import { useState, useEffect, useCallback } from 'react';
import {Badge, Card,Chip, Typography, Divider, Stack, TextField, Grid, Button, Box, Toolbar, FormControl, InputLabel, Select, Tooltip, IconButton, TableCell, Switch, Menu, Avatar, Paper, InputAdornment, FormControlLabel, RadioGroup, Radio } from '@mui/material';
import Swal from 'sweetalert2';
import Modal from '@mui/material/Modal';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { isMobile } from 'react-device-detect';
import { DataGrid, getGridNumericOperators, getGridStringOperators, GridToolbar, GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { useLocation, useNavigate } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import { formatDateTime } from '../../utils/dateUtils';
import { useForm, Controller } from 'react-hook-form';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SearchIcon from '@mui/icons-material/Search';
import { CustomerService } from './Service';
import EditIcon from '@mui/icons-material/Edit';
import Layout from '../../components/Layout/Layout';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ViewProfile from './ViewProfile';
import NoRowsLayout from '../../components/Layout/NoRowsLayout';
import BookingHistory from './BookingHistory';
import { PermissionGuard } from '../../components/PermissionGuard';
import { CheckCircle, Cancel, Close, BorderColorOutlined, Search, KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import { Auth } from '../../services/AuthServices';
import VerifyOtp from './VerifyOtp';
import CustomerProfile from './CustomerProfile';
import PersonalDetails from './PersonalDetail';

const style = {
  position: 'absolute',
  top: (isMobile) ? '80%' : '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: (isMobile) ? "95%" : "25%",
  bgcolor: 'background.paper',
  border: '2px solid #ddd',
  boxShadow: 24,
  p: 2,
};


export default function CustomerList() {
  const { control, handleSubmit, reset, setValue, getValues, formState: { errors },watch } = useForm({
    mode: "onChange"
  });
  /************************  Table Data State Manage **************************** */
  const [allRowCount, setAllRowCount] = useState(0);
  const [list, setList] = useState([]);
  const [deleteRowData, setDeleteRowData] = useState({});
  const [controller, setController] = useState({
    page: 0,
    pageSize: 20,
    filters: {
      searchKey: "",
      status: 1,
      sortKey:'',
      sortDirection:'desc'
    }
  });
  const handlePaginationChange = (newPagination) => {
    setController({
      ...controller,
      page: newPagination.page,
      pageSize: newPagination.pageSize
    });
  }

  function onSubmit(data) {
    const filtersData = getValues();
    setController({
      ...controller,
      filters: {
        ...controller.filters,
        searchKey: filtersData.searchKey,
        status: filtersData.status
      }
    });
  }
  const clearFilters = () => {
    reset();
    setController({
      ...controller,
      filters: {
        ...controller.filters,
        searchKey: "",
        status: "",
        sortKey:'',
        sortDirection:''
      }
    });
  }

  useEffect(()=>{
    if(watch('searchKey').length>2){
      setController({
        ...controller,
        filters: {
          ...controller.filters,
          searchKey:watch('searchKey'),
        }
      });
      
    }
  },[watch('searchKey')])

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const fetchCustomerList = async () => {
    try {
      setLoading(true)
      const listResponse = await CustomerService.sortedCustomerList({
        page: controller.page,
        limit: controller.pageSize,
        filters: {
          searchKey: controller.filters.searchKey,   // Use the title filter from the controller
          status: controller.filters.status,
          sortKey:controller.filters.sortKey,
          sortDirection:controller.filters.sortDirection
        }
      });
      console.log("customer list Response:",listResponse)
      setValue('status', controller.filters.status)
      if (listResponse.success) {
        setList(listResponse.data.customers);
        setAllRowCount(listResponse.data.totalItems);
      } 
    } catch (error) {
      Swal.fire(error.message,'','error')
    }
    setLoading(false)
  };
  /************************  Manage Use Effect **************************** */
  useEffect(() => {
    
    fetchCustomerList();
  }, [controller, deleteRowData]);

  const [selectedCustomer,setSelectedCustomer] =  useState({})
  const [customerProfile,setCustomerProfile] = useState(null);
  const [bookingHistory,setBookingHistory] = useState(null);

  /************** menu item related *******************/
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event,data) => {
    setAnchorEl(event.currentTarget);
    setSelectedCustomer(data);
  };
  const handleClose = (item) => {
    setAnchorEl(null);
    if (item=='profile') {
      console.log('selected customer Data:',selectedCustomer)
      setCustomerProfile(selectedCustomer)
    }else if(item=='bookings'){
      setBookingHistory([]);
    }
    
  };
  
  const handleStatusChange = async (data,customerId) => {
    console.log ("required customer data for status change:",data);
    const result = await Swal.fire({
      text: "Do you want to Change Account Status now?",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Change now!',
      cancelButtonText: 'No, Cancel',
      allowOutsideClick: false,
      allowEscapeKey: false,
      showCloseButton: false,
    });
  
    if (result.isConfirmed) {
      try {
        // Show loading state
        Swal.fire({
          title: 'Processing...',
          text: 'Please wait while we update the account status',
          allowOutsideClick: false,
          allowEscapeKey: false,
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading();
          }
        });
  
        // Make the API call
        const statusChangeResponse = await CustomerService.updateCustomerStatus(data,customerId);
        console.log("customer account status change response:", statusChangeResponse);
  
        // Close loading dialog and show success/error message
        if (statusChangeResponse.success === 1) {
          await fetchCustomerList();
          await Swal.fire({
            icon: 'success',
            title: 'Success!',
            text: statusChangeResponse.message || 'Account Status updated successfully',
            timer: 1500
          });
          
          return true;
        } else {
          await Swal.fire({
            icon: 'error',
            title: 'Error!',
            text: statusChangeResponse.message || 'Failed to update account status'
          });
          return false;
        }
  
      } catch (error) {
        console.error("Error changing customer account status:", error);
        await Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: 'An error occurred while updating the account status'
        });
        return false;
      }
    } else {
      return false;
    }
  };
  const handleDeleteCustomer = async (customerId) => {
    const result = await Swal.fire({
      text: "Do you want to Delete Customer Account now?",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete now!',
      cancelButtonText: 'No, Cancel',
      allowOutsideClick: false,
      allowEscapeKey: false,
      showCloseButton: false,
    });
  
    if (result.isConfirmed) {
      try {
        // Show loading state
        Swal.fire({
          title: 'Processing...',
          text: 'Please wait while deleting customer account',
          allowOutsideClick: false,
          allowEscapeKey: false,
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading();
          }
        });
  
        // Make the API call
        const customerDeleteResponse = await CustomerService.deleteCustomerById(customerId);
        console.log("customer account delete response:", customerDeleteResponse);
  
        // Close loading dialog and show success/error message
        if (customerDeleteResponse.success === 1) {
          await fetchCustomerList();
          await Swal.fire({
            icon: 'success',
            title: 'Success!',
            text: customerDeleteResponse.message || 'Account Deleted successfully',
            timer: 1500
          });
          
          return true;
        } else {
          await Swal.fire({
            icon: 'error',
            title: 'Error!',
            text: customerDeleteResponse.message || 'Failed to delete account'
          });
          return false;
        }
  
      } catch (error) {
        console.error("Error deleting customer account :", error);
        await Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: 'An error occurred while deleting the account '
        });
        return false;
      }
    } else {
      return false;
    }
  };
  /************************  Table Column Manage **************************** */
  // const containsFilterOperator = [
  //   {
  //     label: 'contains',
  //     value: 'contains',
  //     getApplyFilterFn: (filterItem) => {
  //       if (!filterItem.value) return null;
  //       return ({ value }) => value?.toString().includes(filterItem.value);
  //     },
  //   },
  // ];
  const containsOnlyOperator = getGridStringOperators()
  .filter(operator => operator.value === 'contains')
  .map(operator => ({
    ...operator,
    requiresOperator: false,
    showOperatorSelector: false
  }));

  const columns = [
    { field: "id", headerAlign: 'center', headerName: "ID", width: 100, editable: false,filterable:false, },
    {field: "name", headerAlign: 'center', headerName: "Name", flex: 2, editable: false,
      filterOperators:containsOnlyOperator,
      
      valueGetter: (params) => {
        const firstName = params.row?.profile?.firstName || '';
        const middleName = params.row?.profile?.middleName || '';
        const lastName = params.row?.profile?.lastName || '';
        const email = params.row?.profile?.email || '';
        
        // Combine all profile information into a single searchable string
        return `${firstName} ${middleName} ${lastName} ${email} `.trim();
      },
      renderCell: (params) => (
        <Box sx={{ p: 1,display:'flex',gap:'5%',justifyContent:'flex-start',alignItems:'flex-start',width:'100%' }}>
          <Avatar alt={params.row?.profile?.firstName+'_image'} src={''} />
          
          <Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 0.5 }}>
              <Typography 
                variant='body2' 
                sx={{
                  textTransform: 'capitalize',
                  fontWeight: '600',
                  textAlign: 'justify'
                }}
              >
                {params.row?.profile?.firstName || ''} {params.row?.profile?.middleName || ''} {params.row?.profile?.lastName || ''}
              </Typography>
              <Chip
                size="small"
                icon={params.row?.isVerified ? <CheckCircle fontSize="small" /> : <Cancel fontSize="small" />}
                label={params.row?.isVerified ? "Verified" : "Not Verified"}
                color={params.row?.isVerified ? "success" : "error"}
                variant="outlined"
                sx={{ 
                  height: '20px',
                  '& .MuiChip-icon': { 
                    fontSize: '14px',
                    marginLeft: '4px' 
                  },
                  '& .MuiChip-label': {
                    fontSize: '0.675rem',
                    padding: '0 6px'
                  }
                }}
              />
            </Box>
            <Typography sx={{textAlign:'justify',fontSize:'0.7rem'}}>{params.row?.profile?.email || ''}</Typography>
          </Box>
        </Box>
      ),
    },
    { field: "gender", headerAlign: 'center', headerName: "Gender", flex: 1, editable: false,filterOperators:containsOnlyOperator,
      valueGetter: (params) => {
        return `${params.row.profile.gender}`;
      }
    },
    { field: "mobileNumber", headerAlign: 'center', headerName: "Mobile Number", flex: 1, editable: false,filterOperators:containsOnlyOperator, },
    {
      field: "isActive",
      headerAlign: 'center',
      headerName: "Account",
      flex: 1,
      editable: false,
      filterOperators:containsOnlyOperator,
      // Define valueGetter for filtering
      valueGetter: (params) => {
        if (params.row.isActive) {
          return 'active,true,verified';
        }
        return 'inactive,false,non-verified';
      },
      renderCell: (params) => {
        return(       
        <PermissionGuard feature='register-admin' permission='canEdit'>
          <Box sx={{display:'flex',flexDirection:'column'}}>
          <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>
            <Switch
              checked={params.row.isActive}
              onChange={()=>handleStatusChange({isActive: !params.row?.isActive},params.row.id)}
              sx={{
                '& .MuiSwitch-switchBase.Mui-checked': {
                    color: 'success.main',
                    // '&:hover': {
                    //     backgroundColor: 'success.light'
                    // }
                },
                '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                    backgroundColor: 'success.main'
                },
                '& .MuiSwitch-switchBase': {
                    color: 'error.main',
                    // '&:hover': {
                    //     backgroundColor: 'error.light'
                    // }
                },
                '& .MuiSwitch-track': {
                    backgroundColor: 'error.main'
                }
              }}
              inputProps={{ 'aria-label': 'controlled' }}
            />
            <Typography
              variant="body2"
              sx={{
                color: params.row?.isActive ? 'success.main' : 'error.main',
                fontWeight: 'medium'
              }}
            >
              {params.row?.isActive ? 'Active' : 'Inactive'}
            </Typography>
            </Box>
            {/* <Box sx={{textAlign:'center',mb:'2%'}}>
            {!params.row?.isVerified && 
              <Button size='small' variant='outlined' color='warning' sx={{maxHeight:'fit-content',p:'1%'}}
                onClick={()=>sendOtp(params.row)}
              >
                verify
              </Button>
            }
            </Box> */}
            </Box>
        </PermissionGuard>
        
      )},
    },
    {field: 'action', headerAlign: 'center', headerName: 'Action', sortable: false, flex: 1,filterable:false,
      renderCell: (params) => {
        const data = params.row;
        const handleEdit = async()=>{
          const result = await Swal.fire({
            text: "Do you want to Edit Customer Detail now?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Edit now!',
            cancelButtonText: 'No, Cancel',
            allowOutsideClick: false,
            allowEscapeKey: false,
            showCloseButton: false,
          });
          if (result.isConfirmed) {
            setSelectedCustomerDetail({
              id: params.row.id,
              firstName: params.row.profile.firstName,
              middleName: params.row.profile.middleName,
              lastName: params.row.profile.lastName,
              mobileNumber: params.row.mobileNumber,
              gender: params.row.profile.gender,
              email: params.row.profile.email,
              dob: params.row.profile.dob
            });
            setOpenAddEditModal(true)
          }else{
            return false
          }
        }
        return (
          <Stack direction="row" spacing={1} alignItems={'center'} sx={{textAlign:'justify'}}>
            <IconButton color='warning' 
            // onClick={()=>setCustomerProfile(params.row)}
              id="basic-button"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={(event)=>handleClick(event,params.row)}  
            ><MenuIcon/></IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                // 'aria-labelledby': 'basic-button',
              }}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  boxShadow: '1px 1px 2px 0px lightGray',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                },
              }}
            >
              <MenuItem onClick={()=>handleClose('profile')}>View Profile</MenuItem>
              <MenuItem onClick={()=>handleClose('bookings')}>Booking History</MenuItem>
              {!selectedCustomer?.isVerified && <MenuItem onClick={()=>sendOtp(selectedCustomer)}>Verify Account</MenuItem>}
            </Menu>
            <PermissionGuard feature={'customer'} permission='canEdit'>
              <IconButton color='info' 
                onClick={handleEdit}  
              ><BorderColorOutlined/></IconButton>
            </PermissionGuard>
            <PermissionGuard feature={'customer'} permission='canDelete'>
              <IconButton color='error' 
              onClick={()=>handleDeleteCustomer(params.row.id)}
                // onClick={(event)=>handleClick(event,params.row)}  
              ><DeleteForeverIcon/></IconButton>
            </PermissionGuard>
          </Stack>
        );
      },
    },
  ];
  /************ customer mobile/otp verification related ***********/
    const [openOtpModal,setOpenOtpModal] = useState(false);
    const [selectedCustomerDetail,setSelectedCustomerDetail] = useState(null);
    const sendOtp = async (customerData) => {
      setAnchorEl(null);
      console.log("selected customer :",customerData)
      const result = await Swal.fire({
        text: "Do you want to Verify Customer now?",
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Verify now!',
        cancelButtonText: 'No, Cancel',
        allowOutsideClick: false,
        allowEscapeKey: false,
        showCloseButton: false,
      });
    
      if (result.isConfirmed) {
        setSelectedCustomerDetail(customerData);
        try {
          // Show loading state
          const loadingDialog = Swal.fire({
            title: 'Processing...',
            text: 'Please wait while sending the otp',
            allowOutsideClick: false,
            allowEscapeKey: false,
            showConfirmButton: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });
    
          // Make the API call
          const response = await CustomerService.verifyCustomerByMobileNo({ mobileNumber: customerData.mobileNumber });
          console.log("mobile number submission response:", response);
    
          // Always close the loading dialog
          await Swal.close();
    
          if (response.success == '1') {
            setOpenOtpModal(true);
            return true;
          } else {
            await Swal.fire({
              icon: 'error',
              title: 'Error!',
              text: response.message || 'Failed to send otp'
            });
            return false;
          }
    
        } catch (error) {
          // Always close the loading dialog in case of error
          await Swal.close();
          
          console.error("Error Verifying Customer mobile number:", error);
          await Swal.fire({
            icon: 'error',
            title: 'Error!',
            text: 'An error occurred while Verifying customer'
          });
          return false;
        }
      } else {
        return false;
      }
    }
    const handleModalClose = ()=>{
      fetchCustomerList();
      setSelectedCustomerDetail(null);
      setOpenOtpModal(false);
    }
    /************* Add/Edit cuistomer Related *************/
    const [openAddEditModal,setOpenAddEditModal] =  useState(false);
    const handleAddEditCustomerModal = async ()=>{
      fetchCustomerList();
      setSelectedCustomerDetail(null);
      setOpenAddEditModal(false);
    }
  return (
    <Layout pageName={'Manage Customers'}>

      <Card sx={{ minWidth: "100%", marginBottom: "10px" }}>
        <Grid container>
          <Grid item xs={9}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2} sx={{ p: 2 }}>
                <Grid item xs={12} lg={5}>
                  <Controller
                    name="searchKey"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        placeholder="Search by name,mobile,gender........"
                        // label="Search"
                        size="small"
                        fullWidth
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            
                              paddingLeft: '0px',
                            
                          }
                        }}
                        InputProps={{  // Changed from slotProps to InputProps
                          startAdornment: (
                            <InputAdornment position="start">
                              <IconButton>
                                <Search/>
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                {/* <Grid item xs={12} lg={3}>
                  <FormControl sx={{ minWidth: "100%" }} size="small">
                    <InputLabel id="status-select-label">Select Status</InputLabel>
                    <Controller
                      name="status"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <Select
                          {...field}
                          labelId="status-select-label"
                          label="Select Status"
                        >
                          <MenuItem value="2">All</MenuItem>
                          <MenuItem value="1">Active</MenuItem>
                          <MenuItem value="0">Inactive</MenuItem>
                        </Select>
                      )}
                    />
                  </FormControl>
                </Grid> */}
                <Grid item xs={12} lg={3}>
                  <Tooltip title="Search">
                    <IconButton
                      color="primary"
                      type="submit"
                      sx={{ border: "1px solid", borderRadius: "7px", padding: "6px 6px" }}
                    >
                      <SearchIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Clear All Filters">
                    <IconButton
                      color="primary"
                      sx={{ marginLeft: "10px", border: "1px solid", color: "#f00", borderRadius: "7px", padding: "6px 6px" }}
                      onClick={clearFilters}
                    >
                      <RestartAltIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
                {/* <Grid item xs={12} lg={4}>
                  <Button
                    variant="outlined"
                    // onClick={handleClick}
                    endIcon={open?<KeyboardArrowUp/> :<KeyboardArrowDown />}
                    sx={{
                      textTransform: 'none',
                      // color: 'inherit',
                      // borderColor: 'grey.300',
                      // '&:hover': {
                      //   borderColor: 'grey.400',
                      //   backgroundColor: 'grey.50'
                      // }
                    }}
                  >
                    Sort By
                  </Button> */}
                  {/* <Popover
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    PaperProps={{
                      sx: { 
                        width: 320,
                        p: 3,
                        mt: 1
                      }
                    }}
                  >
                    {/* <Typography
                      variant="h6"
                      sx={{
                        fontSize: '1.25rem',
                        fontWeight: 500,
                        mb: 3
                      }}
                    >
                      Sorting Options
                    </Typography> */}

                    {/* <Typography
                      sx={{
                        fontSize: '1rem',
                        fontWeight: 500,
                        mb: 1
                      }}
                    >
                      Sorting Options:
                    </Typography> */}
                    {/* <FormControl fullWidth sx={{ mb: 3 }} size='small'>
                      <Select
                        // value={sortKey}
                        // onChange={(e) => setSortkey(e.target.value)}
                        displayEmpty
                        sx={{
                          bgcolor: 'grey.50',
                          '& .MuiSelect-select': {
                            color: !sortKey ? 'grey.500' : 'inherit'
                          }
                        }}
                      >
                        <MenuItem value="" selected>
                          Default View
                        </MenuItem>
                        <MenuItem value="bookingNumber">Booking Id</MenuItem>
                        <MenuItem value="service date">Service date</MenuItem>
                        <MenuItem value="expert">Expert</MenuItem>
                        <MenuItem value="status">Status</MenuItem>
                      </Select>
                    </FormControl> */}

                    {/* <Typography
                      sx={{
                        fontSize: '1rem',
                        fontWeight: 500,
                        mb: 1
                      }}
                    >
                      Sort Type:
                    </Typography> */}
                    {/* <RadioGroup
                      // value={paymentType}
                      // onChange={(e) => setPaymentType(e.target.value)}
                      sx={{ mb: 3 }}
                    >
                      <FormControlLabel value="all" control={<Radio />} label="All" />
                      <FormControlLabel value="visa" control={<Radio />} label="Visa" />
                      <FormControlLabel value="mastercard" control={<Radio />} label="Mastercard" />
                      <FormControlLabel value="amex" control={<Radio />} label="American Express" />
                    </RadioGroup> */}

                    {/* <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
                      <Button
                        // onClick={handleReset}
                        sx={{
                          color: 'grey.700',
                          '&:hover': {
                            bgcolor: 'grey.100'
                          }
                        }}
                      >
                        Reset
                      </Button>
                      <Button
                        variant="contained"
                        // onClick={handleApply}
                        sx={{
                          bgcolor: 'primary.main',
                          color: 'white',
                          '&:hover': {
                            bgcolor: 'primary.dark'
                          }
                        }}
                      >
                        Apply
                      </Button>
                    </Box> */}
                  {/* </Popover> */} 
                 
                {/* </Grid> */}
              </Grid>
            </form>
          </Grid>
          <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', paddingRight: '16px' }}>
          <PermissionGuard feature="customer" permission="canCreate">
            <Button 
              variant='contained' 
              // onClick={() => navigate(`/admin/customerProfile/0`)} 
              onClick={()=>setOpenAddEditModal(true)}
              sx={{ height: "36px" }} 
              endIcon={<AddCircleIcon />}
            >
              Add
            </Button>
            </PermissionGuard>
          </Grid>
        </Grid>
      </Card>
        {/* ================Customer list Table ======================== */}
      <Box sx={{ overflow: "auto" }}>
        <Box sx={{ width: "100%", display: "table", tableLayout: "fixed", backgroundColor: "#fff",borderRadius:'.5rem' }}>
          <DataGrid
            rows={list}
            columns={columns}
            // getRowId={(row) => row.id}
            //checkboxSelection={true}
            rowCount={allRowCount}  // Use the length of the filtered list
            pageSizeOptions={[5, 10, 20, 50, 100]}
            paginationModel={controller}
            paginationMode="server"
            onPaginationModelChange={handlePaginationChange}

            loading={loading}

            disableColumnMenu
            disableRowSelectionOnClick
            // disableColumnFilter
            // disableColumnSelector
            disableDensitySelector
            slots={{
              toolbar: GridToolbar,
              noRowsOverlay: NoRowsLayout,
            }}
            // slotProps={{
            //   toolbar: {
            //     showQuickFilter: true,  
            //   },
            // }}
            // rowHeight={50}
            autoHeight
            getRowHeight={() => 'auto'}
            sx={{
                '& .MuiDataGrid-cell': {
                border: 1,
                borderRight: 0,
                borderTop: 0,
                borderColor: 'lightgrey',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                },
                '& .MuiDataGrid-columnHeaders': {
                borderBottom: '2px solid rgba(224, 224, 224, 1)',
                borderTop: '2px solid rgba(224, 224, 224, 1)',
                },
                '& .MuiDataGrid-columnHeader': {
                borderLeft: '1px solid rgba(224, 224, 224, 1)',
                '&:last-child': {
                    borderRight: 'none',
                },
                },
                '& .MuiDataGrid-columnHeaderTitle': {
                fontWeight: 'bold',
                color: '#000000'
                },
                '& .MuiDataGrid-row': {
                '&:last-child .MuiDataGrid-cell': {
                    borderBottom: '1px solid rgba(224, 224, 224, 1)',
                },
                '& .MuiDataGrid-cell:last-child': {
                    borderRight: 'none',
                },
                },
                '& .MuiDataGrid-columnSeparator': {
                display: 'none',
                },
            }}
          />
        </Box>
      </Box>
      {customerProfile && 
        <ViewProfile 
          customerDetails={customerProfile}
          onClose={()=>setCustomerProfile(null)}
        />
      }
      {
        bookingHistory && 
        <BookingHistory
          bookingList={bookingHistory}
          onClose={()=>setBookingHistory(null)}
        />
      }
      {/* ================ Verify Customer Modal=============== */}
      <Modal
        open={openOtpModal}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ overflow: "scroll" }}
      >
        <Box sx={{
          position: 'absolute',
          top: (isMobile) ? '80%' : '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          // border: '2px solid #ddd',
          boxShadow: 24,
          p: 2,}}
          width={(isMobile) ? "95%" : "50%"}
        >
          <Box sx={{textAlign:'end'}}>
            <Button size='small' variant='outlined' color='error' startIcon={<Close/>} onClick={handleModalClose}>close</Button>
          </Box>
          <VerifyOtp customerMobileNumber={selectedCustomerDetail?.mobileNumber}
            selectedCustomerDetail={selectedCustomerDetail}
            sendOtp = {sendOtp}
            setOpenOtpModal={setOpenOtpModal}
            onClose={handleModalClose}
          />
        </Box>
      </Modal>
      
      {/* ================== Add/Edit Customer Modal ================ */}
      <Modal
        open={openAddEditModal}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ overflow: "scroll" }}
      >
        <Box 
          sx={{
            position: 'absolute',
            top: (isMobile) ? '80%' : '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            // border: '2px solid #ddd',
            boxShadow: 24,
            // p: 2,
          }}
          width={(isMobile) ? "95%" : "65%"}
        >
          <Card sx={{ minWidth: "100%" ,padding:'1%'}}>
            <Stack direction="row" spacing={2} sx={{justifyContent:'center',alignItems:'center'}}>
              <Typography
                  gutterBottom
                  variant='h5'
                  component='div'
                  // sx={{ padding: '20px' }}
              >
                {selectedCustomerDetail?'Edit Customer Detail':'Add New Customer'}
              </Typography>
              <Typography
                variant='h3'
                component='div'
                sx={{ flexGrow: 1 }}
              />
              <Button size='small' variant='outlined' color='error' startIcon={<Close/>} onClick={handleAddEditCustomerModal}>close</Button>
            </Stack>
          </Card>
          <Box sx={{}}>
            <Grid container spacing={1}>
                <Grid item xs={12} lg={12}>
                    <Paper elevation={3} style={{ padding: 16 }}>
                        <PersonalDetails 
                          personalDetails={selectedCustomerDetail}
                          onSubmitPersonal={handleAddEditCustomerModal} 
                          // userIdProp={id} personalDetails={personalDetails} onSubmitPersonal={handlePersonalSubmit} 
                        />
                    </Paper>
                </Grid>
            </Grid>
          </Box>
        </Box>
      </Modal>
    </Layout>
  )
}