import React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import {Box,Card, Grid, IconButton, ImageListItemBar, Typography } from '@mui/material';
import { DeleteOutline } from '@mui/icons-material';
import { Helpers } from '../../../services/Helpers';
import DeleteIcon from '@mui/icons-material/Delete';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';

function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}
const options = [
    'use as store profile',
    'use as banner',
  ];
  
  const ITEM_HEIGHT = 48;
  
export default function StoreFrontGallery({items}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Grid item xs={12}>
          <Card>
        <Box sx={{p:'.5% 2%',border:'2px solid lightGray',bgcolor:'lightGray'}}>
            <Typography sx={{fontWeight:'bold'}}>Storefront Photos</Typography>
        </Box>
        <Box sx={{px:'2%'}}>
            <ImageList 
                variant="quilted"
                cols={3} 
                gap={5}
                // className="w-full overflow-hidden"
                sx={{width:'100%'}}
            >
                {items && items.length>0 && items.map((item) => (
                <ImageListItem 
                    key={item}
                    className="aspect-square overflow-hidden"
                >
                    <img
                        src={`${Helpers.ImagePathUrl()}/storefront-gallery/md/${item}?w=400&h=400&fit=cover&auto=format`}
                        srcSet={`${Helpers.ImagePathUrl()}/storefront-gallery/md/${item}?w=400&h=400&fit=cover&auto=format&dpr=2 2x`}
                        alt={item}
                        // className="object-cover w-full h-full"
                        loading="lazy"
                    />
                    {/* <ImageListItemBar
                        // title={item.title}
                        // subtitle={item.author}
                        actionIcon={
                          <> */}
                            {/* <IconButton
                                sx={{ color: 'red' }}
                                aria-label={`info about ${item.title}`}
                            >
                                <DeleteIcon />
                            </IconButton> */}
                            {/* <IconButton
                                color='primary'
                                aria-label="more"
                                id="long-button"
                                aria-controls={open ? 'long-menu' : undefined}
                                aria-expanded={open ? 'true' : undefined}
                                aria-haspopup="true"
                                onClick={handleClick}
                            >
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                id="long-menu"
                                MenuListProps={{
                                'aria-labelledby': 'long-button',
                                }}
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                slotProps={{
                                    paper: {
                                        style: {
                                        maxHeight: ITEM_HEIGHT * 4.5,
                                        width: '20ch',
                                        },
                                    },
                                }}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                  }}
                                  transformOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                  }}
                            >
                                {options.map((option) => (
                                <MenuItem key={option} selected={option === 'Pyxis'} onClick={handleClose}>
                                    {option}
                                </MenuItem>
                                ))}
                            </Menu> */}
                        {/* </>
                        }
                    /> */}
                </ImageListItem>
                
                ))}
            </ImageList>

        </Box>
    </Card>
    </Grid>
  );
}

const itemData = [
  {
    img: 'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e',
    title: 'Breakfast',
    // rows: 2,
    // cols: 2,
  },
  {
    img: 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d',
    title: 'Burger',
  },
  {
    img: 'https://images.unsplash.com/photo-1522770179533-24471fcdba45',
    title: 'Camera',
  },
//   {
//     img: 'https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c',
//     title: 'Coffee',
//     // cols: 2,
//   },
  {
    img: 'https://images.unsplash.com/photo-1533827432537-70133748f5c8',
    title: 'Hats',
    // cols: 2,
  },
  {
    img: 'https://images.unsplash.com/photo-1558642452-9d2a7deb7f62',
    title: 'Honey',
    author: '@arwinneil',
    // rows: 2,
    // cols: 2,
  },
  {
    img: 'https://images.unsplash.com/photo-1516802273409-68526ee1bdd6',
    title: 'Basketball',
  },
  {
    img: 'https://images.unsplash.com/photo-1518756131217-31eb79b20e8f',
    title: 'Fern',
  },
  {
    img: 'https://images.unsplash.com/photo-1597645587822-e99fa5d45d25',
    title: 'Mushrooms',
    // rows: 2,
    // cols: 2,
  },
  {
    img: 'https://images.unsplash.com/photo-1567306301408-9b74779a11af',
    title: 'Tomato basil',
  },
  {
    img: 'https://images.unsplash.com/photo-1471357674240-e1a485acb3e1',
    title: 'Sea star',
  },
  {
    img: 'https://images.unsplash.com/photo-1589118949245-7d38baf380d6',
    title: 'Bike',
    // cols: 2,
  },
];

