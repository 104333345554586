import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Box, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { CloudUpload } from '@mui/icons-material';
import { CategoryService } from './CategoryService';
import Swal from 'sweetalert2';

const directories = ['category', 'subcategory'];
const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5 MB in bytes
const UploadImage = ({ open, onClose, categoryList }) => {
    const { control, handleSubmit, setValue, formState: { errors }, watch, reset } = useForm({
        mode: "onChange",
        defaultValues:{
            folder:'',
            category:'',
            file:'',
            subCategory:'',
        }
    });
    const [image, setImage] = useState("");
    const [subCategories, setSubCategories] = useState([]);
    const selectedCategoryId = watch('category');

    useEffect(() => {
        if (open) {
            setImage("");
            reset();
        }
    }, [open, reset]);

    useEffect(() => {
        if (selectedCategoryId) {
            const selectedCategory = categoryList.find((category) => category.id == selectedCategoryId);
            setSubCategories(selectedCategory?.childCategories || []);
        }
    }, [selectedCategoryId, categoryList]);

    const onSelectedImage = (event, onChange) => {
        const file = event.target.files[0];
        if (file) {
            setImage(URL.createObjectURL(file));
            onChange(file);
        }
    };

    const handleUpload = async (data) => {
        console.log("submitted data:",data)
        if (!data.file || !data.folder) {
            console.error("File or folder is missing");
            return;
        }
        
        const formData = new FormData();
        formData.append('file', data.file);
        formData.append('folder', data.folder);

        console.log("FormData contents:");
        for (let [key, value] of formData.entries()) {
            if (value instanceof File) {
                console.log(key, ":", {
                    name: value.name,
                    type: value.type,
                    size: value.size + " bytes"
                });
            } else {
                console.log(key, ":", value);
            }
        }
        try {
            const imageUploadResponse = await CategoryService.uploadImage(formData);
            console.log("Image upload response:", imageUploadResponse);
            // imgUploadResponse(result);
            if (imageUploadResponse.success=='1') {
                const id = data.subCategory ? data.subCategory:data.category;
                const imageUpdateData = {
                    image:imageUploadResponse.data.imageName,
                }
                const imageDataUpdateResponse = await CategoryService.updateCategoryImage(id,imageUpdateData);
                console.log("image data update response:",imageDataUpdateResponse)
                if (imageDataUpdateResponse.success=='1') {
                    Swal.fire(imageUploadResponse.message,'','success');
                    onClose();
                } else {
                    Swal.fire(imageUploadResponse.message,'','error');
                    onClose();
                }
            } else {
                Swal.fire('Image upload Failed','','error');
                    onClose();
            }
            
        } catch (error) {
            console.error("Image Upload failed:", error);
        } finally {
            onClose();
        }
    };
    return (
        <Dialog open={open} fullWidth>
            <DialogTitle sx={{ m: 0, p: 2 }}>
                Image Upload
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <Box component="form" onSubmit={handleSubmit(handleUpload)}>
                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                control={control}
                                name="folder"
                                rules={{ required: 'Select subject' }}
                                render={({ field, fieldState: { error } }) => (
                                    <FormControl fullWidth size="small" error={!!error}>
                                        <InputLabel>Select Subject</InputLabel>
                                        <Select {...field} label="Select Subject">
                                            {directories.map((directory, index) => (
                                                <MenuItem key={index} value={directory} sx={{ textTransform: 'uppercase' }}>
                                                    {directory}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {error && <Typography color="error" variant="caption">{error.message}</Typography>}
                                    </FormControl>
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <Controller
                                        control={control}
                                        name="category"
                                        rules={{ required: 'Select category' }}
                                        render={({ field, fieldState: { error } }) => (
                                            <FormControl fullWidth size="small" error={!!error}>
                                                <InputLabel>Select Category</InputLabel>
                                                <Select {...field} label="Select Category">
                                                    {categoryList.map((category, index) => (
                                                        <MenuItem key={index} value={category.id} sx={{ textTransform: 'uppercase' }}>
                                                            {category.title}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                {error && <Typography color="error" variant="caption">{error.message}</Typography>}
                                            </FormControl>
                                        )}
                                    />
                                </Grid>
                                {watch('folder') === 'subcategory' && (
                                    <Grid item xs={12} sm={6}>
                                        <Controller
                                            control={control}
                                            name="subCategory"
                                            rules={{ required: 'Select sub category' }}
                                            render={({ field, fieldState: { error } }) => (
                                                <FormControl fullWidth size="small" error={!!error}>
                                                    <InputLabel>Select Sub Category</InputLabel>
                                                    <Select {...field} label="Select Sub Category" disabled={!watch('category')}>
                                                        {subCategories.map((subCategory, index) => (
                                                            <MenuItem key={index} value={subCategory.id} sx={{ textTransform: 'uppercase' }}>
                                                                {subCategory.title}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                    {error && <Typography color="error" variant="caption">{error.message}</Typography>}
                                                </FormControl>
                                            )}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                            
                        <Grid item xs={6}>
                            <Controller
                                control={control}
                                name="file"
                                rules={{
                                    required: 'Please select an image',
                                    validate: {
                                        fileType: (value) => {
                                            if (value) {
                                                const acceptedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
                                                return acceptedTypes.includes(value.type) || 'File type not supported. Please upload a PNG, JPEG, or JPG image.';
                                            }
                                            return true;
                                        },
                                        fileSize: (value) => {

                                            if (value) {
                                                return value.size <= MAX_FILE_SIZE || 'File size should be less than 5 MB.';
                                            }
                                            return true;
                                        }
                                    }
                                }}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <>
                                        <input
                                            type="file"
                                            accept="image/*"
                                            id="file"
                                            onChange={(event) => {
                                                const file = event.target.files[0];
                                                onChange(file);
                                                onSelectedImage(event, onChange);
                                            }}
                                            style={{ display: 'none' }}
                                        />
                                        <Box
                                            component="label"
                                            htmlFor="file"
                                            sx={{
                                                p: 3,
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                border: '1px dashed',
                                                borderColor: 'grey.400',
                                                cursor: 'pointer',
                                            }}
                                        >
                                            <CloudUpload sx={{ fontSize: 40, color: 'primary.main' }} />
                                            <Typography variant="body1" color="primary" gutterBottom>
                                                Click to upload or drag and drop
                                            </Typography>
                                            <Typography variant="caption">JPEG, PNG, JPG</Typography>
                                        </Box>
                                        {error && (
                                            <Typography variant="caption" color="error">
                                                {error.message}
                                            </Typography>
                                        )}
                                    </>
                                )}
                            />
                        </Grid>
                        {image && (
                            <Grid item xs={6}>
                                <Box
                                    component="img"
                                    src={image}
                                    alt="Uploaded preview"
                                    sx={{
                                        width: 100,
                                        borderRadius: 1,
                                        mt: 2,
                                    }}
                                />
                            </Grid>
                        )}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button type="submit" color="primary">
                        Upload Image
                    </Button>
                    <Button onClick={onClose} color="secondary">
                        Cancel
                    </Button>
                </DialogActions>
            </Box>
        </Dialog>
    );
};

export default UploadImage;