import { Box, Button, Paper, TextField, Typography } from '@mui/material';
import React from 'react';
import {Controller, useForm} from 'react-hook-form'
import Swal from 'sweetalert2';
import { CustomerService } from './Service';

const ChangeMobileNumber = ({customerData,onCloseModel}) => {
    const {control,handleSubmit,reset} =  useForm({
        mode:'onSubmit',
        defaultValues:{
            mobileNumber:''
        }
    });
    

    const mobileNumberSusmission = async (data) =>{
        console.log ("New mobile Number :",data.mobileNumber);
        try {
            // Show loading state
            const loadingDialog = Swal.fire({
              title: 'Processing...',
              text: 'Please wait while Changing Mobile Number',
              allowOutsideClick: false,
              allowEscapeKey: false,
              showConfirmButton: false,
              didOpen: () => {
                Swal.showLoading();
              }
            });
      
            // Make the API call
            const response = await CustomerService.updateMobileNumber(
                {
                    currentMobileNo: customerData.mobileNumber,
                    newMobileNo: data.mobileNumber 
                });
            console.log("mobile number submission response:", response);
      
            // Always close the loading dialog
            await Swal.close();
      
            if (response.success == '1') {
                reset();
            //   setOpenOtpModal(true);
                Swal.fire({
                    icon: 'success',
                    title: 'Successful!',
                    text: response.message || 'Mobile Number Changed Successfully',
                });
                onCloseModel();
              return true;
            } else {
              await Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: response.message || 'Failed to send otp'
              });
              return false;
            }
      
          } catch (error) {
            // Always close the loading dialog in case of error
            await Swal.close();
            
            console.error("Error While Changing Customer mobile number:", error);
            await Swal.fire({
              icon: 'error',
              title: 'Error!',
              text: 'An error occurred while changing customer mobile number'
            });
            return false;
        }
    }
  return (
    <Paper sx={{p:'5%',my:'2%'}}>
        <Box component={'form'} onSubmit={handleSubmit(mobileNumberSusmission)} sx={{display:'flex',justifyContent:'space-between'}}>
            <Box sx={{display:'flex',gap:'3%',flex:1}}>
                <Typography sx={{fontWeight:'bold', texttTransform:'uppercase', display:'flex ',alignItems:'center'}}>Contact Number </Typography>
                <Controller
                    name='mobileNumber'
                    control={control}
                    rules={{
                        required: 'Mobile number is required',
                        pattern: {
                            value: /^[1-9][0-9]{9}$/,
                            message: "Enter a valid 10-digit number not starting with 0"
                        },
                        validate: (value) => {
                        if (value && value.length !== 10) {
                            return "Mobile number must be exactly 10 digits";
                        }
                        return true;
                        }
                    }}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            size='small'
                            error={!!error}
                            // helperText={error?.message}
                            inputProps={{
                                maxLength: 10,
                            }}
                            onChange={(e) => {
                                const value = e.target.value.replace(/[^0-9]/g, '').slice(0, 10);
                                field.onChange(value);
                            }}
                        />
                    )}                       
                />
                
            </Box>
            <Button variant='contained' size='small' color='primary' type='submit'>Change Number</Button>
        </Box>
    </Paper>
  );
}

export default ChangeMobileNumber;
