import React, { useState } from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  TextField,
  Typography,
  FormControl,
  FormControlLabel,
  Grid,
  Divider,
  IconButton,
  Box,
} from '@mui/material';
import { Cancel } from '@mui/icons-material';

const Permissions = ({permissions}) => {
  console.log("permissions:",permissions)
  const [roleName, setRoleName] = useState('');
  
  const renderPermissionRow = (permission) => (
    <Grid container spacing={2} alignItems="center" sx={{  }}>
      <Grid item xs={4}>
        <Typography>{permission.featureName}</Typography>
      </Grid>
      <Grid item xs={8}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={permission.canView}
                //   onChange={handlePermissionChange(section, 'view')}
                />
              }
              label="View"
            />
          </Grid>
          <Grid item xs={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={permission.canCreate}
                //   onChange={handlePermissionChange(section, 'add')}
                />
              }
              label="Add"
            />
          </Grid>
          <Grid item xs={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={permission.canEdit}
                //   onChange={handlePermissionChange(section, 'modify')}
                />
              }
              label="Modify"
            />
          </Grid>
          <Grid item xs={3} >
            <FormControlLabel
              control={
                <Checkbox
                  checked={permission.canDelete}
                //   onChange={handlePermissionChange(section, 'delete')}
                />
              }
              label="Delete"
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    
    permissions && permissions.map((permission,index)=>{
      return( 
        <Box key={index}>
          {renderPermissionRow(permission)}
          {index < permissions.length-1 && <Divider sx={{ borderStyle:'dotted' }} />}
        </Box>
      )
    })
      
  );
};

export default Permissions;