import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import HomeIcon from '@mui/icons-material/Home';
import PeopleIcon from '@mui/icons-material/People';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import SettingsIcon from '@mui/icons-material/Settings';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import { useLocation, useNavigate } from 'react-router-dom';
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from 'react-redux';
import BrandingWatermarkIcon from '@mui/icons-material/BrandingWatermark';
import CategoryIcon from '@mui/icons-material/Category';
import StoreIcon from '@mui/icons-material/Store';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import { LocalStore } from '../../storage/authStore';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Person2Icon from '@mui/icons-material/Person2';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Business, Storefront } from '@mui/icons-material';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import GroupIcon from '@mui/icons-material/Group';
import ListIcon from '@mui/icons-material/List';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SecurityIcon from '@mui/icons-material/Security';
import ApprovalIcon from '@mui/icons-material/Approval';
import { useEffect, useState } from 'react';

const drawerWidth = 240;

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);




const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),

  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(0)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(0)} + 1px)`,
  },
  [theme.breakpoints.up('lg')]: {
    width: `calc(${theme.spacing(7)} + 1px)`,
  },

});

export default function Sidebar() {
  const theme = useTheme();
  const [subOrderOpen, setSubOrderOpen] = useState(false);

  //const [open, setOpen] = useState(true);
  const userDetail = LocalStore.getCurrentUserDetails();
  const role = (userDetail) ? userDetail.role : '0';

  const dispatch = useDispatch();

  const updateOpen = () => {
    dispatch({
      type: 'menuStatus'
    })
  };
  const open = useSelector(state => state.utilsSto.menu);
  const navigate = useNavigate();
  const activeMenu = useSelector(state => state.utilsSto.menuDefaultSelected);

  const handleNavigation = (path)=>{
    setSubOrderOpen(false);
    navigate(path);
  }
  const pathUrl = useLocation().pathname;
  useEffect(()=>{
    if(pathUrl.startsWith('/settings')){
      setSubOrderOpen(true);
    }
  },[pathUrl])

  return (
    <Box sx={{ display: 'flex' }} mt={2}>
      <CssBaseline />
      <Drawer variant="permanent" open={open} sx={{ color: '#f00' }}>
        <DrawerHeader >
        </DrawerHeader>
        <List>
          {/* ----Dashboard----- */}
          <ListItem
            disablePadding
            onClick={() => {
              handleNavigation("/admin");
              (isMobile) && updateOpen();
            }}
            sx={{ display: 'block' }}
            selected={activeMenu === "home"}
          >
            <ListItemButton
              sx={{
                minHeight: 40,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary="Dashboard" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>

          {/* ----Bookings----- */}

          {(role != '0') && (
            <ListItem
              disablePadding
              selected={activeMenu === "bookings"}
              onClick={() => {
                handleNavigation("/admin/bookings");
                (isMobile) && updateOpen();
              }}
              sx={{ display: 'block' }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <Business />
                </ListItemIcon>
                <ListItemText primary="Bookings" sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          )}

          {/* ----Customers----- */}

          {(role != '0') && (
            <ListItem
              disablePadding
              selected={activeMenu === "customer"}
              onClick={() => {
                handleNavigation("/admin/customers");
                (isMobile) && updateOpen();
              }}
              sx={{ display: 'block' }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <SupervisedUserCircleIcon />
                </ListItemIcon>
                <ListItemText primary="Customers" sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          )}
          
          {/* ----Categories----- */}

          {(role != '0') && (
            <ListItem
              disablePadding
              selected={activeMenu === "category"}
              onClick={() => {
                handleNavigation("/admin/category");
                (isMobile) && updateOpen();
              }}
              sx={{ display: 'block' }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <CategoryIcon />
                </ListItemIcon>
                <ListItemText primary="Category" sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          )}
          
          {/* ----Master services----- */}

          {(role != '0') && (
            <ListItem
              disablePadding
              selected={activeMenu === "service"}
              onClick={() => {
                handleNavigation("/admin/services");
                (isMobile) && updateOpen();
              }}
              sx={{ display: 'block' }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <ShoppingCartIcon />
                </ListItemIcon>
                <ListItemText primary="Master Services" sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          )}

          
          {/* ----Service Experts----- */}

          {(role == '1') && (
            <ListItem
              disablePadding
              selected={activeMenu === "user"}
              onClick={() => {
                handleNavigation("/admin/users");
                (isMobile) && updateOpen();
              }}
              sx={{ display: 'block' }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <PeopleIcon />
                </ListItemIcon>
                <ListItemText primary="Service Experts" sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          )}

          {/* ============= Settings =========== */}
          {/* ========menu options with submenu========== */}
          {(role != '0') && (
            <ListItem
              disablePadding
              // selected={activeMenu === "settings"}
              onClick={() => {
                setSubOrderOpen(!subOrderOpen);
                (isMobile) && updateOpen();
              }}
              sx={{ display: 'block' ,
                bgcolor:subOrderOpen&&'lightGray'
              }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary="Settings" sx={{ opacity: open ? 1 : 0 }} /> 
                {/* Add this line for the dropdown arrow */}
                {subOrderOpen ? <ExpandLess /> : <ExpandMore />} 
              </ListItemButton>
            </ListItem>
          )}
          <Collapse in={subOrderOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem
                disablePadding
                selected={activeMenu === "members"}
                onClick={() => {
                  navigate("/settings/members");
                  (isMobile) && updateOpen();
                }}
                sx={{ display: 'block' }}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                    marginLeft: 2,
                  }}
                >
                  {/* Add your sub-menu item content here */}
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    {/* Add the icon for the sub-menu item */}
                    <GroupIcon /> 
                  </ListItemIcon>
                  <ListItemText primary="Members" sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>

              {/* Add more sub-menu items using the same structure */}
              <ListItem
                disablePadding
                selected={activeMenu === "features"}
                onClick={() => {
                  navigate("/settings/features");
                  (isMobile) && updateOpen();
                }}
                sx={{ display: 'block' }}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                    marginLeft: 2, // Set the left margin here

                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    <ApprovalIcon />
                  </ListItemIcon>
                  <ListItemText primary="Features" sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>

              {/* Add more sub-menu items as needed */}
              <ListItem
                disablePadding
                selected={activeMenu === "roles"}
                onClick={() => {
                  navigate("/settings/roles");
                  (isMobile) && updateOpen();
                }}
                sx={{ display: 'block' }}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                    marginLeft: 2,
                  }}
                >
                  {/* Add your sub-menu item content here */}
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    {/* Add the icon for the sub-menu item */}
                    <GroupIcon /> 
                  </ListItemIcon>
                  <ListItemText primary="Roles" sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
              {/* Add more sub-menu items as needed */}
            </List>
          </Collapse>




          {/* <ListItem
            disablePadding
            onClick={() => {
              navigate(`/admin/userProfile/${userDetail.id}`);
              (isMobile) && updateOpen();
            }}
            sx={{ display: 'block' }}
            selected={activeMenu === "profile"}
          >
            <ListItemButton
              sx={{
                minHeight: 40,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <AccountCircleIcon />
              </ListItemIcon>
              <ListItemText primary="Profile" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem> */}

          {/* ========menu options with submenu ends========== */}

          {/* ----User Management----- */}

           {/* User Management Menu Structure */}
           {/* {(role === 'admin') && (
            <>
              <ListItem
                disablePadding
                selected={activeMenu === "userManagement"}
                onClick={() => {
                  setUserManagementOpen(!userManagementOpen);
                  (isMobile) && updateOpen();
                }}
                sx={{ display: 'block' }}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    <ManageAccountsIcon />
                  </ListItemIcon>
                  <ListItemText primary="User Management" sx={{ opacity: open ? 1 : 0 }} />
                  {open && (userManagementOpen ? <ExpandLess /> : <ExpandMore />)}
                </ListItemButton>
              </ListItem>

              <Collapse in={userManagementOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding> */}
                  {/* Roles Section */}
                  {/* <ListItem
                    disablePadding
                    selected={activeMenu === "roles"}
                    onClick={() => {
                      setRolesOpen(!rolesOpen);
                      (isMobile) && updateOpen();
                    }}
                    sx={{ display: 'block' }}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? 'initial' : 'center',
                        px: 2.5,
                        pl: 4,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : 'auto',
                          justifyContent: 'center',
                        }}
                      >
                        <AdminPanelSettingsIcon />
                      </ListItemIcon>
                      <ListItemText primary="Roles" sx={{ opacity: open ? 1 : 0 }} />
                      {open && (rolesOpen ? <ExpandLess /> : <ExpandMore />)}
                    </ListItemButton>
                  </ListItem> */}

                  {/* Roles Submenu */}
                  {/* <Collapse in={rolesOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItem
                        disablePadding
                        selected={activeMenu === "rolesList"}
                        onClick={() => {
                          navigate("/admin/roles/list");
                          (isMobile) && updateOpen();
                        }}
                        sx={{ display: 'block' }}
                      >
                        <ListItemButton
                          sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                            pl: 6,
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? 3 : 'auto',
                              justifyContent: 'center',
                            }}
                          >
                            <ListIcon />
                          </ListItemIcon>
                          <ListItemText primary="Role List" sx={{ opacity: open ? 1 : 0 }} />
                        </ListItemButton>
                      </ListItem>

                      <ListItem
                        disablePadding
                        selected={activeMenu === "viewRoles"}
                        onClick={() => {
                          navigate("/admin/roles/view");
                          (isMobile) && updateOpen();
                        }}
                        sx={{ display: 'block' }}
                      >
                        <ListItemButton
                          sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                            pl: 6,
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? 3 : 'auto',
                              justifyContent: 'center',
                            }}
                          >
                            <VisibilityIcon />
                          </ListItemIcon>
                          <ListItemText primary="View Role" sx={{ opacity: open ? 1 : 0 }} />
                        </ListItemButton>
                      </ListItem>
                    </List>
                  </Collapse> */}

                  {/* Users Section */}
                  {/* <ListItem
                    disablePadding
                    selected={activeMenu === "users"}
                    onClick={() => {
                      setUsersOpen(!usersOpen);
                      setRolesOpen(!rolesOpen);
                      (isMobile) && updateOpen();
                    }}
                    sx={{ display: 'block' }}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? 'initial' : 'center',
                        px: 2.5,
                        pl: 4,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : 'auto',
                          justifyContent: 'center',
                        }}
                      >
                        <GroupIcon />
                      </ListItemIcon>
                      <ListItemText primary="Users" sx={{ opacity: open ? 1 : 0 }} />
                      {open && (usersOpen ? <ExpandLess /> : <ExpandMore />)}
                    </ListItemButton>
                  </ListItem> */}

                  {/* Users Submenu */}
                  {/* <Collapse in={usersOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItem
                        disablePadding
                        selected={activeMenu === "usersList"}
                        onClick={() => {
                          navigate("/admin/users/list");
                          (isMobile) && updateOpen();
                        }}
                        sx={{ display: 'block' }}
                      >
                        <ListItemButton
                          sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                            pl: 6,
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? 3 : 'auto',
                              justifyContent: 'center',
                            }}
                          >
                            <ListIcon />
                          </ListItemIcon>
                          <ListItemText primary="User List" sx={{ opacity: open ? 1 : 0 }} />
                        </ListItemButton>
                      </ListItem>

                      <ListItem
                        disablePadding
                        selected={activeMenu === "viewUsers"}
                        onClick={() => {
                          navigate("/admin/users/view");
                          (isMobile) && updateOpen();
                        }}
                        sx={{ display: 'block' }}
                      >
                        <ListItemButton
                          sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                            pl: 6,
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? 3 : 'auto',
                              justifyContent: 'center',
                            }}
                          >
                            <VisibilityIcon />
                          </ListItemIcon>
                          <ListItemText primary="View User" sx={{ opacity: open ? 1 : 0 }} />
                        </ListItemButton>
                      </ListItem>
                    </List>
                  </Collapse> */}

                  {/* Permissions Section */}
                  {/* <ListItem
                    disablePadding
                    selected={activeMenu === "permissions"}
                    onClick={() => {
                      navigate("/admin/permissions");
                      (isMobile) && updateOpen();
                    }}
                    sx={{ display: 'block' }}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? 'initial' : 'center',
                        px: 2.5,
                        pl: 4,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : 'auto',
                          justifyContent: 'center',
                        }}
                      >
                        <SecurityIcon />
                      </ListItemIcon>
                      <ListItemText primary="Permissions" sx={{ opacity: open ? 1 : 0 }} />
                    </ListItemButton>
                  </ListItem>
                </List>
              </Collapse> */}
            {/* </>
          )} */}

          
          {/* {(role == 'admin') && (
            <ListItem
              disablePadding
              selected={activeMenu === "storefront"}
              onClick={() => {
                navigate("/admin/storefronts");
                (isMobile) && updateOpen();
              }}
              sx={{ display: 'block' }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <Storefront />
                </ListItemIcon>
                <ListItemText primary="Storefront" sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          )} */}
          {/* {(role != 'customer') && (
            <ListItem
              disablePadding
              selected={activeMenu === "product"}
              onClick={() => {
                navigate("/admin/products");
                (isMobile) && updateOpen();
              }}
              sx={{ display: 'block' }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <ShoppingCartIcon />
                </ListItemIcon>
                <ListItemText primary="Master Product" sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          )} */}
          {/* {(role != 'customer') && (
            <ListItem
              disablePadding
              selected={activeMenu === "product"}
              onClick={() => {
                navigate("/admin/products");
                (isMobile) && updateOpen();
              }}
              sx={{ display: 'block' }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <ShoppingCartIcon />
                </ListItemIcon>
                <ListItemText primary="Master Product" sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          )} */}
          {/* {(role != 'customer') && (
            <ListItem
              disablePadding
              selected={activeMenu === "brand"}
              onClick={() => {
                navigate("/admin/brands");
                (isMobile) && updateOpen();
              }}
              sx={{ display: 'block' }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <BrandingWatermarkIcon />
                </ListItemIcon>
                <ListItemText primary="Brand" sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          )} */}
          
          {/* {(role != 'customer') && (
            <ListItem
              disablePadding
              selected={activeMenu === "variation"}
              onClick={() => {
                navigate("/admin/variation");
                (isMobile) && updateOpen();
              }}
              sx={{ display: 'block' }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <CategoryIcon />
                </ListItemIcon>
                <ListItemText primary="Variation" sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          )} */}
          {/* <ListItem
            disablePadding
            selected={activeMenu === "sellerProduct"}
            onClick={() => {
              navigate("/admin/sellerProduct");
              (isMobile) && updateOpen();
            }}
            sx={{ display: 'block' }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <StoreIcon />
              </ListItemIcon>
              <ListItemText primary="Seller Product" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem> */}

          {/* <ListItem
            disablePadding
            selected={activeMenu === "orders"}
            onClick={() => {
              navigate("/admin/orders");
              (isMobile) && updateOpen();
            }}
            sx={{ display: 'block' }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <ShoppingBagIcon />
              </ListItemIcon>
              <ListItemText primary="Orders" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem> */}

        </List> 
        
      </Drawer>
    </Box>
  );
}