import { Box, Button, Card,CardHeader,CardContent,CircularProgress,Grid,Modal, Paper, Stack, Typography } from '@mui/material';
import React,{useContext, useEffect, useState} from 'react';
import { useDispatch } from 'react-redux';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { isMobile } from 'react-device-detect';
import AddRole from './AddRole';
import UserList from './UserList';
import Permissions from './Permissions';
import CircleIcon from '@mui/icons-material/Circle';
import { ArrowBack, EditOutlined } from '@mui/icons-material';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { RoleAndPermissionServices } from '../RoleAndPermissionServices';
import Swal from 'sweetalert2';
import { RoleDataContext } from '.';
import { PermissionGuard } from '../../../components/PermissionGuard';
import RoleList from './RoleList';
import LoadingOverlay from '../../../components/Layout/LoadingOverlay';

const style = {
    position: 'absolute',
    top: (isMobile) ? '80%' : '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #ddd',
    boxShadow: 24,
    p: 2,
};

const ViewRole = () => {
    const {actionTab,setActionTab,selectedRole,setSelectedRole,roleList,setRoleList} = useContext(RoleDataContext);
    const {id} = useParams();
    const [searchParams] = useSearchParams();
    const roleTitle = searchParams.get('role');

    const menuDispatch = useDispatch();
    menuDispatch({
        type: 'menuSelect',
        payload: 'roles'
    });
    const navigate = useNavigate();
    const [loading,setLoading] = useState(false);
    const [roleData,setRoleData] = useState([]);
    const [openModel,setOpenModel] = useState(false);

    const fetchRoleAndPermissionList = async ()=>{
        setLoading(true)
        try {
        const roleListResponse = await RoleAndPermissionServices.roleList();
        console.log("fetching role list response:",roleListResponse);
        const data = roleListResponse.permissionArr;
        if(roleListResponse.success=='1'){
            setRoleList(data);
            const updatedRoledata = data.find(role=>role.roleId == selectedRole.roleId);
            setSelectedRole(updatedRoledata);
        }
        } catch (error) {
            Swal.fire(error.message,'','error')
        }finally{
        setLoading(false)
        }
    };
    
    /******************* this is called when close button or discard button of edit modal is open from view  **********************/
    const handleClose=()=>{
        console.log("close handle hitted");
        setOpenModel(false);
        console.log("selected role:",selectedRole);
        console.log("role list:",roleList);
        fetchRoleAndPermissionList();
    }

    const handleBack=()=>{
        setSelectedRole(null);
        setActionTab(0);
    }
  return (
    <Box component="main" sx={{ flexGrow: 1, p: 2 }}>
        {/* =========== pop up models like edit services and sub services=============== */} 
        <Modal
            open={openModel}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ overflow: "auto" }}
            >
            <Box
                sx={{
                ...style,
                width: isMobile ? "95%" : "75%",
                maxHeight: "90vh", // Max height for the modal
                overflowY: "auto", // Enable scrolling within the modal
                // display: "flex",
                // flexDirection: "column",
                }}
            >
                <AddRole onClose={handleClose} />
            </Box>
        </Modal>
        {/* ================= page title section and containing add service/sub service button============ */}
        <Card sx={{ minWidth: "100%", marginBottom: "10px" }}>
            <Stack direction="row" spacing={2} className="" alignItems={'center'} sx={{p:'2%'}}>
                <Typography
                    gutterBottom
                    variant='h5'
                    component='div'
                    // sx={{ padding: '20px' }}
                >
                    View Role Details
                </Typography>
                <Typography
                    variant='h3'
                    component='div'
                    sx={{ flexGrow: 1 }}
                ></Typography>
                <PermissionGuard feature={'roles-and-permissions'} permission='canEdit'>
                    <Button variant='outlined' onClick={() => setOpenModel(true)} sx={{ height: "30px", marginTop: "10px" }} startIcon={<EditOutlined />} color='warning'>
                        Edit Role
                    </Button>
                </PermissionGuard>
                <Button variant='outlined' onClick={handleBack } sx={{ height: "30px", marginTop: "10px" }} startIcon={<ArrowBack />}>
                    Back
                </Button>
            </Stack>
        </Card>
        <Grid container spacing={2}>
            
            <Grid item xs={12}>
                <Card sx={{ mx: 'auto', mt: 1 }}>
                    <CardHeader 
                        title={selectedRole?.roleName} 
                        sx={{ 
                        borderBottom: 1, 
                        borderColor: 'divider',
                        '& .MuiCardHeader-title': {
                            fontSize: '1.25rem',
                            fontWeight: 600
                        },
                        textTransform:'uppercase'
                        }}
                    />
                    <Box sx={{borderBottom:'1px solid lightGray',px:2,py:1}}>
                        <Typography variant='h6' sx={{}}>Role Permissions</Typography>
                    </Box>
                    <CardContent sx={{py:1,minHeight:200,position:'relative'}} >

                       {loading && (
                            <LoadingOverlay 
                                message="Loading Related Permissions..."
                                blur={true}
                                opacity={0.7}
                                spinnerSize={48}
                            />
                        )}
                        <Permissions permissions={selectedRole?.permissions}/>
                    
                    </CardContent>
                    
                </Card>
                
            </Grid>
            <Grid item xs={12}>
                <UserList/>
            </Grid>
        </Grid>
    </Box>
  );
}

export default ViewRole;
