import React, { useContext, useEffect, useState } from 'react';
import { IconButton, Paper, TableBody, TextField, Table, TableCell, TableContainer, TableHead, TableRow, Box, Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import DeleteIcon from '@mui/icons-material/Delete';
import { Edit } from '@mui/icons-material';
import EditServiceDialog from './EditServiceDialog';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import NoRowsLayout from '../../../components/Layout/NoRowsLayout';
import { ProviderServices } from '../CrudServices';
import { UserProfileContext } from '../UserProfile';

const ServiceTable = (props) => {
    const {serviceList,fetchProviderServiceList,providerServiceModal} = props;
    const {serviceDetailsData,setServiceDetailsData} = useContext(UserProfileContext);
    const [selectedServiceToEdit,setSelectedServiceToEdit] = useState({});
    const [openUpdateDialog,setOpenUpdateDialog] = useState(false);

    const handleServiceSelection = (data)=>{
        setSelectedServiceToEdit(data);
        setOpenUpdateDialog(true);
    }
    const handleClose =()=>{
        fetchProviderServiceList({
            providerId:serviceDetailsData.providerId,
            page:controller.page+1,
            pageSize:controller.pageSize,
        });
        setOpenUpdateDialog(false)
    }

    /* =====================datagrid table related =========================*/
    const [loading, setLoading] = useState(false);
    const [allRowCount, setAllRowCount] = useState(0);
    const [controller, setController] = useState({
        page: 0,
        pageSize: 5,
        filters: {
          searchKey: "",
          status: 1,
        }
    });

    const handlePaginationChange = (newPagination) => {
        setController({
          ...controller,
          page: newPagination.page,
          pageSize: newPagination.pageSize
        });
    }

    const columns = [
        {
            field: 'serialNumber',
            headerName: 'S.No',
            width: 100,
            valueGetter: (params) => 
              controller.page * controller.pageSize + params.api.getRowIndexRelativeToVisibleRows(params.id)+1,
        },
        {
            field:'subCategoryTitle', headerAlign: 'center', headerName: "Category", flex: 1,editable: false,
        },
        {
            field:'masterServiceTitle', headerAlign: 'center', headerName: "Master Service", flex: 1,editable: false,
        },
        {
            field:'childServiceTitle', headerAlign: 'center', headerName: "Child Service", flex: 1,editable: false,
        },
        {
            field:'serviceTime', headerAlign: 'center', headerName: "Service Time (In mins.)", flex: 1,editable: false,
        },
        {
            field:'price', headerAlign: 'center', headerName: "Price (In rs.)", flex: 1,editable: false,
        },
        {field: 'action', headerAlign: 'center', headerName: 'Action', sortable: false, flex: 1,
          renderCell: (params) => {
            return (
              <Stack direction="row" spacing={1}>
                <IconButton
                  onClick={() => handleServiceSelection(params.row)}
                  size="small"
                  color='warning'
                >
                  <Edit fontSize="small" />
                </IconButton>
                {/* <IconButton
                //   onClick={() => handleRemoveService(service.servicesMasterId, service.servicesChildId)}
                    color='error'
                  size="small"
                >
                  <DeleteIcon fontSize="small" />
                </IconButton> */}
              </Stack>
            );
          },
        },
    // {
    //   field: "name", headerAlign: 'center', headerName: "Name", flex: 1, editable: false, valueGetter: (params) => {
    //     return `${params.row.profile.firstName} ${params.row.profile.middleName} ${params.row.profile.lastName}`;
    //   }
    // },
    ]

  useEffect(()=>{
    fetchProviderServiceList({
        providerId:serviceDetailsData.providerId,
        page:controller.page+1,
        pageSize:controller.pageSize,
    });
  },[controller]);
  useEffect(()=>{
    setAllRowCount(providerServiceModal?.totalItems);
  },[])
  return (
    <>
    {openUpdateDialog && <EditServiceDialog serviceData={selectedServiceToEdit} onClose={handleClose} openUpdateDialog={openUpdateDialog} />}
        <Box sx={{ overflow: "auto" }}>
            <Box sx={{ width: "100%", display: "table", tableLayout: "fixed", backgroundColor: "#fff",borderRadius:'.5rem' }}>
                <DataGrid
                    rows={serviceList}
                    columns={columns}
                    getRowId={(row) => row.servicesChildId}
                    //checkboxSelection={true}
                    rowCount={allRowCount}  // Use the length of the filtered list
                    pageSizeOptions={[5, 10, 20, 50, 100]}
                    paginationModel={controller}
                    paginationMode="server"
                    onPaginationModelChange={handlePaginationChange}

                    loading={loading}

                    disableColumnMenu
                    disableRowSelectionOnClick
                    slots={{
                        toolbar: GridToolbar,
                        noRowsOverlay: NoRowsLayout,
                    }}

                    rowHeight={50}
                    autoHeight
                    sx={{
                    '--DataGrid-overlayHeight': '300px',
                    "& .MuiDataGrid-cell": {
                        border: 1,
                        borderRight: 0,
                        borderTop: 0,
                        borderColor: 'lightgrey',
                        p: 0,
                        textAlign: 'center',
                        justifyContent: 'center',
                    },
                    '& .MuiDataGrid-columnHeaders': {
                        borderBottom: '2px solid rgba(224, 224, 224, 1)',
                        borderTop: '2px solid rgba(224, 224, 224, 1)',
                    },
                    '& .MuiDataGrid-columnHeader': {
                        borderLeft: '1px solid rgba(224, 224, 224, 1)',
                        '&:last-child': {
                        borderRight: 'none',
                        },
                    },
                    '& .MuiDataGrid-columnHeaderTitle': {
                        fontWeight: 'bold',
                        color: '#000000'
                    },
                    '& .MuiDataGrid-row': {
                        '&:last-child .MuiDataGrid-cell': {
                        borderBottom: '1px solid rgba(224, 224, 224, 1)',
                        },
                        '& .MuiDataGrid-cell:last-child': {
                        borderRight: 'none',
                        },
                    },
                    '& .MuiDataGrid-columnSeparator': {
                        display: 'none',
                    },
                    }}
                />
            </Box>
        </Box>
    </>
  );
}

export default ServiceTable;
