import React from 'react';
import { IconButton, Paper, TableBody, TextField, Table, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import DeleteIcon from '@mui/icons-material/Delete';

const formatValue = (value) => {
    if (typeof value !== 'string') {
      return value === 0 ? '0' : value?.toString() || '';
    }
    const formatted = value.replace(/^0+(?=\d)/, '').replace(/^\./, '0.');
    return formatted === '' ? '' : formatted;
};

const SelectedServices = ({ providerServiceList }) => {
  const { watch, setValue, formState: { errors }, register, trigger } = useFormContext();
  const services = watch('services') || [];

  const handleRemoveService = (servicesMasterId, servicesChildId) => {
    const updatedServices = services.filter(
      service => !(service.servicesMasterId === servicesMasterId && service.servicesChildId === servicesChildId)
    );
    setValue('services', updatedServices, { shouldDirty: true });
  };

  const handleUpdateService = async (servicesMasterId, servicesChildId, updatedFields) => {
    const updatedServices = services.map(service => {
      if (service.servicesMasterId === servicesMasterId && service.servicesChildId === servicesChildId) {
        return { ...service, ...updatedFields };
      }
      return service;
    });
    setValue('services', updatedServices, { shouldDirty: true });
    // Trigger validation for the updated field
    await trigger(`services`);
  };

  const isExistingService = (servicesMasterId, servicesChildId) => {
    return providerServiceList.some(
      service => service.servicesMasterId === servicesMasterId && service.servicesChildId === servicesChildId
    );
  };

  const newServices = services.filter(service => !isExistingService(service.servicesMasterId, service.servicesChildId));
  
  return (
    <TableContainer component={Paper}>
      <Table size="small" sx={{ minWidth: 650 }} aria-label="selected services table">
        <TableHead>
          <TableRow>
            <TableCell sx={{fontWeight:'bold'}}>Category</TableCell>
            <TableCell sx={{fontWeight:'bold'}}>Master Service</TableCell>
            <TableCell sx={{fontWeight:'bold'}}>Child Service</TableCell>
            <TableCell align="center" sx={{ width: '1rem',fontWeight:'bold' }}>Time (min)</TableCell>
            <TableCell align="center" sx={{ width: '1rem',fontWeight:'bold' }}>Price</TableCell>
            <TableCell align="center" sx={{ width: '1rem',fontWeight:'bold' }}>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {newServices.map((service, index) => (
            <TableRow key={`${service.servicesMasterId}-${service.servicesChildId}`}>
              <TableCell>{service.subCategoryTitle}</TableCell>
              <TableCell>{service.masterServiceTitle}</TableCell>
              <TableCell>{service.childServiceTitle}</TableCell>
              <TableCell align="right">
                <TextField
                  {...register(`services.${index}.serviceTime`, {
                    required: "Time is required",
                    min: { value: 1, message: "Time must be greater than 0" }
                  })}
                  type="number"
                  placeholder="0"
                  value={formatValue(service.serviceTime)}
                  onChange={(e) => {
                    const value = parseInt(e.target.value, 10);
                    if (value >= 0) {
                      handleUpdateService(
                        service.servicesMasterId,
                        service.servicesChildId,
                        { serviceTime: value }
                      );
                    } else if (e.target.value === '') {
                      handleUpdateService(
                        service.servicesMasterId,
                        service.servicesChildId,
                        { serviceTime: '' }
                      );
                    }
                  }}
                  size="small"
                  inputMode="numeric"
                  error={!!errors?.services?.[index]?.serviceTime}
                  // helperText={errors?.services?.[index]?.serviceTime?.message}
                  inputProps={{
                    style: { textAlign: 'center', width: '3rem', height: '1rem' },
                    min: 0
                  }}
                />
              </TableCell>
              <TableCell align="right">
                <TextField
                  {...register(`services.${index}.price`, {
                    required: "Price is required",
                    min: { value: 1, message: "Price must be greater than 0" }
                  })}
                  type="number"
                  placeholder="0"
                  value={formatValue(service.price)}
                  onChange={(e) => {
                    const value = formatValue(e.target.value);
                    if (!isNaN(value) && value >= 1) {
                      handleUpdateService(
                        service.servicesMasterId,
                        service.servicesChildId,
                        { price: value }
                      );
                    }
                    else if (!isNaN(value) && value > 0 && value < 1) {
                      handleUpdateService(
                        service.servicesMasterId,
                        service.servicesChildId,
                        { price: 1.00 }
                      );
                    } else if (e.target.value === '') {
                      handleUpdateService(
                        service.servicesMasterId,
                        service.servicesChildId,
                        { price: 0 }
                      );
                    }
                  }}
                  inputMode="numeric"
                  size="small"
                  error={!!errors?.services?.[index]?.price}
                  // helperText={errors?.services?.[index]?.price?.message}
                  inputProps={{
                    style: { textAlign: 'center', width: 'fit-content', height: '1rem' },
                    min: 0,
                    step: 0.01
                  }}
                />
              </TableCell>
              <TableCell align="right">
                <IconButton
                  onClick={() => handleRemoveService(service.servicesMasterId, service.servicesChildId)}
                  size="small"
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default SelectedServices;

// import React from 'react';
// import { IconButton, Paper, TableBody, TextField, Table, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
// import { useFormContext } from 'react-hook-form';
// import DeleteIcon from '@mui/icons-material/Delete';
// // import formatValue from '../../../utils/formatNumber';

// const formatValue = (value) => {
//     if (typeof value !== 'string') {
//       return value === 0 ? '0' : value?.toString() || '';
//     }
//     // Remove leading zeros, but keep one zero before decimal point if needed
//     const formatted = value.replace(/^0+(?=\d)/, '').replace(/^\./, '0.');
//     return formatted === '' ? '' : formatted;
// };

// const SelectedServices = ({ providerServiceList }) => {
//   const { watch, setValue } = useFormContext();
//   const services = watch('services') || [];

//   const handleRemoveService = (servicesMasterId, servicesChildId) => {
//     const updatedServices = services.filter(
//       service => !(service.servicesMasterId === servicesMasterId && service.servicesChildId === servicesChildId)
//     );
//     setValue('services', updatedServices, { shouldDirty: true });
//   };

//   const handleUpdateService = (servicesMasterId, servicesChildId, updatedFields) => {
//     const updatedServices = services.map(service => {
//       if (service.servicesMasterId === servicesMasterId && service.servicesChildId === servicesChildId) {
//         return { ...service, ...updatedFields };
//       }
//       return service;
//     });
//     setValue('services', updatedServices, { shouldDirty: true });
//   };

//   const isExistingService = (servicesMasterId, servicesChildId) => {
//     return providerServiceList.some(
//       service => service.servicesMasterId === servicesMasterId && service.servicesChildId === servicesChildId
//     );
//   };

//   const newServices = services.filter(service => !isExistingService(service.servicesMasterId, service.servicesChildId));
  
//   return (
//     <TableContainer component={Paper}>
//       <Table size='small' sx={{ minWidth: 650 }} aria-label="selected services table">
//         <TableHead>
//           <TableRow>
//             <TableCell sx={{fontWeight:'bold'}}>Category</TableCell>
//             <TableCell sx={{fontWeight:'bold'}}>Master Service</TableCell>
//             <TableCell sx={{fontWeight:'bold'}}>Child Service</TableCell>
//             <TableCell align="center" sx={{ width: '1rem',fontWeight:'bold' }}>Time (min)</TableCell>
//             <TableCell align="center" sx={{ width: '1rem',fontWeight:'bold' }}>Price</TableCell>
//             <TableCell align="center" sx={{ width: '1rem',fontWeight:'bold' }}>Actions</TableCell>
//           </TableRow>
//         </TableHead>
//         <TableBody>
//           {newServices.map((service) => (
//             <TableRow key={`${service.servicesMasterId}-${service.servicesChildId}`}>
//               <TableCell>{service.subCategoryTitle}</TableCell>
//               <TableCell>{service.masterServiceTitle}</TableCell>
//               <TableCell>{service.childServiceTitle}</TableCell>
//               <TableCell align="right">
//                 <TextField
//                   type="number"
//                   placeholder='0'
//                   // value={service.serviceTime === 0 ? '' : service.serviceTime}
//                   value={formatValue(service.serviceTime)}
//                   onChange={(e) => {
//                     const value = parseInt(e.target.value,10);
//                     console.log(value);
//                     // const value=formatValue(e.target.value)
//                     // const value = parseInt(e.target.value);
//                     if (value >= 0) {
//                       handleUpdateService(
//                         service.servicesMasterId,
//                         service.servicesChildId,
//                         { serviceTime: value }
//                       );
//                     } else if (e.target.value == '') {
//                       console.log("null",e.target.value)
//                       handleUpdateService(
//                         service.servicesMasterId,
//                         service.servicesChildId,
//                         { serviceTime: '' }
//                       );
//                     }
//                   }}
//                   size="small"
//                   inputMode="numeric"
//                   inputProps={{
//                     style: { textAlign: 'center', width: 'fit-content', height: '1rem',width:'3rem' },
//                     min: 0,
//                     // step: 1 // Only allow whole numbers
//                   }}
//                 />
//               </TableCell>
//               <TableCell align="right">
//                 <TextField
//                   type="number"
//                   placeholder='0'
//                   value={formatValue(service.price)}
//                   // value={service.price === 0 ? '' : service.price}
//                   onChange={(e) => {
//                     const value = formatValue(e.target.value);
//                     if (!isNaN(value) && value >= 1) {
//                       handleUpdateService(
//                         service.servicesMasterId,
//                         service.servicesChildId,
//                         { price: value }
//                       );
//                     }
//                     else if (!isNaN(value) && value > 0 && value <1 ) {
//                       handleUpdateService(
//                         service.servicesMasterId,
//                         service.servicesChildId,
//                         { price: 1.00 }
//                       );
//                     }else if (e.target.value === '') {
//                       handleUpdateService(
//                         service.servicesMasterId,
//                         service.servicesChildId,
//                         { price: 0 }
//                       );
//                     }
//                   }}
//                   // inputMode="decimal"
//                   inputMode="numeric"
//                   size="small"
//                   inputProps={{
//                       style: { textAlign: 'center', width: 'fit-content', height: '1rem' },
//                       min: 0,
//                       step: 0.01 // Allow two decimal places
//                   }}
//                 />
//               </TableCell>
//               <TableCell align="right">
//                 <IconButton
//                   onClick={() => handleRemoveService(service.servicesMasterId, service.servicesChildId)}
//                   size="small"
//                 >
//                   <DeleteIcon fontSize="small" />
//                 </IconButton>
//               </TableCell>
//             </TableRow>
//           ))}
//         </TableBody>
//       </Table>
//     </TableContainer>
//   );
// }

// export default SelectedServices;



// import React from 'react';
// import { IconButton, Paper, TableBody, TextField, Table, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
// import { useFormContext } from 'react-hook-form';
// import DeleteIcon from '@mui/icons-material/Delete';

// const SelectedServices = () => {
//   const { watch, setValue } = useFormContext();
//   const services = watch('services') || [];

//   const handleRemoveService = (servicesMasterId, servicesChildId) => {
//     const updatedServices = services.filter(
//       service => !(service.servicesMasterId === servicesMasterId && service.servicesChildId === servicesChildId)
//     );
//     setValue('services', updatedServices, { shouldDirty: true });
//   };

//   const handleUpdateService = (servicesMasterId, servicesChildId, updatedFields) => {
//     const updatedServices = services.map(service => {
//       if (service.servicesMasterId === servicesMasterId && service.servicesChildId === servicesChildId) {
//         return { ...service, ...updatedFields };
//       }
//       return service;
//     });
//     setValue('services', updatedServices, { shouldDirty: true });
//   };
  
//   return (
//     <TableContainer component={Paper}>
//       <Table size='small' sx={{ minWidth: 650 }} aria-label="selected services table">
//         <TableHead>
//           <TableRow>
//             {/* <TableCell sx={{fontWeight:'bold'}}>Category</TableCell> */}
//             <TableCell sx={{fontWeight:'bold'}}>Category</TableCell>
//             <TableCell sx={{fontWeight:'bold'}}>Master Service</TableCell>
//             <TableCell sx={{fontWeight:'bold'}}>Child Service</TableCell>
//             <TableCell align="center" sx={{ width: '1rem',fontWeight:'bold' }}>Time (min)</TableCell>
//             <TableCell align="center" sx={{ width: '1rem',fontWeight:'bold' }}>Price</TableCell>
//             <TableCell align="center" sx={{ width: '1rem',fontWeight:'bold' }}>Actions</TableCell>
//           </TableRow>
//         </TableHead>
//         <TableBody>
//           {services.map((service) => (
//             <TableRow key={`${service.servicesMasterId}-${service.servicesChildId}`}>
//               {/* <TableCell>{service.categoryTitle}</TableCell> */}
//               <TableCell>{service.subCategoryTitle}</TableCell>
//               <TableCell>{service.masterServiceTitle}</TableCell>
//               <TableCell>{service.childServiceTitle}</TableCell>
//               <TableCell align="right">
//                 {/* <TextField
//                   type="number"
//                   value={service.serviceTime === 0 ?'':service.serviceTime}
//                   onChange={(e) => handleUpdateService(service.servicesMasterId, service.servicesChildId, { serviceTime: parseInt(e.target.value) || '0' })}
//                   size="small"
//                   inputProps={{ style: { width: '3rem',height:'1rem' }, min: 0  }}
//                 /> */}
//                 <TextField
//                   type="number"
//                   value={service.serviceTime === 0 ?'':service.serviceTime} // Show empty when serviceTime is 0, to replace it easily
//                   onChange={(e) => {
//                     const value = parseInt(e.target.value);

//                     if (!isNaN(value) && value >= 0) {
//                       // Replace 0 with positive number, or keep it as 0 if the value is zero
//                       handleUpdateService(
//                         service.servicesMasterId,
//                         service.servicesChildId,
//                         { serviceTime: value }
//                       );
//                     } else if (e.target.value === '') {
//                       // If the field is cleared, set the value to 0
//                       handleUpdateService(
//                         service.servicesMasterId,
//                         service.servicesChildId,
//                         { serviceTime: 0 }
//                       );
//                     }
//                   }}
//                   size="small"
//                   inputProps={{ style: { width: '3rem', height: '1rem' }, min: 0 }} // Set min to 0 to prevent negative numbers
//                 />
//               </TableCell>
//               <TableCell align="right">
//               <TextField
//                   type="number"
//                   value={service.price === 0 ? '' : service.price} // Show empty when price is 0, to replace it easily
//                   onChange={(e) => {
//                     const value = parseFloat(e.target.value);

//                     if (!isNaN(value) && value >= 0) {
//                       // Replace 0 with positive number, or keep it as 0 if the value is zero
//                       handleUpdateService(
//                         service.servicesMasterId,
//                         service.servicesChildId,
//                         { price: value }
//                       );
//                     } else if (e.target.value === '') {
//                       // If the field is cleared, set the value to 0
//                       handleUpdateService(
//                         service.servicesMasterId,
//                         service.servicesChildId,
//                         { price: 0 }
//                       );
//                     }
//                   }}
//                   size="small"
//                   inputProps={{ style: { width: 'fit-content',textAlign:'end', height: '1rem' }, min: 0 }} // Set min to 0 to prevent negative numbers
//                 />

//               </TableCell>
//               <TableCell align="right">
//                 <IconButton
//                   onClick={() => handleRemoveService(service.servicesMasterId, service.servicesChildId)}
//                   size="small"
//                 >
//                   <DeleteIcon fontSize="small" />
//                 </IconButton>
//               </TableCell>
//             </TableRow>
//           ))}
//         </TableBody>
//       </Table>
//     </TableContainer>
//   );
// }

// export default SelectedServices;



// // import { IconButton, Paper, TableBody, TextField, Table, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
// // import React from 'react';
// // import { useFormContext } from 'react-hook-form';
// // import DeleteIcon from '@mui/icons-material/Delete';

// // const SelectedServices = ({ serviceList,child }) => {
// //   const { watch, setValue } = useFormContext();
// //   const services = watch('services') || [];

// //   const handleRemoveService = (servicesMasterId, servicesChildId) => {
// //     const updatedServices = services.filter(
// //       service => !(service.servicesMasterId === servicesMasterId && service.servicesChildId === servicesChildId)
// //     );
// //     setValue('services', updatedServices);
// //   };

// //   const handleUpdateService = (servicesMasterId, servicesChildId, updatedFields) => {
// //     const updatedServices = services.map(service => {
// //       if (service.servicesMasterId === servicesMasterId && service.servicesChildId === servicesChildId) {
// //         return { ...service, ...updatedFields };
// //       }
// //       return service;
// //     });
// //     setValue('services', updatedServices);
// //   };

// //   return (
// //     <TableContainer component={Paper}>
// //       <Table size='small' sx={{ minWidth: 650 }} aria-label="selected services table">
// //         <TableHead>
// //           <TableRow>
// //             <TableCell sx={{fontWeight:'bold'}}>Master Service</TableCell>
// //             <TableCell sx={{fontWeight:'bold'}}> Child Service</TableCell>
// //             <TableCell align="center" sx={{ width: '1rem',fontWeight:'bold' }}>Time (min)</TableCell>
// //             <TableCell align="center" sx={{ width: '1rem',fontWeight:'bold' }}>Price</TableCell>
// //             <TableCell align="center" sx={{ width: '1rem',fontWeight:'bold' }}>Actions</TableCell>
// //           </TableRow>
// //         </TableHead>
// //         <TableBody>
// //           {services.map((service) => (
// //             <TableRow key={`${service.servicesMasterId}-${service.servicesChildId}`}>
// //               <TableCell>
// //                 {serviceList.find(s => s.masterServiceId === service.servicesMasterId)?.title}
// //               </TableCell>
// //               <TableCell>{serviceList.find(s => s.masterServiceId === service.servicesMasterId)?.childServices.find(cs=>cs.childServiceId == service.servicesChildId)?.title}</TableCell>
// //               <TableCell align="right">
// //                 <TextField
// //                   type="number"
// //                   value={service.serviceTime}
// //                   onChange={(e) => handleUpdateService(service.servicesMasterId, service.servicesChildId, { serviceTime: parseInt(e.target.value) || 0 })}
// //                   size="small"
// //                   inputProps={{ style: { width: '3rem',height:'1rem' } }}
// //                 />
// //               </TableCell>
// //               <TableCell align="right">
// //                 <TextField
// //                   type="number"
// //                   value={service.price}
// //                   onChange={(e) => handleUpdateService(service.servicesMasterId, service.servicesChildId, { price: parseFloat(e.target.value) || 0 })}
// //                   size="small"
// //                   inputProps={{ style: { width: '3rem',height:'1rem' } }}
// //                 />
// //               </TableCell>
// //               <TableCell align="right">
// //                 <IconButton
// //                   onClick={() => handleRemoveService(service.servicesMasterId, service.servicesChildId)}
// //                   size="small"
// //                 >
// //                   <DeleteIcon fontSize="small" />
// //                 </IconButton>
// //               </TableCell>
// //             </TableRow>
// //           ))}
// //         </TableBody>
// //       </Table>
// //     </TableContainer>
// //   );
// // }

// // export default SelectedServices;