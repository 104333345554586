import React, { createContext, useEffect, useState } from 'react';
import Layout from '../../components/Layout/Layout';
import { Box, Grid } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import BookingDetails from './BookingDetails';
import { BookingService } from './Service';
import dayjs from 'dayjs';

const BookingDetailContext = createContext();
const BookingInfo = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [bookingDataInitialState,setBookingDataInitialState] = useState({
        address:'',
        timeSlot:'', // Set default time as one hour from now,
        categoryId: '',
        customer:'',
        landmark: '',
        paymentMode: 'pay after service',
        serviceArea:'',
        serviceDate:'',
        serviceExpert: '',
        services:[],
        subCategoryId:'',
    });
    
    const fetchBookingById = async ()=>{
        try {
            const bookingDetailResponse= await BookingService.bookingDetailById({bookingId:id});
            console.log("booking details of selected booking for edit:",bookingDetailResponse);
        } catch (error) {
            
        }
    }
    useEffect(()=>{
        if (id>0){
            fetchBookingById();
        }
    },[])
  return (
    <BookingDetailContext.Provider value={{bookingDataInitialState}}>
        <Layout pageName={id>0 ? 'Edit Booking':'New Booking'}>        
            <Box sx={{ marginTop: 1 }}>
                <Grid container spacing={1}>
                    <Grid item xs={12} lg={12}>
                        <BookingDetails/>
                    </Grid>
                </Grid>
            </Box>
        </Layout>
    </BookingDetailContext.Provider>
  );
}

export default BookingInfo;
export {BookingDetailContext}
