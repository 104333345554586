import { Box } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import ServiceList from "./ServiceList";

export default function Service(){
    const menuDispatch = useDispatch();
    menuDispatch({
        type: 'menuSelect',
        payload: 'service'
    });
    return (
        <>
            <Box component="main" sx={{ flexGrow: 1, p: 2 }}>
                <ServiceList />
            </Box>
        </>
    )
}

