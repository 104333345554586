import { commonRequest } from "../../services/ApiCall";
import { Helpers } from "../../services/Helpers";

export const BookingService = {
    bookingList : async (data) =>{
        // return await commonRequest("get",  `${Helpers.URL()}/api/admin/service-booking/list?page=1&limit=10&searchKey&sortKey&sortDirection&bookingStatus`,{}, Helpers.token());
        return await commonRequest("get",  `${Helpers.URL()}/api/admin/service-booking/list?page=${data.page+1}&limit=${data.limit}&searchKey=${data.filters.searchKey}&sortKey=${data.filters.sortKey}&sortDirection=${data.filters.sortDirection}&${data.filters.status=='all'?'bookingStatus=':data.filters.status}`,{}, Helpers.token());
    },
    // /api/admin/service-booking/list?page=1&limit=10&searchKey&sortKey&sortDirection&bookingStatus
    categoryList : async (data)=>{
        return await commonRequest("get",  `${Helpers.URL()}/api/admin/service-category/getCategories`,{}, Helpers.token());
    },

    subCategoryList : async (data)=>{
        return await commonRequest("get",  `${Helpers.URL()}/api/admin/service-category/getSubCategories/${data.categoryId}`,{}, Helpers.token());
    },

    servicablePincodeList:async (data)=>{
        return await commonRequest("get",  `${Helpers.URL()}/api/admin/provider-services/ServingPincodes/getAllServingPincodes`,{}, Helpers.token());
    },

    serviceExpertList:async (data)=>{
        return await commonRequest("get",  `${Helpers.URL()}/api/admin/provider-services/getProviders/${data.pincode}`,{}, Helpers.token());
    },

    serviceList : async (data)=>{
        return await commonRequest("get",  `${Helpers.URL()}/api/admin/provider-services/getServices/${data.providerId}/${data.subCategoryId}`,{}, Helpers.token());
    },

    timeSlots : async (data) =>{
        return await commonRequest("POST", `${Helpers.URL()}/api/admin/booking-time-slots/getProviderTimeSlots`,data, Helpers.token());
    },

    bookingDetailById : async (data) =>{
        return await commonRequest("get", `${Helpers.URL()}/api/admin/service-booking/getBookingDetails/${data.bookingId}`, {}, Helpers.token());
    },

    addBooking : async (data) =>{
        return await commonRequest("POST", `${Helpers.URL()}/api/admin/service-booking`,data, Helpers.token());
    },    
}