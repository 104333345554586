import { Close, Download, Share } from '@mui/icons-material';
import { Box, Button, Card, Dialog, DialogContent, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React, { useState } from 'react';

const BookingResult = ({onCloseDialog,bookingResponseDetails}) => {
    const [openDialog,setOpenDialog] = useState(true);
  return (
    <Dialog open={openDialog} fullWidth
        PaperProps={{
            sx: { borderRadius: '1rem' }
        }}
    >
        <Box textAlign={'right'} p={1}>
            <IconButton size='small' color='error' onClick={()=>onCloseDialog()}><Close/></IconButton>
        </Box>
      <DialogContent sx={{padding:'0'}}>
        <Box sx={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
            <Typography 
              sx={{fontWeight:700,
                color:`${bookingResponseDetails?.status?'green':'red'}`,
                fontSize:'xx-large',textTransform:'capitalize'}}
            >{bookingResponseDetails?.message}</Typography>
            {bookingResponseDetails?.status && <Box sx={{width:'85%',my:'3%'}}>
              <TableContainer component={Paper}>
                <Table>
                  <TableBody>
                    <TableRow >
                      <TableCell sx={{ p: 1,borderRight:'1px solid lightGray' ,bgcolor:'#8b878742',fontWeight:'bold'}}>Booking Number</TableCell>
                      <TableCell sx={{ p: 1 ,textAlign:'center',bgcolor:'#8b878742',fontWeight:'bold'}}>ORD#{bookingResponseDetails.data.orderDetails?.bookingNumber}</TableCell>
                    </TableRow>
                    <TableRow >
                      <TableCell sx={{ p: 1 ,borderRight:'1px solid lightGray' }}>Sub Total</TableCell>
                      <TableCell sx={{ p: 1 ,textAlign:'right'}}>{bookingResponseDetails?.data.orderDetails?.subTotal}/-</TableCell>
                    </TableRow>
                    <TableRow >
                      <TableCell sx={{ p: 1 ,borderRight:'1px solid lightGray' }}>Platform Charge</TableCell>
                      <TableCell sx={{ p: 1 ,textAlign:'right'}}>{bookingResponseDetails?.data.orderDetails?.platformCharges}/-</TableCell>
                    </TableRow>
                    <TableRow >
                      <TableCell sx={{ p: 1 ,borderRight:'1px solid lightGray' }}>Discount</TableCell>
                      <TableCell sx={{ p: 1 ,textAlign:'right'}}>{bookingResponseDetails?.data.orderDetails?.discount}/-</TableCell>
                    </TableRow>
                    <TableRow >
                      <TableCell sx={{ p: 1 ,borderRight:'1px solid lightGray' }}>Tax</TableCell>
                      <TableCell sx={{ p: 1 ,textAlign:'right'}}>{bookingResponseDetails?.data.orderDetails?.tax}/-</TableCell>
                    </TableRow>
                    <TableRow >
                      <TableCell sx={{ p: 1 ,borderRight:'1px solid lightGray',bgcolor:'#8b878742',fontWeight:'bold' }}>Total Amount To Pay</TableCell>
                      <TableCell sx={{ p: 1 ,textAlign:'right',bgcolor:'#8b878742',fontWeight:'bold'}}>Rs. {bookingResponseDetails?.data.orderDetails?.total}/-</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              {/* <Box sx={{display:'flex',borderBottom:'1px solid lightGray'}}>
                <Typography sx={{flex:1, fontWeight:700,fontSize:'large',mb:'5%'}}>Booking Number</Typography>
                <Typography sx={{flex:1,borderLeft:'1px solid lightGray',fontWeight:700,fontSize:'large'}}></Typography>
              </Box>
              <Box sx={{display:'flex'}}>
                <Typography sx={{flex:1, fontWeight:700,fontSize:'large',mb:'5%'}}></Typography>
                <Typography sx={{flex:1,borderLeft:'1px solid lightGray',fontWeight:700,fontSize:'large'}}></Typography>
              </Box> */}
            </Box>}
            {/* <Button endIcon={<Download/>} onClick={()=>onCloseDialog()}>Download Order Summary</Button>
            <Button endIcon={<Share/>} onClick={()=>onCloseDialog()}>Share</Button> */}
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default BookingResult;
