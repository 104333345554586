import React, { createContext, useState } from 'react';
import RoleList from './RoleList';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import AddRole from './AddRole';
import ViewRole from './ViewRole';

const RoleDataContext = createContext();
const Role = () => {
    const menuDispatch = useDispatch();
    menuDispatch({
        type: 'menuSelect',
        payload: 'roles'
    });
    const [actionTab,setActionTab] = useState(0);
    const [selectedRole,setSelectedRole] = useState(null);
    const [roleList,setRoleList] = useState([]);
    return (
        <RoleDataContext.Provider value={{actionTab,setActionTab,selectedRole,setSelectedRole,roleList,setRoleList}}>
            <Box component="main" sx={{ flexGrow: 1, p: 2 }}>
                {actionTab=='0' &&<RoleList/>}
                {actionTab=='1' && <AddRole/>}
                {actionTab=='2' && <ViewRole />}
            </Box>
        </RoleDataContext.Provider>
    )
}

export default Role;
export {RoleDataContext}