import { Route, Routes, Navigate } from "react-router-dom";
import Login from "./pages/Login";
import Private from "./components/Private";
import Home from "./pages/Home";
import 'react-toastify/dist/ReactToastify.css';
import Otp from "./pages/Login/MobileOtpModule/Otp";
import Sidebar from "./components/Sidebar";
import { Box } from "@mui/material";
import Navbar from "./components/Navbar";
import { LocalStore } from "./storage/authStore";
import Product from "./pages/Product";
import Brand from "./pages/Brand";
import Category from "./pages/Category";
import Service from "./pages/MasterService";
import Provider from "./pages/Provider";
import UserProfile from "./pages/Provider/UserProfile";
import ViewProfile from "./pages/Provider/viewProfile/ViewProfile";
import SellerProduct from "./pages/SellerProduct";
import AddSellerProduct from "./pages/SellerProduct/AddSellerProduct";
import Variation from "./pages/Variation";
import Orders from "./pages/Order";
import AddEditOrder from "./pages/Order/AddEditOrder";
import AddEditProduct from "./pages/Product/AddEditProduct";
import Customer from "./pages/Customer";
import CustomerProfile from "./pages/Customer/CustomerProfile";
import Booking from "./pages/Booking";
import BookingInfo from "./pages/Booking/BookingInfo";
import ForgotPassword from "./pages/ForgotPassword";
import Member from "./pages/Settings/members/index";
import Role from "./pages/Settings/roles";
import { ProtectedRoute } from "./components/ProtectedRoute";
import UnauthorizedPage from "./pages/UnAuthorized";
import Feature from "./pages/Settings/features/index";
import LoggedUserProfile from "./pages/Logged";

// Auth Route component to prevent accessing login pages when already authenticated
const AuthRoute = ({ element }) => {
  const isLogin = LocalStore.isLogin();
  if (isLogin) {
    return <Navigate to="/admin/dashboard" replace />;
  }
  return element;
};

function App() {
  const isLogin = LocalStore.isLogin();
  const userDetail = LocalStore.getCurrentUserDetails();
  const role = userDetail ? userDetail.role : 0;

  return (
    <>
      <div className={isLogin ? 'bodyBgColor' : ''}>
        {isLogin && <Navbar />}
        <Box sx={{ display: "flex" }} mt={isLogin ? 8 : 0}>
          {isLogin && <Sidebar />}
          <Routes>
            {/* Auth Routes - Protected from logged-in users */}
            <Route path="/" element={<AuthRoute element={<Login />} />} />
            <Route path="/login" element={<AuthRoute element={<Login />} />} />
            <Route path="/forgot-password" element={<AuthRoute element={<ForgotPassword />} />} />
            <Route path="/unauthorized" element={<UnauthorizedPage />} />

            
            <Route path="/admin" element={<Private />}>

              {/* =====================Dashboard ===================*/}
              <Route path="" element={<Home />} />
              <Route path="dashboard" element={<Home />} />

              {/* ==================Booking Management ===================*/}
              <Route path="bookings" element={
                <ProtectedRoute feature="booking" element={<Booking />} />
              } />
              <Route path="booking/:id" element={
                <ProtectedRoute feature="booking" element={<BookingInfo />} />
              } />

              {/* ====================Customer Management ========================*/}
              <Route path="customers" element={
                <ProtectedRoute feature="customer" element={<Customer />} />
              } />
              <Route path="customerProfile/:id" element={
                <ProtectedRoute feature="customer" element={<CustomerProfile />} />
              } />

              
              {/* ===================Category Management =================*/}
              <Route path="category" element={
                <ProtectedRoute feature="category" element={<Category />} />
              } />

              
              {/* ===================Service Management =================*/}
              <Route path="services" element={
                <ProtectedRoute feature="service" element={<Service />} />
              } />

              {/* ===============User/Provider Management ==============*/}
              <Route path="users" element={
                <ProtectedRoute feature="service-expert" element={<Provider />} />
              } />
              <Route path="expert-detail/:id" element={
                <ProtectedRoute feature="service-expert" element={<ViewProfile />} />
              } />

              <Route path="userProfile/:id" element={<UserProfile />} />
              {/* <Route path="logged/userProfile/:id" element={<LoggedUserProfile />} /> */}
            </Route>

            {/* Settings Routes */}
            <Route path="/settings" element={<Private />}>
              <Route path="members" element={
                <ProtectedRoute feature="register-admin" element={<Member />} />
              } />
              {/* <Route path="member/:id" element={
                <ProtectedRoute feature="register-admin" element={<AddMember />} />
              } /> */}
              <Route path="roles" element={
                <ProtectedRoute feature="roles-and-permissions" element={<Role />} />
              } />
              {/* <Route path="role/view/:id" element={
                <ProtectedRoute feature="roles-and-permissions" element={<ViewRole />} />
              } /> */}
              <Route path="features" element={
                <ProtectedRoute feature="roles-and-permissions" element={<Feature />} />
              } />
            </Route>
          </Routes>
        </Box>
      </div>
    </>
  );
}

export default App;






// import { Route, Routes } from "react-router-dom";
// import Login from "./pages/Login";
// import Private from "./components/Private";
// import Home from "./pages/Home";

// import 'react-toastify/dist/ReactToastify.css';
// import Otp from "./pages/Login/MobileOtpModule/Otp";
// import Sidebar from "./components/Sidebar";
// import { Box } from "@mui/material";
// import Navbar from "./components/Navbar";
// import { LocalStore } from "./storage/authStore";
// import Product from "./pages/Product";
// import Brand from "./pages/Brand";
// import Category from "./pages/Category";
// import Service from "./pages/MasterService";
// // import User from "./pages/AdminUser";
// // import UserProfile from "./pages/AdminUser/UserProfile";
// import Provider from "./pages/Provider";
// import UserProfile from "./pages/Provider/UserProfile";
// import ViewProfile from "./pages/Provider/viewProfile/ViewProfile";
// import SellerProduct from "./pages/SellerProduct";
// import AddProduct from "./pages/Product/AddProduct";
// import AddSellerProduct from "./pages/SellerProduct/AddSellerProduct";
// import Test from "./pages/Category/Test";
// import Variation from "./pages/Variation";
// import Orders from "./pages/Order";
// import AddEditOrder from "./pages/Order/AddEditOrder";
// import AddEditProduct from "./pages/Product/AddEditProduct";
// import Customer from "./pages/Customer";
// import CustomerProfile from "./pages/Customer/CustomerProfile";
// import Booking from "./pages/Booking";
// import BookingInfo from "./pages/Booking/BookingInfo";
// import ForgotPassword from "./pages/ForgotPassword";
// import Member from "./pages/Settings/members/index";
// import MemberList from "./pages/Settings/members/MemberList";
// import AddMember from "./pages/Settings/members/AddMember";
// import Permission from "./pages/Settings/permissions/index";
// import Role from "./pages/Settings/roles";
// import ViewRole from "./pages/Settings/roles/ViewRole";

// function App() {
//   const isLogin = LocalStore.isLogin();
//   const userDetail = LocalStore.getCurrentUserDetails();
//   const role = (userDetail) ? userDetail.role : 0;
//   return (
//     <>
//       <div className={isLogin && 'bodyBgColor'}>
//         {isLogin && <Navbar />}
//         <Box sx={{ display: "flex" }} mt={isLogin && 8}>
//           {isLogin && <Sidebar />}
//           <Routes>
//             <Route path="/" exact element={<Login />} />
//             <Route path="/login" exact element={<Login />} />
//             <Route path="/forgot-password" exact element={<ForgotPassword />} />

//             {/* <Route path="/otpVerify" exact element={<Otp />} /> */}

//             {/* Authorised Path */}
//             <Route path="/admin" element={<Private />} >
//               {(role == '1') && (
//                 <>
                  
//                   <Route path="products" element={<Product />} />
//                   <Route path="products/addEdit/:id" element={<AddEditProduct />} />
//                   <Route path="brands" element={<Brand />} />
//                   <Route path="category" element={<Category />} />
//                   <Route path="services" element={<Service />} />
//                   <Route path="variation" element={<Variation />} />
//                   <Route path="customers" element={<Customer />} />
//                   <Route path="bookings" element={<Booking />} />
//                   <Route path="customerProfile/:id" element={<CustomerProfile />} />
//                   <Route path="booking/:id" element={<BookingInfo />} />
//                 </>
//               )}
//               {role=='1'&&
//                 <>
//                   {/* <Route path="users" element={<User />} /> */}
//                   <Route path="users" element={<Provider />} />
//                   <Route path="userProfile/:id" element={<UserProfile />} />
//                 </>
//               }

              

//               <Route path="addEditOrder/:id" element={<AddEditOrder />} />
//               <Route path="sellerProduct" element={<SellerProduct />} />
//               <Route path="addSellerProduct/:sellerId/:sellerProductId/:productId" element={<AddSellerProduct />} />
//               <Route path="" element={<Home />} />
//               <Route path="dashboard" element={<Home />} />
//               <Route path="expert-detail/:id" element={<ViewProfile />} />
              
//               {/* <Route path="storefronts" element={<StoreFront />} />
//               <Route path="storefront/:id" element={<StoreFrontInfo/>} /> */}

//               <Route path="orders" element={<Orders />} />
//             </Route>
//             <Route path="/settings"  >
//               <Route path="members" element={<Member />} />
//               <Route path="member/:id" element={<AddMember />} />
//               <Route path="roles" element={<Role />} />
//               <Route path="role/view/:id" element={<ViewRole />} />
//               <Route path="permissions" element={<Permission />} />
//             </Route>
//           </Routes>
//         </Box>
//       </div>
//     </>
//   );
// }

// export default App;
