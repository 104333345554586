import React, { useContext, useEffect, useState } from 'react';
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Link, Typography } from '@mui/material';
import { Container } from "@mui/material";
import { ToastContainer, toast } from 'react-toastify';
import Loading from './Loading';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import LogoSection from './LogoSection';
import { useForm, Controller } from 'react-hook-form';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Auth } from '../../services/AuthServices';
import { LocalStore } from '../../storage/authStore';

const EmailPasswordLoginModule = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const { control, register, handleSubmit, setValue, trigger, formState: { errors, isDirty }, watch, reset, getValues } = useForm({
        mode: "onChange",
        defaultValue:{
            email: '',
            password: '',
        }
    });
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
    };
    const [responseDetails,setResponseDetails] = useState({
        status:'',
        message:'',
    })
    const handleLogin = async (data) => {
        setIsLoading(true);
        console.log("login credentials:", data);
        try {
            const loginResponse = await Auth.loginWithEmailPassword(data);
            console.log("login Response:",loginResponse);
            if (loginResponse.success == '1') {

                LocalStore.doLogin({
                    token: loginResponse.data.token,
                    role: loginResponse.data.user.roleId,
                    id: loginResponse.data.user.id,
                    permissions:loginResponse.data?.permissions,
                    user:loginResponse.data.user
                  });
                  window.location.assign('/admin');
            }else{
                setResponseDetails({status:false,message:loginResponse.message});
            }
        } catch (error) {
            
        }finally{
            setIsLoading(false);
        }
    }
    const email = watch('email');
    const password = watch('password');
    useEffect(()=>{
        if(responseDetails.message){
            setResponseDetails({  status:'', message:'' })
        }
    },[email,password])
    return (
        <>
            <Container component="main" maxWidth="lg">
                {isLoading && <Loading/>}
                <Box>
                    <Grid container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        sx={{ minHeight: '90vh' }}>
                        <CssBaseline />

                        <Grid item
                            component={Paper}
                            elevation={6}
                            square
                            sx={{
                                width: { xs: "100%", sm: "75%", md: "50%" },
                                maxWidth: "600px",
                                borderRadius: '1rem'
                            }}
                        >
                            <LogoSection />
                            <Paper
                                sx={{
                                    my: '3%',
                                    mx: 4,
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    p: '3%',
                                    borderRadius: '.75rem'
                                }}
                            >
                                {responseDetails.message && <Typography sx={{color:'red',fontSize:'x-small',bgcolor:'#e9c9c9',p:'1%'}}>{responseDetails.message}</Typography>}
                                <Box component={'form'} sx={{ width: '100%' }} onSubmit={handleSubmit(handleLogin)}>
                                    <Controller
                                        control={control}
                                        name='email'
                                        rules={{
                                            required:'Enter Registered Email ',
                                            pattern: {
                                                value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/,
                                                message: "Enter a valid email"
                                            }                                            
                                        }}
                                        render={({ field, fieldState: { error } }) => (
                                            <TextField
                                                {...field}
                                                fullWidth
                                                sx={{ my: '2%' }}
                                                size='small'
                                                label='Enter email'
                                                error={!!error}
                                                helperText={error?.message}
                                            />
                                        )}
                                    />
                                    <Box sx={{ my: '2%' }}>
                                        <Controller
                                            name='password'
                                            control={control}
                                            rules={{
                                                required: "Enter a valid password"
                                            }}
                                            render={({field,fieldState:{error}})=>(
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    size="small"
                                                    type={showPassword ? "text" : "password"}
                                                    label="Enter Password*"
                                                    error={!!error}
                                                    helperText={error?.message}
                                                    InputProps={{
                                                        endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={togglePasswordVisibility}
                                                            edge="end"
                                                            >
                                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            )}
                                        />
                                        
                                    </Box>
                                    <Box sx={{ my: '2%', textAlign: 'center' }}>
                                        <Button variant='contained' color='primary' type='submit'>Login</Button>
                                    </Box>
                                </Box>
                            </Paper>
                            <Box
                                sx={{
                                    display: "flex",
                                    // justifyContent: 'space-between',
                                    justifyContent:'flex-end',
                                    my: '3%',
                                    p: '3%',
                                }}
                            >
                                <Link component={RouterLink} to="/forgot-password" sx={{ color: '#0866ff' }} underline='hover'>Forgot Password</Link>
                                {/* <Typography>If not already registered? ...<span><Link component={RouterLink} to="#" underline='hover' sx={{ color: '#0866ff' }}>Sign Up</Link></span></Typography> */}
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Container>

            <ToastContainer />
            {isLoading && <Loading />}
        </>
    );
}

export default EmailPasswordLoginModule;