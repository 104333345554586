import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Card, Grid, TextField, Typography, IconButton } from '@mui/material';
import { Controller, useForm, useFieldArray } from 'react-hook-form';
import { UserProfileContext } from '../UserProfile';
import { Add as AddIcon, Delete, Remove as RemoveIcon } from '@mui/icons-material';
import { ProviderServices } from '../CrudServices';
import { resetServerContext } from 'react-beautiful-dnd';
import Swal from 'sweetalert2';
import Loading from '../Loading';

const BusinessDetails = ({businessDetailsData}) => {
  return (
        <Grid container spacing={2}>
            <Grid item xs={12} lg={7}>
                <Card sx={{}}>
                    <Box sx={{p:'.5% 2%',border:'2px solid lightGray',bgcolor:'lightGray'}}>
                        <Typography sx={{fontWeight:'bold'}}>Business Info</Typography>
                    </Box>
                    <Box sx={{p:'2%'}}>
                        <Box sx={{my:'1%'}}>
                            <Typography sx={{width:'60%' ,fontWeight:'bold', texttTransform:'uppercase', display:'flex ',alignItems:'center'}}>Business Name</Typography>
                            
                                    <TextField
                                        value={businessDetailsData?.businessName}
                                        fullWidth
                                        sx={{}}
                                        size='small'
                                    />                   
                        </Box>
                        <Box sx={{my:'1%'}}>
                            <Typography sx={{width:'60%' ,fontWeight:'bold', texttTransform:'uppercase', display:'flex ',alignItems:'center'}}>Business Email</Typography>
                            
                            <TextField
                                    value={businessDetailsData?.businessEmail}
                                    sx={{}}
                                    fullWidth
                                    size='small'
                            />                    
                        </Box>
                        <Box sx={{display:'flex',gap:'2%',my:"1%"}}>
                            <Box sx={{flex:1}}>
                                <Typography sx={{fontWeight:'bold', texttTransform:'uppercase', display:'flex ',alignItems:'center'}}>Business Mobile Number </Typography>
                                
                                        <TextField
                                            value={businessDetailsData?.businessMobileNumber}
                                            size='small'
                                    />
                            </Box>
                            
                            <Box sx={{flex:1}}>
                                <Typography sx={{fontWeight:'bold', textTransform:'uppercase', display:'flex', alignItems:'center'}}>Gst Number</Typography>
                                
                                    <TextField
                                    value={businessDetailsData?.gstNumber}
                                    type="text"
                                    fullWidth
                                    size="small"
                                />
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', gap: '2%', my: '1%' }}>
                            <Box sx={{ flex: 1 }}>
                                <Typography sx={{ fontWeight: 'bold', textTransform: 'uppercase', display: 'flex', alignItems: 'center' }}>
                                Pan Number</Typography>
                                
                                    <TextField
                                    value={businessDetailsData?.panNumber}
                                    type="text"
                                    fullWidth
                                    size="small"
                                />
                            </Box>
                            <Box sx={{ flex: 1 }}>
                                <Typography sx={{ fontWeight: 'bold', textTransform: 'uppercase', display: 'flex', alignItems: 'center' }}>
                                Aadhaar Number
                                </Typography>
                                <TextField
                                    value={businessDetailsData?.aadharNumber}
                                    type='text'
                                    fullWidth
                                    size='small'
                                />
                            </Box>
                        </Box>
                    </Box>
                </Card>
            </Grid>
            <Grid item xs={12} lg={5}>
                    <Card sx={{}}>
                        <Box sx={{p:'.5% 2%',border:'2px solid lightGray',bgcolor:'lightGray'}}>
                            <Typography sx={{fontWeight:'bold'}}>UPI Details</Typography>
                        </Box>
                        <Box sx={{ p: '2%' }}>
                            <Typography sx={{ fontWeight: 'bold', textTransform: 'uppercase', mb: 1 }}>UPI IDs</Typography>
                            {businessDetailsData?.upiId && businessDetailsData?.upiId.map((upi, index) => (
                                <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                    
                                            <TextField
                                                value={upi}
                                                fullWidth
                                                size="small"
                                    />
                                </Box>
                            ))}
                        </Box>
                    </Card>
            </Grid>
        </Grid>
  );
}

export default BusinessDetails;
