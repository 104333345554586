import React, { useEffect, useState } from 'react';
import { TextField, Button, Typography, IconButton, Grid, FormControl, InputLabel, Select, MenuItem, FormLabel, RadioGroup, FormControlLabel, Radio, Autocomplete } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useForm ,Controller} from 'react-hook-form';
import Swal from "sweetalert2";
import ImgUploadDialog from "../../components/ImgUpload/imgUpload";
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import { CrudServices } from "./CrudServices";
import { isMobile } from "react-device-detect";
import { BookingService } from "../Booking/Service";
import { Helpers } from '../../services/Helpers';
import Loading from '../Provider/Loading';


export default function AddService({ closeEvent,serviceData }) {
    const [isEditing,setIsEditing] = useState(false);
    const [serviceInitialState,setServiceInitialState] = useState({  
        title:'',
        shortTitle:'',
        description:'',
        image:'',
        categoryId:'',
        serviceCategoryId:'',
        parentServiceId:0,
        serviceType:'parent',
        
    })
    const { register,control, handleSubmit, reset, setValue, watch, formState: { errors,isDirty,dirtyFields } } = useForm({
        mode: "onChange",
        defaultValues:serviceInitialState
    });

    const [errorMsg, setErrorMsg] = useState("");
    const [modalTtitle, setModalTitle] = useState("Add Service");

    const [imageUploadResponseDetails,setImageUploadResponseDetails] = useState({});
    const [isLoading,setIsLoading] = useState (false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    
    const [categoryList,setCategoryList] = useState([]);
    const fetchCategoryList= async()=>{
        try {
          const categoryListResponse = await BookingService.categoryList();
          console.log("category list Response:",categoryListResponse);
          if(categoryListResponse.success===1){
            setCategoryList(categoryListResponse.data)
          }
        } catch (error) {
          
        }
    }
    
    /*================= on component load==================== */
    
    useEffect(() => {
      fetchCategoryList();
    }, []);
    /*================= on category selection==================== */
    const [subCategoryList,setSubCategoryList] = useState([]);
    const categoryId = watch('categoryId');
    const fetchSubCategoryList= async()=>{
        setIsLoading(true);
        try {
          const subCategoryListResponse = await BookingService.subCategoryList({categoryId:categoryId});
          console.log("sub category list Response:",subCategoryListResponse);
          if(subCategoryListResponse.success===1){
            setSubCategoryList(subCategoryListResponse.data)
          }
        } catch (error) {
          
        }finally{
            setIsLoading(false);
        }
    }
    useEffect(()=>{
        if(categoryId){
            fetchSubCategoryList();
        }
    },[categoryId])
    /*============== on SubCategory selection ============ */
    const subCategoryId=watch('serviceCategoryId');
    const serviceType = watch('serviceType', 'parent');
    const [parentServices,setParentServices] = useState([]);
    useEffect(()=>{
      if(subCategoryId && serviceType=='child'){
        // setValue('parentServiceId',0)
        setParentServices([]);
        const fetchServiceList= async()=>{
            setIsLoading(true);
          try {
            const serviceListResponse = await CrudServices.serviceListBySubCategory(subCategoryId);
            console.log("service list Response:",serviceListResponse);
            if(serviceListResponse.success===1){
                setParentServices(serviceListResponse.data)
            }
          } catch (error) {
            
          }finally{
            setIsLoading(false);
          }
        }
        fetchServiceList();
      }
    },[subCategoryId,serviceType])
    /*============================================*/
    useEffect(() => {
        // categoryList();
        if (serviceData.id>0) {
            if (serviceData.parentServiceId>0) {
                setServiceInitialState({serviceType:'child', categoryId: serviceData.categoryId,serviceCategoryId:serviceData?.serviceCategoryId,...serviceData});
            }else{
                setServiceInitialState(serviceData);
            }
            setModalTitle("Edit Service");
        }

    }, [serviceData])
    useEffect(()=>{
        reset(serviceInitialState);
    },[serviceInitialState,reset])
           
    const onSubmit=(data)=> {
        setIsSubmitting(true);
        console.log("raw data:",data);
        const modifiedData={
            title: data.title,
            shortTitle: data.shortTitle,
            description: data.description ,
            image: data.image,
            serviceCategoryId: data.serviceCategoryId,
            parentServiceId: serviceType=='parent'?0:data.parentServiceId,
        }
        const createRow = async () => {
            setErrorMsg("");
            let createRowRes = "";
            if (serviceData.id>0) {
                if (isDirty) {
                    // Filter out only the modified fields
                    const modifiedFields = Object.keys(dirtyFields).reduce((acc, key) => {
                        if (modifiedData.hasOwnProperty(key)) {
                            acc[key] = modifiedData[key];
                        }
                        return acc;
                    }, {});
                    // Always include title in the final data
                    if (!modifiedFields.hasOwnProperty('title')) {
                        modifiedFields.title = modifiedData.title;
                    }
                    console.log('Modified fields:', modifiedFields);
                    createRowRes = await CrudServices.updateService(serviceData.id, modifiedFields);
                    console.log("service update response:",createRowRes)
                  } 
                
            } else {
                console.log("data to be submitted for creating new:",modifiedData)
                createRowRes = await CrudServices.addService(modifiedData)
                console.log("service create response:",createRowRes)
            }

            if (createRowRes.success=='1') {
                Swal.fire(serviceData.id>0 ?'Service Data Updated Successfully':createRowRes.message, '', 'success')
                reset();
                closeEvent();
                setIsSubmitting(false);

            } else {
                setErrorMsg(createRowRes.message);
                setIsSubmitting(false)
            }
        }
        createRow();
    }
    /*================== image upload realted ====================== */
    
    const [imgModalOpen, setImgModalOpen] = useState(false);

    const openImgModal = () => {
        setImgModalOpen(true);
    };

    const closeModal = () => {
        setImgModalOpen(false);
        // afterAddPop(1);
    };
    
    const imgData = {
        path: 'service',
        img_type: "single"
    };
    const imgUploadResponse = (data) => {
        setImageUploadResponseDetails(data);
        setValue('image', data.imageName, { shouldDirty: true,shouldValidate: true });
    }
    return (
        <>
            {/* ============== image upload dialog ============= */}
            <ImgUploadDialog open={imgModalOpen} onClose={closeModal} 
                imgData={imgData} 
                imgUploadResponse={imgUploadResponse} 
            />
            <Typography variant="h5" align="center">
                {modalTtitle}
            </Typography>
            <IconButton
                style={{ position: "absolute", top: 0, right: 0 }}
                onClick={closeEvent}
            >
                <CloseIcon />
            </IconButton>
            <Typography variant="inherit" color="red">
                {errorMsg}
            </Typography>

            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2} mt={1}>
                    {isLoading || isSubmitting && <Loading/>}
                    <Grid item xs={12} >
                        <Grid container spacing={2}>
                        <Grid item xs={isMobile?12:6}>
                                <Controller
                                    control={control}
                                    name="categoryId"
                                    rules={{
                                        required:'select category',
                                    }}
                                    render={({field,fieldState:{error}})=>(
                                        <FormControl sx={{ minWidth: "100%" }} size="small">
                                            <InputLabel id="select_category">Select Category*</InputLabel>
                                            <Select
                                                {...field}
                                                id="select_category"
                                                label='select category*'
                                                error={!!error}
                                            >
                                            {
                                                categoryList.map((category,catIndex)=>(
                                                <MenuItem value={category.id} sx={{textTransform:'uppercase'}} key={catIndex}>{category.title}</MenuItem>
                                                ))
                                            }
                                            </Select>
                                        </FormControl>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={isMobile?12:6}>
                                <Controller
                                    control={control}
                                    name="serviceCategoryId"
                                    rules={{
                                        required:'Select Sub Category',
                                    }}
                                    render={({field,fieldState:{error}})=>(
                                        <FormControl sx={{ minWidth: "100%" }} size="small">
                                            <InputLabel id="select_sub_category">Select Sub Category*</InputLabel>
                                            <Select
                                                {...field}
                                                disabled={!categoryId}
                                                id="select_sub_category"
                                                label='Select Sub Category*'
                                                error={!!error}
                                            >
                                            {
                                                subCategoryList.map((subCategory,subCatIndex)=>(
                                                <MenuItem value={subCategory.id} sx={{textTransform:'uppercase'}} key={subCatIndex}>{subCategory.title}</MenuItem>
                                                ))
                                            }
                                            </Select>
                                        </FormControl>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl component="fieldset">
                                <FormLabel component="legend">Service Type</FormLabel>
                                <Controller
                                    name="serviceType"
                                    control={control}
                                    defaultValue={ watch('parentServiceId')==0 ? 'parent' : 'child' }
                                    rules={{ required: 'Please select a service type' }}
                                    render={({ field }) => (
                                    <RadioGroup {...field} row>
                                        <FormControlLabel value="parent" control={<Radio />} label="Parent Service" />
                                        <FormControlLabel value="child" control={<Radio />} label="Child Service" />
                                    </RadioGroup>
                                    )}
                                />
                                </FormControl>
                            </Grid>
                            {serviceType === 'child' && (
                                <Grid item xs={isMobile ? 12 : 6}>
                                    {/* <Controller
                                        control={control}
                                        name="parentServiceId"
                                        rules={{
                                            validate: (value) => {
                                                const serviceType = watch('serviceType');
                                                if (serviceType === 'parent') return true;
                                                return parseInt(value) > 0 || 'Please select a Parent service';
                                            }
                                        }}
                                        render={({field, fieldState: {error}}) => (
                                        <FormControl sx={{ minWidth: "100%" }} size="small">
                                            <InputLabel id="select_parent_service">Select Parent Service*</InputLabel>
                                            <Select
                                                {...field}
                                                id="select_parent_service"
                                                label='Select Parent Service*'
                                                error={!!error}
                                                disabled={!subCategoryId}
                                            >
                                                {parentServices.map((parentService, index) => (
                                                    <MenuItem value={parentService.masterServiceId} key={index}>{parentService.title}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        )}
                                    /> */}
                                    <Controller
                                        control={control}
                                        name="parentServiceId"
                                        rules={{
                                            validate: (value) => {
                                            if (serviceType === 'parent') return true;
                                            return value ? true : 'Please select a Parent service';
                                            }
                                        }}
                                        render={({ field, fieldState: { error } }) => (
                                            <Autocomplete
                                            {...field}
                                            options={parentServices}
                                            getOptionLabel={(option) => option.title || ''}
                                            isOptionEqualToValue={(option, value) => 
                                                option.masterServiceId === (value?.masterServiceId || value)
                                            }
                                            onChange={(event, newValue) => {
                                                field.onChange(newValue ? newValue.masterServiceId : null);
                                            }}
                                            value={parentServices.find(option => option.masterServiceId === field.value) || null}
                                            disabled={!subCategoryId}
                                            renderInput={(params) => (
                                                <TextField
                                                {...params}
                                                label="Select Parent Service*"
                                                error={!!error}
                                                helperText={error ? error.message : ''}
                                                size="small"
                                                />
                                            )}
                                            />
                                        )}
                                        />

                                </Grid>
                            )}
                            <Grid item xs={isMobile?12:6}>
                                <Controller
                                    control={control}
                                    name="title"
                                    rules={{
                                        required:'Service Name is required',
                                    }}
                                    render={({field,fieldState:{error}})=>(
                                        <TextField
                                            {...field}
                                            label="Enter Service Name*"
                                            variant="outlined"
                                            size="small"
                                            fullWidth 
                                            error={!!error}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={isMobile?12:6}>
                                <Controller
                                    control={control}
                                    name="shortTitle"
                                    rules={{
                                        required:'Service short title is required',
                                    }}
                                    render={({field,fieldState:{error}})=>(
                                        <TextField
                                            {...field}
                                            label="Enter Service Short Title*"
                                            variant="outlined"
                                            size="small"
                                            fullWidth 
                                            error={!!error}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={serviceType === 'child'?6:12}>
                                <Controller
                                    control={control}
                                    name="description"
                                    rules={{
                                        // required:'Category Name is required',
                                    }}
                                    render={({field,fieldState:{error}})=>(
                                        <TextField
                                            {...field}
                                            label="Enter Service Description"
                                            variant="outlined"
                                            size="small"
                                            fullWidth 
                                            error={!!error}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                        
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2} alignItems={'center'}>
                            {/* <Grid item xs={rowId.image ||imageUploadResponseDetails.imageName? 6 : 12} textAlign={'center'}> */}
                            <Grid item xs={6} textAlign={'center'}>
                                <Button 
                                    disabled={isSubmitting}
                                    fullWidth
                                    onClick={(e) => openImgModal()}
                                    sx={{ marginRight: 2 }}
                                    startIcon={<AddToPhotosIcon />}
                                    variant="outlined"
                                    color="primary"
                                >
                                    Upload Image
                                </Button>                  
                            </Grid>
                            <Grid item xs={6}>
                                {
                                    imageUploadResponseDetails.imageName ? 
                                    <img
                                    src={`${Helpers.ImagePathUrl()}/service/sm/${imageUploadResponseDetails.imageName}`}
                                />
                                :
                                serviceData.image && <img
                                    src={`${Helpers.ImagePathUrl()}/service/sm/${serviceData.image}`}
                                />
                                }
                                <Controller
                                    control={control}
                                    name='image'
                                    rules={{
                                        required:'please upload a image'
                                    }}
                                    render={({field})=>(
                                        <>
                                            <TextField
                                                sx={{display:'none'}}
                                            />
                                            <Typography sx={{fontSize:'small',color:'red'}}>{errors.image && errors.image?.message}</Typography>
                                        </>
                                    )}
                                />
                            </Grid>
                            
                        </Grid>
                    </Grid>
    
                    <Grid item xs={12}>
                        <Typography variant="h5" align="center">
                            <Button disabled={!isDirty || isSubmitting} variant="outlined" color="secondary" type="submit">
                                {/* {Object.keys(rowId).length ?'update':'Save'} */}
                                Save
                            </Button>
                        </Typography>
                    </Grid>
                </Grid>
            </form>
        </>
    )
}
