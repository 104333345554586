import { Box } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import FeatureList from './FeatureList';

const Feature = () => {
    const menuDispatch = useDispatch();
    menuDispatch({
        type: 'menuSelect',
        payload: 'features'
    });
    return (
        <>
            <Box component="main" sx={{ flexGrow: 1, p: 2 }}>
                <FeatureList/>
                
            </Box>
        </>
    )
}

export default Feature;
