
import { Box, Button, TextField, Typography,Link } from '@mui/material'
import {useContext, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Link as RouterLink,useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Auth } from '../../services/AuthServices';

const ResetPassword = () => {
    const navigate = useNavigate();
    const{register,handleSubmit,control,getValues,formState:{errors},clearErrors,reset,setValue,watch}= useForm();
    const [error,setError] = useState('');

    const submitHandler =async(data)=>{

        console.log("submitted email:",data);
        try {
            // Show loading state
            const loadingDialog = Swal.fire({
              title: 'Processing...',
              text: 'Please wait while sending new password to Regigesterd Email',
              allowOutsideClick: false,
              allowEscapeKey: false,
              showConfirmButton: false,
              didOpen: () => {
                Swal.showLoading();
              }
            });
      
            // Make the API call
            const response = await Auth.resetPasswordByEmail(data.email);
            console.log("password reset response:", response);
      
            // Always close the loading dialog
            await Swal.close();
      
            if (response.success == '1') {
                Swal.fire({
                    icon: 'success',
                    title: 'Reset Successfully!',
                    text: response.message || 'Failed to send otp'
                });
                navigate('/login');
                return true;
            } else {
              await Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: response.message || 'Failed to Reset Password'
              });
              return false;
            }
      
          } catch (error) {
            // Always close the loading dialog in case of error
            await Swal.close();
            
            console.error("Error Resetting Password:", error);
            await Swal.fire({
              icon: 'error',
              title: 'Error!',
              text: 'An error occurred while Resetting Password'
            });
            return false;
          }
    }
  return (
    <>
    <Box sx={{p:'2%', borderBottom:'2px solid',display:'flex',gap:'5%'}}>
        {/* <Image src='/aaomilkar_logo.png' alt='amkm_logo' width={30} height={10}/> */}
        <Typography sx={{flex:1,fontSize:'x-large',fontWeight:"bold"}}>Reset Password</Typography>
    </Box>
    <Box sx={{textAlign:'center', mt:'2%'}}>
        <Typography sx={{text:'x-small', color:'red'}}>{error && error}</Typography>
    </Box>
    <Box component='form' className='p-[5%]' onSubmit={handleSubmit(submitHandler)}>
        <Controller
            control={control}
            name="email"
            rules={{
                required:'enter Email',
                pattern:{
                    value:/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/,
                    message:"Email is not Valid"
                }
            }}
            render={({field,fieldState:{error}})=>(
                <TextField
                    {...field}
                    variant="outlined"
                    size="small"
                    fullWidth 
                    error={!!error}
                />
            )}
        />
        <Box sx={{my:'2%', textAlign:'center'}}>
            <Button variant='contained' color='primary' type='submit'>Generate New Password</Button>
        </Box>
        
    </Box>
    <Box sx={{borderTop:'2px solid' ,p:'2%', textAlign:'center'}}>
        <Typography>Want to try login again ?...
            <span>
                <Link component={RouterLink} to="/login" underline='hover' sx={{color:'#0866ff', fontWeight:'bold'}}>Go Back To Login</Link>
            </span>
        </Typography>
    </Box>
    </>
  )
}

export default ResetPassword
