import { useState, useEffect, useCallback } from 'react';
import { Card,Chip, Typography, Divider, Stack, TextField, Grid, Button, Box, Toolbar, FormControl, InputLabel, Select, Tooltip, IconButton, Badge, Avatar } from '@mui/material';
import Swal from 'sweetalert2';
import Modal from '@mui/material/Modal';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { isMobile } from 'react-device-detect';
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid';
import { User } from './UserService';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { useLocation, useNavigate } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import { formatDateTime } from '../../utils/dateUtils';
import { useForm, Controller } from 'react-hook-form';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SearchIcon from '@mui/icons-material/Search';
import { ProviderServices } from './CrudServices';
import NoRowsLayout from '../../components/Layout/NoRowsLayout';
import { CustomerService } from '../Customer/Service';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import calculateAge from '../../utils/ageCalculation';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import { PermissionGuard } from '../../components/PermissionGuard';
import { Helpers } from '../../services/Helpers';

const style = {
    position: 'absolute',
    top: (isMobile) ? '80%' : '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: (isMobile) ? "95%" : "25%",
    bgcolor: 'background.paper',
    border: '2px solid #ddd',
    boxShadow: 24,
    p: 2,
};


export default function UserList() {
    const { control, handleSubmit, reset, setValue, getValues, formState: { errors } } = useForm({
        mode: "onChange"
    });
     /************************  Table Data State Manage **************************** */
  const [allRowCount, setAllRowCount] = useState(0);
  const [list, setList] = useState([]);
  const [deleteRowData, setDeleteRowData] = useState({});
  const [controller, setController] = useState({
    page: 0,
    pageSize: 20,
    filters: {
      searchKey: "",
      status: 1,
    }
  });

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  /************************  Manage Use Effect **************************** */
  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true)
        const listResponse = await ProviderServices.fetchProviderList({
          page: controller.page+1,
          limit: controller.pageSize,
          filters: {
            searchKey: controller.filters.searchKey,   // Use the title filter from the controller
            status: controller.filters.status,
          }
        });
        console.log("Service Expert list Response:",listResponse)
        setValue('status', controller.filters.status)
        if (listResponse.success=='1') {
          setList(listResponse.data.list);
          setAllRowCount(listResponse.data.totalItems);
        } 
        else {
        //   Swal.fire('Request failed','','error')
        
        }
      } catch (error) {
        Swal.fire(error.message,'','error')
      }
      setLoading(false)
    };
    getData();
  }, [controller, deleteRowData]);

    /************************  Table Column Manage **************************** */
    // const columns = [
    //     { field: "id", headerName: "S.NO.", width: 100, editable: false ,headerAlign: 'center',},
    //     {
    //         field: "businessName", headerName: "Business Name", flex:1, editable: false, valueGetter: (params) => {
    //             return `${params.row.UserBusinessDetail?.businessName? params.row.UserBusinessDetail?.businessName:''}`;
    //         }
    //     ,headerAlign: 'center',},
        
    //     { field: "mobileNumber", headerName: "Mobile Number",  flex:1, editable: false ,headerAlign: 'center',},
    //     {
    //         field: "profile", headerName: "Personal Details",  flex:1, renderCell: (params) => {
    //             return (
    //                 <>
    //                     {params.row.UserProfile ? (
    //                         <Button
    //                             variant="contained"
    //                             size='small'
    //                             sx={{ fontSize: "11px" }}
    //                             color="success"
    //                             // onClick={(e) => changeStatus(params.row.id, params.row.status)}
    //                         >
    //                             completed
    //                         </Button>
    //                     ) : (
    //                         <Button
    //                             variant="contained"
    //                             size='small'
    //                             sx={{ fontSize: "11px" }}
    //                             color="error"
    //                             // onClick={(e) => changeStatus(params.row.id, params.row.status)}
    //                         >
    //                             Pending
    //                         </Button>
    //                     )}
    //                 </>
    //             )
    //         }
    //     ,headerAlign: 'center',},
    //     {
    //         field: "businessProfile", headerName: "BusinessProfile",  flex:1, renderCell: (params) => {
    //             return (
    //                 <>
    //                     {params.row.UserBusinessDetail ? (
    //                         <Button
    //                             variant="contained"
    //                             size='small'
    //                             sx={{ fontSize: "11px" }}
    //                             color="success"
    //                             // onClick={(e) => changeStatus(params.row.id, params.row.status)}
    //                         >
    //                             completed
    //                         </Button>
    //                     ) : (
    //                         <Button
    //                             variant="contained"
    //                             size='small'
    //                             sx={{ fontSize: "11px" }}
    //                             color="error"
    //                             // onClick={(e) => changeStatus(params.row.id, params.row.status)}
    //                         >
    //                             Pending
    //                         </Button>
    //                     )}
    //                 </>
    //             )
    //         }
    //     ,headerAlign: 'center',},

    //     // {
    //     //     field: "role", headerName: "User Type", width: 150, editable: false, renderCell: (params) => {
    //     //         return (
    //     //             <>
    //     //                 <Box>
    //     //                     {params.row.userType == 2 && (
    //     //                         <Typography sx={{ backgroundColor: "#d7e9f7", width: "113px", color: "#1565c0", textAlign: "center", borderRadius: "4px", fontSize: "13px", padding: "4px 10px" }}>
    //     //                             Seller
    //     //                         </Typography>
    //     //                     )}
    //     //                     {params.row.userType == 3 && (
    //     //                         <Typography sx={{ backgroundColor: "#f5ff9c", width: "113px", color: "#827717", textAlign: "center", borderRadius: "4px", fontSize: "13px", padding: "4px 10px" }}>
    //     //                             Sarthi
    //     //                         </Typography>
    //     //                     )}
    //     //                     {params.row.userType == 4 && (
    //     //                         <Typography sx={{ backgroundColor: "#ffe9b9", width: "113px", color: "#e65100", textAlign: "center", borderRadius: "4px", fontSize: "13px", padding: "4px 10px" }}>
    //     //                             Provider
    //     //                         </Typography>
    //     //                     )}
    //     //                 </Box>
    //     //             </>
    //     //         )
    //     //     }
    //     // ,headerAlign: 'center',},
    //     {
    //         field: "status", headerName: "Status",  flex:1, renderCell: (params) => {
    //             return (
    //                 <>
    //                     {params.row.isActive ? (
    //                         <Button
    //                             variant="contained"
    //                             size='small'
    //                             sx={{ fontSize: "11px" }}
    //                             color="success"
    //                             // onClick={(e) => changeStatus(params.row.id, params.row.status)}
    //                         >
    //                             Active
    //                         </Button>
    //                     ) : (
    //                         <Button
    //                             variant="contained"
    //                             size='small'
    //                             sx={{ fontSize: "11px" }}
    //                             color="error"
    //                             // onClick={(e) => changeStatus(params.row.id, params.row.status)}
    //                         >
    //                             InActive
    //                         </Button>
    //                     )}
    //                 </>
    //             )
    //         }
    //     ,headerAlign: 'center',},
    //     // {
    //     //     field: "createdAt", headerName: "Created Date", width: 250, valueGetter: (params) => {
    //     //         return formatDateTime(params.row.createdAt)
    //     //     }
    //     // ,headerAlign: 'center',},
    //     {
    //         field: "actions", headerName: "Action",  flex:1, renderCell: (params) => {

    //             return (
    //                 <Stack spacing={2} direction="row">
    //                     <RemoveRedEyeIcon
    //                         style={{
    //                             fontSize: "20px",
    //                             color: "blue",
    //                             cursor: "pointer",
    //                         }}
    //                         className="cursor-pointer"
    //                         onClick={(e) => navigate(`/admin/userProfile/${params.row.id}`)}
    //                     />
    //                     {/* <DeleteIcon
    //                         style={{
    //                             fontSize: "20px",
    //                             color: "red",
    //                             cursor: "pointer",
    //                         }}
    //                         className="cursor-pointer"
    //                         onClick={(e) => deleteRow(params.row.id)}
    //                     /> */}
    //                 </Stack>
    //             );
    //         }
    //     ,headerAlign: 'center',},
    // ];
    const columns = [
        { field: 'id', headerName: 'ID', width: 70 ,headerAlign: 'center',},
        { 
          field: 'name', 
          headerName: 'Profile', 
          flex: 2.5,
          headerAlign: 'center',
          valueGetter: (params) => {
            const firstName = params.row.UserProfile?.firstName || '';
            const lastName = params.row.UserProfile?.lastName || '';
            const email = params.row.UserProfile?.email || '';
            const age = calculateAge(params.row.UserProfile?.dob) || '';
            const mobile = params.row.mobileNumber || '';
            
            // Combine all profile information into a single searchable string
            return `${firstName} ${lastName} ${email} ${age} ${mobile}`.trim();
          },
          renderCell: (params) => (
            <Box sx={{ p: 1,display:'flex',gap:'5%' ,alignItems:'center',justifyContent:'flex-start',width:'100%'}}>
              <Avatar src={`${Helpers.ImagePathUrl()}/profile/sm/${params.row.UserProfile?.profileImage}`} />
              <Box>
                <Typography variant="body2" >
                  {`${params.row.UserProfile?.firstName || ''} ${params.row.UserProfile?.lastName || ''}`}
                </Typography>
                <Typography variant="caption">{params.row.UserProfile?.email || 'N/A'}</Typography>
                <Typography sx={{fontSize:'x-small'}}> <Box component={'span'} sx={{fontWeight:600}}>Mobile:</Box>{` ${params.row.mobileNumber}`}</Typography>
                <Typography sx={{fontSize:'x-small'}}><Box component={'span'} sx={{fontWeight:600}}>Age: </Box>{`${calculateAge(params.row.UserProfile?.dob) || 'N/A'}`}</Typography>              
              </Box>
            </Box>
          ),
        },
        { 
          field: 'status', 
          headerName: 'Status', 
          headerAlign: 'center',
          filterable: false,
          flex:.6,
          renderCell: (params) => (
            <Chip 
              label={params.row.isActive ? 'Active' : 'Inactive'} 
              color={params.row.isActive ? 'success' : 'error'} 
              size="small" 
            />
          ),
        },
        { 
          field: 'verification', 
          headerName: 'Verification', 
          headerAlign: 'center',
          filterable: false,
          flex:.75,
          renderCell: (params) => (
            <Box>
                {/*  */}
                {params.row.isPanVerified && <Box sx={{display:'flex',my:'5%',alignItems:'center'}}>
                    <Typography variant="body2" sx={{fontSize:'x-small',width:'75%',fontWeight:'bold'}}>PAN</Typography>
                    <Typography variant='body2' sx={{flex:1}}>:</Typography>
                    <IconButton size='small' sx={{p:0}}>{params.row.isPanVerified?<CheckCircleIcon color='success' sx={{fontSize:'1rem'}}/>:<CancelIcon color='error' sx={{fontSize:'1rem'}}/>}</IconButton>
                </Box>}
                {params.row.isAadhharVerified && <Box sx={{display:'flex',my:'5%',alignItems:'center'}}>
                    <Typography variant="body2" sx={{fontSize:'x-small',width:'75%',fontWeight:'bold'}}>Aadhaar</Typography>
                    <Typography variant='body2' sx={{flex:1}}>:</Typography>
                    <IconButton size='small' sx={{p:0}}>{params.row.isAadhharVerified?<CheckCircleIcon color='success' sx={{fontSize:'1rem'}}/>:<CancelIcon color='error' sx={{fontSize:'1rem'}}/>}</IconButton>
                </Box>}
                {!params.row.isPanVerified && !params.row.isAadhharVerified &&
                  <Box sx={{display:'flex',my:'5%',alignItems:'center'}}>
                    <Typography variant="body2" sx={{fontSize:'x-small',width:'75%',fontWeight:'bold'}}>KYC</Typography>
                    <Typography variant='body2' sx={{flex:1}}>:</Typography>
                    <IconButton size='small' sx={{p:0}}><CancelIcon color='error' sx={{fontSize:'1rem'}}/></IconButton>
                  </Box>
                }
            </Box>
          ),
        },
        { 
          field: 'categories', 
          headerName: 'Categories', 
          headerAlign: 'center',
          filterable: false,
          flex:1.25,
          renderCell: (params) => (
            <Box sx={{}}>
              {params.row.providerCat.length>0 && params.row.providerCat.map((cat, index) => (
                  <>
                  <Chip key={index} label={cat.proServCat.title} size="small" sx={{my:'1%'}}/>
                  <br/>
                </>
              ))}
              
              <Typography sx={{fontSize:'x-small'}}>{params.row.providerCat.length=='0' && 'N/A'}</Typography>
            </Box>
          ),
        },
        // { 
        //   field: 'address', 
        //   headerName: 'Address', 
        //   headerAlign: 'center',flex:1,
        //   renderCell: (params) => (
        //     <Box>
        //       {params.row.userAllAddress.map((address, index) => (
        //         <Typography key={index} variant="caption" display="block">
        //           {`${address.address}, ${address.city}, ${address.state}`}
        //         </Typography>
        //       ))}
        //     </Box>
        //   ),
        // },
        { 
          field: 'businessDetail', 
          headerName: 'Business Details',headerAlign: 'center',flex:1.5,filterable:false,
          renderCell: (params) => (
            <Box sx={{display:'flex',flexDirection:'column'}}>
            {params.row.UserBusinessDetail ? (
              
              <Box sx={{textAlign:'center'}}>
                <Typography variant="body2" sx={{textAlign:'center',fontWeight:600}}>{params.row.UserBusinessDetail.businessName}</Typography>
                <Typography variant="caption" sx={{textAlign:'center'}}>{params.row.UserBusinessDetail.businessEmail}</Typography>
                {/* <Typography variant="caption" sx={{textAlign:'center'}}>{params.row.Storefront? ''}</Typography> */}
              </Box>
            ) : 
            <Box sx={{display:'flex',my:'5%',alignItems:'center',justifyContent:'flex-end'}}>
              <Typography variant="body2" sx={{fontWeight:'bold',fontSize:'x-small'}}>Business Profile</Typography>
              <Typography variant='body2' sx={{flex:1}}>:</Typography>
              <IconButton size='small' sx={{p:0}}><CancelIcon color='error' sx={{fontSize:'1rem'}}/></IconButton>
            </Box>
            }
            <Box sx={{display:'flex',my:'5%',alignItems:'center',justifyContent:`${params.row.UserBusinessDetail ?'center':'flex-end'}`}}>
              <Typography variant="body2" sx={{fontWeight:'bold',fontSize:'x-small'}}>Storefront</Typography>
              <Typography variant='body2' sx={{}}>:</Typography>
              <IconButton size='small' sx={{p:0}}>{params.row.Storefront?<CheckCircleIcon color='success' sx={{fontSize:'1rem'}}/>:<CancelIcon color='error' sx={{fontSize:'1rem'}}/>}</IconButton>
            </Box>
          </Box>
          ),
        },
        {
            field: "actions", headerName: "Action",filterable: false,  flex:1, renderCell: (params) => {

                return (
                    <Stack spacing={2} direction="row">
                        <RemoveRedEyeIcon
                            style={{
                                fontSize: "20px",
                                color: "blue",
                                cursor: "pointer",
                            }}
                            className="cursor-pointer"
                            onClick={(e) => navigate(`/admin/expert-detail/${params.row.id}`)}
                        />
                        <PermissionGuard feature='service-expert' permission='canEdit'>
                          <BorderColorOutlinedIcon
                              style={{
                                  fontSize: "20px",
                                  color: "#ff3700",
                                  cursor: "pointer",
                              }}
                              className="cursor-pointer"
                              onClick={(e) => navigate(`/admin/userProfile/${params.row.id}`)}
                          />
                        </PermissionGuard>
                        {/* <DeleteIcon
                            style={{
                                fontSize: "20px",
                                color: "red",
                                cursor: "pointer",
                            }}
                            className="cursor-pointer"
                            onClick={(e) => deleteRow(params.row.id)}
                        /> */}
                    </Stack>
                );
            },
            headerAlign: 'center',
        },
      ];
    /************************  Remove table row **************************** */
    const removeTableRow = async (id) => {
        // if (!id) return;
        // const delP = await Brand.deleteBrand(id);
        // if (delP.success) {
        //     setDeleteRow(id);
        //     Swal.fire('Deleted!', '', 'success')
        // } else {
        //     Swal.fire(delP.message, '', 'error')
        // }
    }

    const deleteRow = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showDenyButton: true,
            confirmButtonText: 'Yes, delete it!',
            denyButtonText: `No, cancel!`,
        }).then((result) => {
            if (result.isConfirmed) {
                removeTableRow(id);
            }
        })
    }

    const handlePaginationChange = (newPagination) => {
        setController({
            ...controller,
            page: newPagination.page,
            pageSize: newPagination.pageSize
        });
    }

    function onSubmit(data) {
        const filtersData = getValues();
        setController({
            ...controller,
            filters: {
                ...controller.filters,
                searchKey: filtersData.searchKey,
                status: filtersData.status
            }
        });
    }

    const updateStatus = async (id, status) => {
        if (!id) return;
        const statusRes = await User.changeStatus({ id: id, status: status });
        if (statusRes.success) {
            setDeleteRowData(id);
            Swal.fire('Status Change Successfully', '', 'success')
        } else {
            Swal.fire(statusRes.message, '', 'error')
        }
    }
    const changeStatus = (id, status) => {
        Swal.fire({
            title: 'Are you sure to change status?',
            icon: 'warning',
            showDenyButton: true,
            confirmButtonText: 'Yes, Change Status!',
            denyButtonText: `No, cancel!`,
        }).then((result) => {
            if (result.isConfirmed) {
                const newStatus = (status == 0) ? 1 : 0;
                updateStatus(id, newStatus);
            }
        })
    }
    const clearFilters = () => {
        reset();
        setController({
            ...controller,
            filters: {
                ...controller.filters,
                searchKey: "",
                status: ""
            }
        });
    }
    return (
        <>
            <Card sx={{ minWidth: "100%", marginBottom: "10px" }}>
                <Stack direction="row" spacing={2} className="">
                    <Typography
                        gutterBottom
                        variant='h5'
                        component='div'
                        sx={{ padding: '20px' }}
                    >
                        Manage Service Experts
                    </Typography>
                    <Typography
                        variant='h3'
                        component='div'
                        sx={{ flexGrow: 1 }}
                    ></Typography>
                    <Typography
                        gutterBottom
                        variant='h5'
                        component='div'
                        sx={{ padding: '10px' }}
                    >
                      <PermissionGuard feature='service-expert' permission='canCreate'>
                        <Button variant='contained' onClick={() => navigate(`/admin/userProfile/0`)} sx={{ height: "30px", marginTop: "10px" }} endIcon={<AddCircleIcon />}>
                            Add
                        </Button>
                      </PermissionGuard>
                    </Typography>

                </Stack>
            </Card>

            <Card sx={{ minWidth: "100%", marginBottom: "10px" }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2} sx={{ p: 2 }}>
                        <Grid item xs={12} lg={2}>
                            <Controller
                                name="searchKey"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        placeholder="Search here....."
                                        label="Search"
                                        size="small"
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>
                        {/* <Grid xs={6} lg={2}>
                            <FormControl sx={{ minWidth: "100%" }} size="small">
                                <InputLabel id="demo-simple-select-autowidth-label">Select Status</InputLabel>
                                <Controller
                                    name="status"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            label="Select Status"
                                        >
                                            <MenuItem value="2">All</MenuItem>
                                            <MenuItem value="1">Active</MenuItem>
                                            <MenuItem value="0">Inactive</MenuItem>
                                        </Select>
                                    )}
                                />
                            </FormControl>
                        </Grid> */}
                        <Grid item xs={12} lg={2}>
                            <Tooltip title="Search Button">
                                <IconButton
                                    color="primary"
                                    type="submit"
                                    sx={{ border: "1px solid", borderRadius: "7px", padding: "6px 6px" }}
                                >
                                    <SearchIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Clear All Filters">
                                <IconButton
                                    color="primary"
                                    sx={{ marginLeft: "10px", border: "1px solid", color: "#f00", borderRadius: "7px", padding: "6px 6px" }}
                                    onClick={clearFilters}
                                >
                                    <RestartAltIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </form>
            </Card>

            <Box sx={{ overflow: "auto" }}>
      <Box sx={{ width: "100%", display: "table", tableLayout: "fixed", backgroundColor: "#fff", borderRadius: '.5rem' }}>
        <DataGrid
          rows={list}
          columns={columns}
          getRowId={(row) => row.id}
          rowCount={allRowCount}
          pageSizeOptions={[5, 10, 20, 50, 100]}
          paginationModel={controller}
          paginationMode="server"
          onPaginationModelChange={handlePaginationChange}
          loading={loading}
          disableColumnMenu
          disableRowSelectionOnClick
          slots={{
            toolbar: GridToolbar,
          }}
          autoHeight
          getRowHeight={() => 'auto'}
          sx={{
            '& .MuiDataGrid-cell': {
              border: 1,
              borderRight: 0,
              borderTop: 0,
              borderColor: 'lightgrey',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            },
            '& .MuiDataGrid-columnHeaders': {
              borderBottom: '2px solid rgba(224, 224, 224, 1)',
              borderTop: '2px solid rgba(224, 224, 224, 1)',
            },
            '& .MuiDataGrid-columnHeader': {
              borderLeft: '1px solid rgba(224, 224, 224, 1)',
              '&:last-child': {
                borderRight: 'none',
              },
            },
            '& .MuiDataGrid-columnHeaderTitle': {
              fontWeight: 'bold',
              color: '#000000'
            },
            '& .MuiDataGrid-row': {
              '&:last-child .MuiDataGrid-cell': {
                borderBottom: '1px solid rgba(224, 224, 224, 1)',
              },
              '& .MuiDataGrid-cell:last-child': {
                borderRight: 'none',
              },
            },
            '& .MuiDataGrid-columnSeparator': {
              display: 'none',
            },
          }}
        />
      </Box>
    </Box>
        </>
    )
}