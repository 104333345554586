import React from "react";
import BarCharts from "../charts/BarCharts";
import { Box, Card, CardContent, Grid, Stack, Typography } from "@mui/material";
import StorefrontIcon from '@mui/icons-material/Storefront';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

import "../Home/home.css";
import CountUp from "react-countup";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import { useDispatch } from "react-redux";
import Testing from "./Testing";
import MultiFilterDataGrid from "./MultiFilterDataGrid";
import CustomDataGrid from "./CustomDataGrid";

export default function Home() {
    const menuDispatch = useDispatch();
    menuDispatch({
        type: 'menuSelect',
        payload: 'home'
    });
    
    return (
        <>
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>

            {/* <Grid container spacing={2}>
                <Grid item xs={12} lg={8}>
                    <Testing/>
                </Grid>
                <MultiFilterDataGrid/>
            </Grid> */}
            {/* <Grid container spacing={2}>
                <Grid item xs={12} lg={8}>
                    <CustomDataGrid/>
                </Grid>
            </Grid> */}
                
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={8}>
                        <Stack spacing={2} direction="row">
                            <Card sx={{ minWidth: 49 + "%", height: 150 }} className="gradient">
                                <CardContent>
                                    <div className="iconstyle">
                                        <CreditCardIcon />
                                    </div>
                                    <Typography gutterBottom variant="h5" component="div" sx={{ color: "#ffffff" }}>
                                        $
                                        <CountUp delay={0.4} end={88500} duration={0.6} />
                                    </Typography>
                                    <Typography gutterBottom variant="body2" component="div" sx={{ color: "#ffffff" }} >
                                        Total Earnings
                                    </Typography>

                                </CardContent>
                            </Card>
                            <Card sx={{ minWidth: 49 + "%", height: 150 }} className="gradientLight">
                                <CardContent>
                                    <div className="iconstyle">
                                        <ShoppingCartIcon />
                                    </div>
                                    <Typography gutterBottom variant="h5" component="div" sx={{ color: "#ffffff" }}>
                                        $
                                        <CountUp delay={0.4} end={877765} duration={0.6} />
                                    </Typography>
                                    <Typography gutterBottom variant="body2" component="div" sx={{ color: "#ccd1d1" }} >
                                        Total Orders
                                    </Typography>

                                </CardContent>
                            </Card>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <Stack spacing={2}>
                            <Card className="gradientLight">
                                <Stack spacing={2} direction="row">
                                    <div className="iconstyle">
                                        <StorefrontIcon />
                                    </div>
                                    <div className="paddingall" >
                                        <span className="pricetitle">$
                                            <CountUp delay={0.4} end={98254354} duration={0.6} />
                                            k</span>
                                        <br />
                                        <span className="pricesubtitle">Total Income</span>
                                    </div>
                                </Stack>
                            </Card>
                            <Card >
                                <Stack spacing={2} direction="row">
                                    <div className="iconstyleblack">
                                        <StorefrontIcon />
                                    </div>
                                    <div className="paddingall">
                                        <span className="pricetitle">$
                                            <CountUp delay={0.4} end={345366} duration={0.6} />
                                            k</span>
                                        <br />
                                        <span className="pricesubtitle">Total Income</span>
                                    </div>
                                </Stack>
                            </Card>
                        </Stack>
                    </Grid>
                </Grid>
                <Box height={20} />
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={8}>
                        <Card sx={{ height: 60 + "vh" }}>
                            <CardContent>
                                <BarCharts />
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item lg={4} xs={12}>
                        <Card sx={{ height: 60 + "vh" }}>
                            <CardContent>
                                <div className="paddingall">
                                    <span className="pricetitle">Popular Products</span>
                                </div>

                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Box>

        </>
    )
}