import React, { useContext, useEffect } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import {
  TextField,
  FormControl,
  Select,
  MenuItem,
  Button,
  Typography,
  Grid,
  Box,
  InputLabel,
  Stack,
  Paper,
} from '@mui/material';
import { User } from '../UserService';
import Swal from 'sweetalert2';
import { Helpers } from '../../../services/Helpers';
import { Watch } from '@mui/icons-material';
const UpiDetail = () => {
    const { control, handleSubmit, setValue, formState: { error,isDirty } ,watch,reset} = useFormContext();
  return (
    <Grid item lg={6} xs={12}>
        <Paper>
            <Box sx={{p:'.5% 2%',border:'2px solid lightGray',bgcolor:'lightGray'}}>
                <Typography sx={{fontWeight:'bold'}}>UPI Info</Typography>
            </Box>
            <Grid container spacing={2} sx={{ padding: '2%' }}>
                <Grid item lg={6} xs={12}>
                    <Controller
                        name="paytm_mobile_no"
                        control={control}
                        defaultValue=""
                        render={({ field ,fieldState:{error}}) => (
                            <TextField
                                {...field}
                                type='number'
                                // placeholder="Enter PayTm Mobile No"
                                label="Paytm Mobile Number"
                                size="small"
                                fullWidth
                                error={!!error}
                                helperText={error && error?.message}
                            />
                        )}
                    />
                </Grid>
                <Grid item lg={6} xs={12}>
                    <Controller
                        name="phonepay_mobile_no"
                        control={control}
                        rules={{
                            required:' empty not allowed'
                        }}   
                        render={({ field ,fieldState:{error}}) => (
                            <TextField
                                {...field}
                                type='number'
                                // placeholder="Enter PhonePay Mobile No"
                                label="PhonePay Mobile Number"
                                size="small"
                                fullWidth
                                error={!!error}
                                helperText={error && error?.message}
                            />
                        )}
                    />
                </Grid>
                <Grid item lg={6} xs={12}>
                    <Controller
                        name="gpay_mobile_no"
                        control={control}
                        defaultValue=""
                        render={({ field ,fieldState:{error}}) => (
                            <TextField
                                {...field}
                                type='number'
                                // placeholder="Enter GPAY Mobile Number"
                                label="GPAY Mobile Number"
                                size="small"
                                fullWidth
                                error={!!error}
                                helperText={error && error?.message}
                            />
                        )}
                    />
                </Grid>
                <Grid item lg={6} xs={12}>
                    <Controller
                        name="upi_id"
                        control={control}
                        defaultValue=""
                        render={({ field,fieldState:{error} }) => (
                            <TextField
                                {...field}
                                // placeholder="Enter UPI Id"
                                label="UPI Id"
                                size="small"
                                fullWidth
                                error={!!error}
                                helperText={error && error?.message}
                            />
                        )}
                    />
                </Grid>
            </Grid>
        </Paper>
    </Grid>
  );
}

export default UpiDetail;

