import React, { useState,useContext, useEffect } from 'react';
import {FormProvider , useForm, Controller } from 'react-hook-form';
import {
  TextField,
  FormControl,
  Select,
  MenuItem,
  Button,
  Typography,
  Grid,
  Box,
  InputLabel,
  Stack,
  Paper,
} from '@mui/material';
import { User } from '../UserService';
import Swal from 'sweetalert2';
import { Helpers } from '../../../services/Helpers';
import { Watch } from '@mui/icons-material';
import { UserProfileContext } from '../UserProfile';
import UpiDetail from './UpiDetail';
import { ProviderServices } from '../CrudServices';

export default function BankDetails({ userIdProp, bankDetail, onSubmitBank, tabNextChange }) {
  const {bankDetailsData,setBankDetailsData} = useContext(UserProfileContext);
  const [isSubmitted,setIsSubmitted] = useState(false);
  const bankDetailFormMethod = useForm({
    mode: "onChange",
    defaultValues:bankDetailsData
  });
  const { control, handleSubmit, setValue, formState: { error,isDirty } ,watch,reset} = bankDetailFormMethod;
  useEffect(() => {
    if (bankDetailsData) {
      reset(bankDetailsData);
    }
  }, [bankDetailsData,reset]);

  const onSubmit = async(data) => {
    console.log("submitted bank data:",data)
    // try {
    //   setIsSubmitted(true);
    //   const bankDetailUpdateResponse = await ProviderServices.updateUserBankDetail(data);
    //   console.log("Bank Detail Update Response:",bankDetailUpdateResponse);
    //   if(bankDetailUpdateResponse.success=='1'){
    //       const data = bankDetailUpdateResponse.data;
    //       // setBankDetailsData({
    //       //     "providerId": data.providerId,
    //       //     "businessName": data.businessName,
    //       //     "businessEmail": data.businessEmail,
    //       //     "businessMobileNumber": data.businessMobileNumber,
    //       //     "upiId": data.upiId,
    //       //     "aadharNumber": data.aadharNumber,
    //       //     "panNumber": data.panNumber,
    //       //     "gstNumber": data.gstNumber
    //       // });
    //       Swal.fire('Bank Detail Updated Successfully','','success');
    //       setIsSubmitted(false)
    //   }else{
    //       Swal.fire('Something Went Wrong','','error');
    //       setIsSubmitted(false)
    //   }
    // } catch (error) {
    //     setIsSubmitted(false)
    // }  
  };


  return (
    <FormProvider {...bankDetailFormMethod}>
      <Box sx={{ overflow: "auto"}}>
        <Box component={'form'} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2} sx={{ }}>
            <Grid item lg={6} xs={12}>
              <Paper>
                <Box sx={{p:'.5% 2%',border:'2px solid lightGray',bgcolor:'lightGray'}}>
                  <Typography sx={{fontWeight:'bold'}}>Bank Info</Typography>
                </Box>
                <Grid container spacing={2} sx={{ padding: '2%' }}>
                  <Grid item lg={6} xs={12}>
                    <Controller
                      name="bankName"
                      control={control}
                      defaultValue=""
                      rules={{
                        required:'Empty not allowed'
                      }}
                      render={({ field ,fieldState:{error}}) => (
                        <TextField
                          {...field}
                          // placeholder="Enter Bank Name"
                          label="Bank Name"
                          size="small"
                          fullWidth
                          error={!!error}
                          helperText={error && error?.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <Controller
                      name="accountNumber"
                      control={control}
                      defaultValue=""
                      rules={{
                        required:'Empty not allowed'
                      }}
                      render={({ field ,fieldState:{error}}) => (
                        <TextField
                          {...field}
                          type='number'
                          // placeholder="Enter Bank A/C Number"
                          label="Bank A/C Number"
                          size="small"
                          fullWidth
                          error={!!error}
                          helperText={error && error?.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <Controller
                      name="ifscCode"
                      control={control}
                      defaultValue=""
                      rules={{
                        required:'Empty not allowed'
                      }}
                      render={({ field ,fieldState:{error}}) => (
                        <TextField
                          {...field}
                          // placeholder="Enter Bank IFSC Code"
                          label="IFSC Code"
                          size="small"
                          fullWidth
                          error={!!error}
                          helperText={error && error?.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <Controller
                      name="beneficiaryName"
                      control={control}
                      defaultValue=""
                      rules={{
                        required:'Empty not allowed'
                      }}
                      render={({ field ,fieldState:{error}}) => (
                        <TextField
                          {...field}
                          // placeholder="Enter Register User Name In Bank"
                          label="Account Holder"
                          size="small"
                          fullWidth
                          error={!!error}
                          helperText={error && error?.message}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <UpiDetail/>
            <Grid item xs={12}>
              <Box textAlign={'center'}>
                <Button disabled={!isDirty || isSubmitted} type="submit" variant="contained" color="primary">
                  Update Banking Details
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box >
    </FormProvider>
  );
}
