import React from 'react';
import { useState, useEffect, useContext } from 'react';
import { Button, Box, Typography, Paper, Modal } from "@mui/material";
import { MuiOtpInput } from 'mui-one-time-password-input';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import ResendTimer from '../../components/ResendTimer';
// import Loading from "../Loading";
import { isMobile } from "react-device-detect";
import Loading from '../Login/Loading';
import { CustomerService } from './Service';
import Swal from 'sweetalert2';
import { Close } from '@mui/icons-material';
import {Controller, useForm} from 'react-hook-form'
import ChangeMobileNumber from './ChangeMobileNumber';

const getDeviceType = () => {
  const userAgent = navigator.userAgent;
  const width = window.innerWidth;

  if (/Mobi|Android/i.test(userAgent) || width <= 768) {
    return 'mobile';
  } else if (/Tablet|iPad/i.test(userAgent) || (width > 768 && width <= 1024)) {
    return 'tablet';
  } else {
    return 'desktop';
  }
};

export default function VerifyOtp({selectedCustomerDetail,onClose,sendOtp,setOpenOtpModal}) {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [disableVerifyButton, setDisableVerifyButton] = useState(true);

  const handleChange = (otpValue) => {
    setOtp(otpValue);

    // Disable the verify button if OTP is not complete
    if (otpValue.length === 6) {
      setDisableVerifyButton(false);
    } else {
      setDisableVerifyButton(true);
    }
  };

  const handleComplete = () => {
    // Once OTP is complete, enable the verify button
    setDisableVerifyButton(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const requiredData = {    
      mobileNumber: selectedCustomerDetail.mobileNumber,
      otp: Number(otp)
  }
    const otpVerificationResponse = await CustomerService.verifyMobileNoOtp(requiredData);
    console.log("otp Verification Response:",otpVerificationResponse);
    if (otpVerificationResponse.success == '1') {
      setIsLoading(false);
      Swal.fire({
        icon: 'success',
        title: 'Success!',
        text: otpVerificationResponse.message || 'Otp Verified Successfully',
        timer: 1500
      });
      onClose();
    }else{
      setIsLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: otpVerificationResponse.message || 'Otp Verification Failed'
      })
    }
  };

  const [resendOtpTime, setResendOtpTime] = useState(120);

  const handleOtpResend = async (mobileNumber) => {
      setOpenOtpModal(false);
      try {
        // Show loading state
        const loadingDialog = Swal.fire({
          title: 'Processing...',
          text: 'Please wait while Re-sending the otp',
          allowOutsideClick: false,
          allowEscapeKey: false,
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading();
          }
        });
  
        // Make the API call
        const response = await CustomerService.verifyCustomerByMobileNo({ mobileNumber: mobileNumber });
        console.log("mobile number submission response:", response);
  
        // Always close the loading dialog
        await Swal.close();
  
        if (response.success == '1') {
          setOpenOtpModal(true);
          return true;
        } else {
          await Swal.fire({
            icon: 'error',
            title: 'Error!',
            text: response.message || 'Failed to send otp'
          });
          return false;
        }
  
      } catch (error) {
        // Always close the loading dialog in case of error
        await Swal.close();
        
        console.error("Error Verifying Customer mobile number:", error);
        await Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: 'An error occurred while Verifying customer'
        });
        return false;
      }
    }
    /********** mobile number change related **********/
    
    const [openMobileNumberChangeModal,setOpenMobileNumberChangeModal] = useState(false);
    const handleModalClose = async()=>{
      setOpenMobileNumberChangeModal(false);
      onClose();
    }
    const handleEdit = async()=>{
      const result = await Swal.fire({
        text: "Do you want to Change Customer Mobile Number now?",
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Change now!',
        cancelButtonText: 'No, Cancel',
        allowOutsideClick: false,
        allowEscapeKey: false,
        showCloseButton: false,
      });
      if (result.isConfirmed) {
        setOpenMobileNumberChangeModal(true);
      }else{
        return false
      }
    }
  let otpBoxSize = isMobile ? "100%" : "350px";

  return (
    <>
      <Paper sx={{ m: 2, p: '2%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Box sx={{ textAlign: 'left', width: '100%', my: '2%' }}>
          <Typography component="h1" variant="h5">OTP Verification</Typography>
          <Typography component="h1" variant="h6">Enter the OTP you received to</Typography>
          <Typography component="h1" variant="h6">+91-{selectedCustomerDetail?.mobileNumber && selectedCustomerDetail.mobileNumber}</Typography>
        </Box>
        <Box component="form" noValidate sx={{ mt: 1 }} width={'100%'} onSubmit={handleSubmit}>
          <MuiOtpInput
            sx={{ width: otpBoxSize, margin: 'auto' }}
            onComplete={handleComplete}
            gap={2}
            length={6}
            value={otp}
            onChange={handleChange}
          />
          <Box sx={{ my: '5%', textAlign: 'center' }}>
            <Button disabled={disableVerifyButton} variant="contained" type="submit">
              Verify Otp
            </Button>
          </Box>
          <Typography textAlign={'center'}>
            OTP will expire in:
            <Box component={'span'} sx={{ color: `${resendOtpTime < 60 && resendOtpTime > 0 ? 'red' : 'green'}`, fontSize: '.75rem', ml: '2%' }}>
              <ResendTimer counter={resendOtpTime} setCounter={setResendOtpTime} />
            </Box>
            <Box component={'span'} sx={{ fontSize: '.5rem' }}> seconds</Box>
          </Typography>
          <Box sx={{ my: '5%', display: 'flex', justifyContent: 'space-between', padding: '0 5%' }}>
            <Button onClick={()=>handleOtpResend(selectedCustomerDetail.mobileNumber)} disabled={resendOtpTime > 0}>Resend OTP</Button>
            <Typography paragraph variant="body1">
              <span>Click Here! </span>
              <Box component={'span'} onClick={handleEdit}  sx={{ textDecoration: 'none', cursor: 'pointer', color: '#1976d2' }}>
                Change mobile number
              </Box>
            </Typography>
          </Box>
        </Box>
      </Paper>
      <ToastContainer />
      {isLoading && <Loading />}

      {/* ============= Change Mobile Number Otp  Modal =========== */}

      <Modal
        open={openMobileNumberChangeModal}
        // onClose={handleClose}
        sx={{ overflow: "scroll" }}
      >
        <Box sx={{
          position: 'absolute',
          top: (isMobile) ? '80%' : '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          // border: '2px solid #ddd',
          boxShadow: 24,
          p: 2,}}
          width={(isMobile) ? "95%" : "50%"}
        >
          <Box sx={{display:'flex',justifyContent:'space-between'}}>
            <Typography sx={{fontWeight:600,fontSize:'large'}}>Change Mobile Number</Typography>
            <Button size='small' variant='outlined' color='error' onClick={handleModalClose} startIcon={<Close/>} >close</Button>
          </Box>
          <ChangeMobileNumber customerData = {selectedCustomerDetail} onCloseModel={handleModalClose}/>
        </Box>
      </Modal>
    </>
  );
}
