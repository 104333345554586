import React, { useContext, useState, useEffect } from 'react';
import { Controller, useForm, useFieldArray } from 'react-hook-form';
import { 
  Box, 
  Typography, 
  Checkbox, 
  FormGroup, 
  FormControlLabel, 
  TextField,
  Button,
  Grid,
  IconButton,
  Card
} from '@mui/material';
import { Add as AddIcon, Remove as RemoveIcon } from '@mui/icons-material';
import { UserProfileContext } from '../UserProfile';
import { ProviderServices } from '../CrudServices';
import Swal from 'sweetalert2';
import Loading from '../Loading';
import { useParams } from 'react-router-dom';

const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const WorkingHours = ({ fetchedData }) => {
  const {id} = useParams();
  const [availabilityDetailData,setAvailabilityDetailData] = useState(
    {
        userId: id,
        dayTimeSlots: [ ]
    }
)
  const [isLoading, setIsLoading] = useState(false);
 
  const prepopulateData = (fetchedData) => {
    return days.map(day => {
      const dayData = fetchedData.dayTimeSlots.find(slot => slot.day === day);
      return {
        day,
        isSelected: !!dayData?.slots.length>0,
        slots: dayData ? dayData.slots : []
      };
    });
  };

  const { control, handleSubmit, setValue, formState: { errors, isDirty }, watch, reset } = useForm({
    mode: 'onChange',
    defaultValues: {
      userId: availabilityDetailData.userId || '',
      dayTimeSlots: prepopulateData(availabilityDetailData)
    }
  });
  
  useEffect(() => {
    if (availabilityDetailData.userId > 0) {
      const fetchProviderTimeSlots = async () => {
        setIsLoading(true);
        const timeSlotList = await ProviderServices.fetchProviderTimeSlots(availabilityDetailData.userId);
        setAvailabilityDetailData(timeSlotList.data);
        setIsLoading(false);
      }
      fetchProviderTimeSlots();
    }
  }, []);

  useEffect(() => {
    if (availabilityDetailData) {
      reset({
        userId: availabilityDetailData.userId || '',
        dayTimeSlots: prepopulateData(availabilityDetailData)
      });
    }
  }, [availabilityDetailData, reset]);

  const { fields } = useFieldArray({
    control,
    name: "dayTimeSlots"
  });

  const onSubmit = async (data) => {
    const selectedDays = data.dayTimeSlots.filter(day => day.isSelected && day.slots.some(slot => slot.startTime && slot.endTime));
    
    if (selectedDays.length === 0) {
      Swal.fire("Error", "Please select at least one day and add time slots.", "error");
      return;
    }

    setIsLoading(true);
    const formattedData = {
      userId: data.userId,
      dayTimeSlots: selectedDays.map(({ day, slots }) => ({
        day,
        slots: slots.filter(slot => slot.startTime && slot.endTime)
      }))
    };

    try {
      const timeslotCreateResponse = await ProviderServices.updateTimeSlot(formattedData);
      console.log("time slot update response:",timeslotCreateResponse);
      if (timeslotCreateResponse.success == '1') {
        Swal.fire("Success", "Time slots updated successfully", "success");
        setAvailabilityDetailData(timeslotCreateResponse.data);
      } else {
        Swal.fire("Error", "Something went wrong", "error");
      }
    } catch (error) {
      Swal.fire("Error", "An error occurred while updating time slots", "error");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && <Loading />}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card sx={{}}>
            <Box sx={{p:'.5% 2%',border:'2px solid lightGray',bgcolor:'lightGray'}}>
              <Typography sx={{fontWeight:'bold'}}>Working Hours</Typography>
            </Box>
            <Box sx={{p:'2%',display:'flex',flexWrap:'wrap',gap:'2%'}}>
              
                {fields.map((field, index) => {
                  const isSelected = watch(`dayTimeSlots.${index}.isSelected`);
                  return (
                    <Box key={field.id} sx={{ mb: 2,width:'49%' }}>
                      <Controller
                        name={`dayTimeSlots.${index}.isSelected`}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <FormControlLabel
                            control={<Checkbox checked={value} />}
                            label={field.day}
                          />
                        )}
                      />
                      {isSelected && (
                        <Controller
                          name={`dayTimeSlots.${index}.slots`}
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <>
                              {value.map((slot, slotIndex) => (
                                <Grid container spacing={2} key={slotIndex} sx={{ mb: 1, ml: 4 }}>
                                  <Grid item xs={6}>
                                    <TextField
                                      label="Start Time"
                                      type="time"
                                      size="small"
                                      value={slot.startTime}
                                    />
                                  </Grid>
                                  <Grid item xs={6}>
                                    <TextField
                                      label="End Time"
                                      type="time"
                                      size="small"
                                      value={slot.endTime}
                                    />
                                  </Grid>
                                  
                                </Grid>
                              ))}
                            </>
                          )}
                        />
                      )}
                    </Box>
                  );
                })}
              
            </Box>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default WorkingHours;