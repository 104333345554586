import React, { useEffect, useState, useMemo } from 'react';
import { Autocomplete, Box, Button, CircularProgress, Grid, TextField, Typography } from '@mui/material';
import { useFormContext, Controller } from 'react-hook-form';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import parse from 'autosuggest-highlight/parse';
import { debounce } from '@mui/material/utils';

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const CoordinateDetails = ({ prefix }) => {
  const { control, setValue } = useFormContext();
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const loaded = React.useRef(false);

  useEffect(() => {
    if (typeof window !== 'undefined' && !loaded.current) {
      if (!document.querySelector('#google-maps')) {
        loadScript(
          `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
          document.querySelector('head'),
          'google-maps',
        );
      }

      loaded.current = true;
    }
  }, []);

  useEffect(() => {
    if (!window.google) {
      const timer = setInterval(() => {
        if (window.google) {
          setScriptLoaded(true);
          clearInterval(timer);
        }
      }, 100);

      return () => clearInterval(timer);
    } else {
      setScriptLoaded(true);
    }
  }, []);

  const autocompleteService = useMemo(() => {
    if (scriptLoaded && window.google) {
      return new window.google.maps.places.AutocompleteService();
    }
    return null;
  }, [scriptLoaded]);

  const placesService = useMemo(() => {
    if (scriptLoaded && window.google) {
      return new window.google.maps.places.PlacesService(document.createElement('div'));
    }
    return null;
  }, [scriptLoaded]);

  const fetch = useMemo(
    () =>
      debounce((request, callback) => {
        if (autocompleteService) {
          autocompleteService.getPlacePredictions(request, callback);
        }
      }, 400),
    [autocompleteService],
  );

  useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setOptions([]);
      return undefined;
    }

    setLoading(true);

    fetch({ input: inputValue }, (results) => {
      if (active) {
        setOptions(results || []);
        setLoading(false);
      }
    });

    return () => {
      active = false;
    };
  }, [inputValue, fetch]);

  const handlePlaceSelect = (place) => {
    if (!place || !place.place_id || !placesService) return;

    placesService.getDetails(
      { placeId: place.place_id, fields: ['address_components', 'geometry', 'formatted_address'] },
      (placeResult, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          const addressComponents = placeResult.address_components;
          const lat = placeResult.geometry.location.lat();
          const lng = placeResult.geometry.location.lng();

          setValue(`${prefix}.address`, placeResult.formatted_address);
          setValue(`${prefix}.latitude`, lat.toString());
          setValue(`${prefix}.longitude`, lng.toString());
          setValue(`${prefix}.state`, addressComponents.find(c => c.types.includes('administrative_area_level_1'))?.long_name || '');
          setValue(`${prefix}.city`, addressComponents.find(c => c.types.includes('locality'))?.long_name || '');
          setValue(`${prefix}.pincode`, addressComponents.find(c => c.types.includes('postal_code'))?.long_name || '');
        }
      }
    );
  };


  return (
    <Box>
      <Controller
        name={`${prefix}.address`}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Autocomplete
            id={`${prefix}-address-autocomplete`}
            sx={{ width: '100%' }}
            size='small'
            getOptionLabel={(option) =>
              typeof option === 'string' ? option : option.description
            }
            filterOptions={(x) => x}
            options={options}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={value}
            onChange={(event, newValue) => {
              onChange(newValue ? newValue.description : '');
              handlePlaceSelect(newValue);
            }}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Address"
                fullWidth
                error={!!error}
                helperText={error?.message}
              />
            )}
            renderOption={(props, option) => {
              const matches = option.structured_formatting.main_text_matched_substrings || [];
              const parts = parse(
                option.structured_formatting.main_text,
                matches.map((match) => [match.offset, match.offset + match.length]),
              );

              return (
                <li {...props}>
                  <Grid container alignItems="center">
                    <Grid item>
                      <Box component={LocationOnIcon} sx={{ color: 'text.secondary', mr: 2 }} />
                    </Grid>
                    <Grid item xs>
                      {parts.map((part, index) => (
                        <span
                          key={index}
                          style={{
                            fontWeight: part.highlight ? 700 : 400,
                          }}
                        >
                          {part.text}
                        </span>
                      ))}
                      <Typography variant="body2" color="text.secondary">
                        {option.structured_formatting.secondary_text}
                      </Typography>
                    </Grid>
                  </Grid>
                </li>
              );
            }}
          />
        )}
      />
      
      {/* Other fields (latitude, longitude, pincode) remain unchanged */}
      <Box sx={{ display: 'flex', gap: '2%', my: '1%' }}>
        <Box sx={{ flex: 1 }}>
          <Typography sx={{ fontWeight: 'bold' }}>Pincode<span style={{color:'red'}}>*</span></Typography>
          <Controller
            name={`${prefix}.pincode`}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                type='text'
                size='small'
                InputProps={{ inputProps: { style: { textTransform: "uppercase" } } }}
                error={!!error}
              />
            )}
          />
        </Box>
        <Box sx={{ flex: 1 }}>
          <Typography sx={{ fontWeight: 'bold' }}>Latitude<span style={{color:'red'}}>*</span></Typography>
          <Controller
            name={`${prefix}.latitude`}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                type='number'
                size='small'
                InputProps={{ inputProps: { style: { textTransform: "uppercase" } } }}
                error={!!error}
              />
            )}
          />
        </Box>
        <Box sx={{ flex: 1 }}>
          <Typography sx={{ fontWeight: 'bold' }}>Longitude<span style={{color:'red'}}>*</span></Typography>
          <Controller
            name={`${prefix}.longitude`}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                size='small'
                fullWidth
                type='number'
                error={!!error}
              />
            )}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default CoordinateDetails;

// import React, { useEffect, useState, useMemo,useRef } from 'react';
// import {Autocomplete, Box, Button, CircularProgress,Grid, TextField, Typography } from '@mui/material';
// import { useFormContext, Controller } from 'react-hook-form';
// import LocationOnIcon from '@mui/icons-material/LocationOn';
// import parse from 'autosuggest-highlight/parse';
// import { debounce } from '@mui/material/utils';

// // Replace this with your actual API key
// const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

// function loadScript(src, position, id) {
//   if (!position) {
//     return;
//   }

//   const script = document.createElement('script');
//   script.setAttribute('async', '');
//   script.setAttribute('id', id);
//   script.src = src;
//   position.appendChild(script);
// }

// const autocompleteService = { current: null };
// const placesService = { current: null };
// const geocoder = { current: null };

// const CoordinateDetails=()=> {
//   const [value, setValue] = useState(null);
//   const [inputValue, setInputValue] = useState('');
//   const [options, setOptions] = useState([]);
//   const [placeDetails, setPlaceDetails] = useState(null);
//   const loaded = useRef(false);

//   if (typeof window !== 'undefined' && !loaded.current) {
//     if (!document.querySelector('#google-maps')) {
//       loadScript(
//         `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
//         document.querySelector('head'),
//         'google-maps',
//       );
//     }

//     loaded.current = true;
//   }

//   const fetch = useMemo(
//     () =>
//       debounce((request, callback) => {
//         autocompleteService.current.getPlacePredictions(request, callback);
//       }, 400),
//     [],
//   );

//   useEffect(() => {
//     let active = true;

//     if (!autocompleteService.current && window.google) {
//       autocompleteService.current = new window.google.maps.places.AutocompleteService();
//       placesService.current = new window.google.maps.places.PlacesService(document.createElement('div'));
//       geocoder.current = new window.google.maps.Geocoder();
//     }
//     if (!autocompleteService.current) {
//       return undefined;
//     }

//     if (inputValue === '') {
//       setOptions(value ? [value] : []);
//       return undefined;
//     }

//     fetch({ input: inputValue }, (results) => {
//       if (active) {
//         let newOptions = [];

//         if (value) {
//           newOptions = [value];
//         }

//         if (results) {
//           newOptions = [...newOptions, ...results];
//         }

//         setOptions(newOptions);
//       }
//     });

//     return () => {
//       active = false;
//     };
//   }, [value, inputValue, fetch]);

//   const handlePlaceSelect = (place) => {
//     if (!place || !place.place_id) return;

//     placesService.current.getDetails(
//       { placeId: place.place_id, fields: ['address_components', 'geometry'] },
//       (placeResult, status) => {
//         if (status === window.google.maps.places.PlacesServiceStatus.OK) {
//           const addressComponents = placeResult.address_components;
//           const lat = placeResult.geometry.location.lat();
//           const lng = placeResult.geometry.location.lng();

//           const details = {
//             state: addressComponents.find(c => c.types.includes('administrative_area_level_1'))?.long_name || '',
//             city: addressComponents.find(c => c.types.includes('locality'))?.long_name || '',
//             coordinates: `${lat},${lng}`,
//             pincode: addressComponents.find(c => c.types.includes('postal_code'))?.long_name || ''
//           };

//           setPlaceDetails(details);
//         }
//       }
//     );
//   };

//   return (
//     <Box>
//       <Autocomplete
//         sx={{ width: 300 }}
//         getOptionLabel={(option) =>
//           typeof option === 'string' ? option : option.description
//         }
//         filterOptions={(x) => x}
//         options={options}
//         autoComplete
//         includeInputInList
//         filterSelectedOptions
//         value={value}
//         noOptionsText="No locations"
//         onChange={(event, newValue) => {
//           setOptions(newValue ? [newValue, ...options] : options);
//           setValue(newValue);
//           handlePlaceSelect(newValue);
//         }}
//         onInputChange={(event, newInputValue) => {
//           setInputValue(newInputValue);
//         }}
//         renderInput={(params) => (
//           <TextField {...params} label="Add a location" fullWidth />
//         )}
//         renderOption={(props, option) => {
//           const matches = option.structured_formatting.main_text_matched_substrings || [];
//           const parts = parse(
//             option.structured_formatting.main_text,
//             matches.map((match) => [match.offset, match.offset + match.length]),
//           );
//           return (
//             <li {...props}>
//               <Grid container alignItems="center">
//                 <Grid item>
//                   <Box component={LocationOnIcon} sx={{ color: 'text.secondary', mr: 2 }} />
//                 </Grid>
//                 <Grid item xs>
//                   {parts.map((part, index) => (
//                     <span
//                       key={index}
//                       style={{
//                         fontWeight: part.highlight ? 700 : 400,
//                       }}
//                     >
//                       {part.text}
//                     </span>
//                   ))}
//                   <Typography variant="body2" color="text.secondary">
//                     {option.structured_formatting.secondary_text}
//                   </Typography>
//                 </Grid>
//               </Grid>
//             </li>
//           );
//         }}
//       />
//       {/* {placeDetails && (
//         <Box mt={2}>
//           <Typography variant="h6">Selected Place Details:</Typography>
//           <Typography>State: {placeDetails.state}</Typography>
//           <Typography>City: {placeDetails.city}</Typography>
//           <Typography>Coordinates: {placeDetails.coordinates}</Typography>
//           <Typography>Pincode: {placeDetails.pincode}</Typography>
//         </Box>
//       )} */}
//     </Box>
//   );
// }
// export default CoordinateDetails;

// import React, { useEffect, useState } from 'react';
// import { Box, Button, CircularProgress, TextField, Typography } from '@mui/material';
// import { useFormContext, Controller } from 'react-hook-form';
// import Swal from 'sweetalert2';

// const CoordinateDetails = ({ prefix }) => {
//   const { control, watch, setValue } = useFormContext();

//   const [loading, setLoading] = useState(false);
//   const [disableCoordinates, setDisableCoordinates] = useState(false);
//   const state = watch(`${prefix}.state`);
//   const mainAddress = watch(`${prefix}.address`);
//   const city = watch(`${prefix}.city`);

//   const [address, setAddress] = useState('');

//   const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

//   const fetchCoordinatesAndPincode = async () => {
//     setValue(`${prefix}.latitude`, '');
//     setValue(`${prefix}.longitude`, '');
//     setValue(`${prefix}.pincode`, '');
//     setLoading(true);

//     try {
//       const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${GOOGLE_MAPS_API_KEY}`);
//       const data = await response.json();
      
//       if (data.status === 'OK' && data.results.length > 0) {
//         const result = data.results[0];
//         const { lat, lng } = result.geometry.location;
        
//         setValue(`${prefix}.latitude`, lat.toString(), { shouldDirty: true });
//         setValue(`${prefix}.longitude`, lng.toString(), { shouldDirty: true });
//         setDisableCoordinates(true);

//         // Extract pincode from address components
//         const pincodeComponent = result.address_components.find(
//           component => component.types.includes('postal_code')
//         );
//         const pincode = pincodeComponent ? pincodeComponent.long_name : '';
        
//         setValue(`${prefix}.pincode`, pincode, { shouldDirty: true });

//         if (!pincode) {
//           Swal.fire('Pincode not found', 'The pincode could not be determined from the provided address. Enter pincode manually.', 'warning');
//         }
//       } else {
//         Swal.fire('Unable to fetch details', 'Enter Coordinates and pincode Manually', 'error');
//         setDisableCoordinates(false);
//       }
//     } catch (err) {
//       Swal.fire('An error occurred while fetching the details. Enter Coordinates and pincode Manually', '', 'error');
//       setDisableCoordinates(false);
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     if (state && mainAddress && city) {
//       setAddress(`${mainAddress}, ${city}, ${state}`);
//     }
//   }, [state, mainAddress, city]);

//   return (
//     <>
//       <Button
//         variant="contained"
//         onClick={fetchCoordinatesAndPincode}
//         disabled={loading || !mainAddress || !city || !state}
//         sx={{ mb: 2 }}
//       >
//         {loading ? <CircularProgress size={24} /> : 'Get Coordinates'}
//       </Button>
//       <Box sx={{ display: 'flex', gap: '2%', my: '1%' }}>
//         <Box sx={{ flex: 1 }}>
//           <Typography sx={{ fontWeight: 'bold' }}>Pincode<span style={{color:'red'}}>*</span></Typography>
//           <Controller
//             name={`${prefix}.pincode`}
//             control={control}
//             render={({ field, fieldState: { error } }) => (
//               <TextField
//                 {...field}
//                 fullWidth
//                 type='text'
//                 size='small'
//                 InputProps={{ inputProps: { style: { textTransform: "uppercase" } } }}
//                 error={!!error}
//               />
//             )}
//           />
//         </Box>
//         <Box sx={{ flex: 1 }}>
//           <Typography sx={{ fontWeight: 'bold' }}>Latitude<span style={{color:'red'}}>*</span></Typography>
//           <Controller
//             name={`${prefix}.latitude`}
//             control={control}
//             render={({ field, fieldState: { error } }) => (
//               <TextField
//                 {...field}
//                 fullWidth
//                 type='number'
//                 size='small'
//                 disabled={disableCoordinates}
//                 InputProps={{ inputProps: { style: { textTransform: "uppercase" } } }}
//                 error={!!error}
//               />
//             )}
//           />
//         </Box>
//         <Box sx={{ flex: 1 }}>
//           <Typography sx={{ fontWeight: 'bold' }}>Longitude<span style={{color:'red'}}>*</span></Typography>
//           <Controller
//             name={`${prefix}.longitude`}
//             control={control}
//             render={({ field, fieldState: { error } }) => (
//               <TextField
//                 {...field}
//                 disabled={disableCoordinates}
//                 size='small'
//                 fullWidth
//                 type='number'
//                 error={!!error}
//               />
//             )}
//           />
//         </Box>
//       </Box>
//     </>
//   );
// }

// export default CoordinateDetails;
// import { Box, Button, CircularProgress, TextField, Typography } from '@mui/material';
// import React, { useEffect ,useState} from 'react';
// import { useFormContext, Controller } from 'react-hook-form';
// import Swal from 'sweetalert2';

// const CoordinateDetails = ({prefix}) => {
//     const {control,watch,setValue} = useFormContext();

//     const [loading, setLoading] = useState(false);
//     const [disableCoordinates,setDisableCoordinates] = useState(false);
//     const state = watch(`${prefix}.state`);
//     const mainAddress = watch(`${prefix}.address`);
//     const city = watch(`${prefix}.city`);

//     const [address, setAddress] = useState('');
//     const fetchCoordinatesAndPincode = async () => {
//         setValue(`${prefix}.latitude`, '');
//         setValue(`${prefix}.longitude`, '');
//         setValue(`${prefix}.pincode`, '');
//         setLoading(true);
//         try {
//             const response = await fetch(`https://nominatim.openstreetmap.org/search?format=json&addressdetails=1&q=${encodeURIComponent(address)}`);
//             const data = await response.json();
//             console.log(`details of ${prefix}:`, data);
//             if (data && data.length > 0) {
//                 setValue(`${prefix}.latitude`, (parseFloat(data[0].lat)).toString(), { shouldDirty: true });
//                 setValue(`${prefix}.longitude`, (parseFloat(data[0].lon)).toString(), { shouldDirty: true });
//                 setDisableCoordinates(true);

//                 // Extract pincode from address details
//                 const pincode = data[0].address.postcode;
//                 setValue(`${prefix}.pincode`, pincode || '', { shouldDirty: true });

//                 if (!pincode) {
//                     Swal.fire('Pincode not found', 'The pincode could not be determined from the provided address.Enter pincode Manually', 'warning');
//                 }
//             } else {
//                 Swal.fire('Unable to fetch details', 'Enter Coordinates and pincode Manually', 'error');
//                 setDisableCoordinates(false);
//             }
//         } catch (err) {
//             Swal.fire('An error occurred while fetching the details...Enter Coordinates and pincode Manually', '', 'error');
//             setDisableCoordinates(false);
//         } finally {
//             setLoading(false);
//         }
//     };
//     useEffect(()=>{
//         if (state && mainAddress && city) {
//             setAddress(`${mainAddress},${city},${state}`)
//         }
//     },[state,mainAddress,city])
//   return (
//     <>
//         <Button
//             variant="contained"
//             onClick={()=>fetchCoordinatesAndPincode(prefix)}
//             disabled={loading || !mainAddress || !city || !state}
//             sx={{ mb: 2 }}
//         >
//             {loading ? <CircularProgress size={24} /> : 'Get Coordinates'}
//         </Button>
//         <Box sx={{ display: 'flex', gap: '2%', my: '1%' }}>
//         <Box sx={{ flex:1}}>
//             <Typography sx={{ fontWeight: 'bold' }}>Pincode<span style={{color:'red'}}>*</span></Typography>
//             <Controller
//                 name={`${prefix}.pincode`}
//                 control={control}
//                 // rules={{
//                 //   required: "Required",
//                 //   pattern: { value: /[1-9]{1}[0-9]{5}/, message: "Only numeric: not starting with 0" },
//                 //   minLength: { value: 6, message: "Should be 6 digits" },
//                 //   maxLength: { value: 6, message: "Should be 6 digits" }
//                 // }}
//                 render={({ field, fieldState: { error } }) => (
//                 <TextField
//                     {...field}
//                     fullWidth
//                     type='number'
//                     size='small'
//                     // disabled
//                     InputProps={{ inputProps: { style: { textTransform: "uppercase" } } }}
//                     error={!!error}
//                 />
//                 )}
//             />
//             </Box>
//             <Box sx={{ flex:1}}>
//             <Typography sx={{ fontWeight: 'bold' }}>Latitude<span style={{color:'red'}}>*</span></Typography>
//             <Controller
//                 name={`${prefix}.latitude`}
//                 control={control}
//                 // rules={{
//                 //   required: "Required",
//                 //   pattern: { value: /[1-9]{1}[0-9]{5}/, message: "Only numeric: not starting with 0" },
//                 //   minLength: { value: 6, message: "Should be 6 digits" },
//                 //   maxLength: { value: 6, message: "Should be 6 digits" }
//                 // }}
//                 render={({ field, fieldState: { error } }) => (
//                 <TextField
//                     {...field}
//                     fullWidth
//                     type='number'
//                     size='small'
//                     disabled={disableCoordinates}
//                     InputProps={{ inputProps: { style: { textTransform: "uppercase" } } }}
//                     error={!!error}
//                 />
//                 )}
//             />
//             </Box>
//             <Box sx={{flex:1}}>
//             <Typography sx={{width:'65%' ,fontWeight:'bold', texttTransform:'uppercase', display:'flex ',alignItems:'center'}}>Longitude <span style={{color:'red'}}>*</span></Typography>
//             <Controller
//                 name={`${prefix}.longitude`}
//                 control={control}
//                 // rules={{
//                 //     pattern:{
//                 //         value:/[1-9]{1}[0-9]{9}/,
//                 //         message:"only numeric : not starting with 0"
//                 //     },
//                 //     minLength:{
//                 //         value:10,
//                 //         message:"mobileNumber should be of 10 digit"
//                 //     },
//                 //     maxLength:{
//                 //         value:10,
//                 //         message:"mobileNumber should be of 10 digit"
//                 //     }
//                 // }}
//                 render={({field,fieldState:{error}})=>(
//                     <TextField
//                         {...field}
//                         disabled={disableCoordinates}
//                         // sx={{width:'58%'}}
//                         size='small'
//                         fullWidth
//                         error={!!error}
//                         // helperText={error && error?.message}
//                     />
//                 )}                       
//             />
//             </Box>
//         </Box>
//     </>
//   );
// }

// export default CoordinateDetails;
