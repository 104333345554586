import React, { useState,useEffect } from 'react';
import { Box, Button, Avatar, Typography, IconButton, Tabs, Tab, TextField, Grid, Paper, Card, Chip } from '@mui/material';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { useNavigate, useParams } from 'react-router-dom';
import { ProviderServices } from '../CrudServices';
import Swal from 'sweetalert2';
import { Helpers } from '../../../services/Helpers';
import PersonalDetails from './PersonalDetail';
import AddressDetails from './AddressDetails';
import BusinessDetails from './BusinessDetails';
import ServiceTable from './ServiceTable';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import WorkingHours from './AvailabilityDetails';
import { ArrowBack } from '@mui/icons-material';
import StoreFrontDetails from './StoreFrontDetails';
import { PermissionGuard } from '../../../components/PermissionGuard';


const ViewProfile = () => {
  const {id} = useParams();
  const navigate =  useNavigate();
  const [coverImage, setCoverImage] = useState('https://via.placeholder.com/1200x300');
  const [profileImage, setProfileImage] = useState('https://via.placeholder.com/150');
  const [activeTab, setActiveTab] = useState(0);
  const [editMode, setEditMode] = useState(false);
  const [isLoading,setIsLoading] = useState(false);

  const [personalDetails, setPersonalDetails] = useState({});
  const [businessDetailsData,setBusinessDetailsData] = useState({});
  const [serviceDetailsData,setServiceDetailsData] = useState([]);
  const [availabilityDetailData,setAvailabilityDetailData] = useState([]);
  const [storeFrontDetailData,setStoreFrontDetailData] = useState(
    {
        providerId: '',
        id:'',
        name: '',
        categories: [],
        image: '',
        gallery: [],
        description: '',
    }
)
  
  const [bankDetails, setBankDetails] = useState({
    accountNumber: '1234567890',
    bankName: 'Example Bank',
    ifscCode: 'EXMP0001234'
  });

  const [contactDetails, setContactDetails] = useState({
    email: 'john.doe@example.com',
    phone: '+91 7906397578',
    address: 'B block indira nagar ,noida'
  });

  const [gallery, setGallery] = useState([
    'https://via.placeholder.com/150',
    'https://via.placeholder.com/150',
    'https://via.placeholder.com/150'
  ]);
  const fetchUserDetails = async () => {
    setIsLoading(true);
    const userDetailResponse = await ProviderServices.fetchProviderDetailsById(id);
    if (userDetailResponse.success == '1' && userDetailResponse.data.userType=="4") {
        console.log("user details:",userDetailResponse.data)
        const {userId,firstName,middleName,lastName,email,dob,gender,profileImage,waiveVisitCharge} = userDetailResponse.data?.UserProfile ?? {};
        const transformAddresses = (currentState, userAllAddress) => {
            // Create a copy of the current state to avoid direct mutation
            const updatedState = { ...currentState };
          
            // Safely iterate through userAllAddress
            userAllAddress?.forEach(addr => {
              if (addr.serviceArea === '1') {
                updatedState.serviceAddress = {
                  serviceArea: '1',
                  fullName: addr.fullName || '',
                  mobileNumber: addr.mobileNumber || '',
                  pincode: addr.pincode || '',
                  latitude: addr.latitude || '',
                  longitude: addr.longitude || '',
                  city: addr.city || '',
                  state: addr.state || '',
                  landmark: addr.landmark || '',
                  address: addr.address || '',
                  addressType: addr.addressType || '1'
                };
              } else if (addr.serviceArea === '2') {
                updatedState.selfAddress = {
                  serviceArea: '2',
                  fullName: addr.fullName || '',
                  mobileNumber: addr.mobileNumber || '',
                  pincode: addr.pincode || '',
                  latitude: addr.latitude || '',
                  longitude: addr.longitude || '',
                  city: addr.city || '',
                  state: addr.state || '',
                  landmark: addr.landmark || '',
                  address: addr.address || '',
                  addressType: addr.addressType || '1'
                };
              }
            });
          
            return updatedState;
          };
        const { selfAddress, serviceAddress } = transformAddresses(personalDetails,userDetailResponse.data.userAllAddress??[]);
        
        const providerCat = userDetailResponse.data?.providerCat??[];
        // Extract serviceCategoryId and convert to strings
        const transformserviceCategories = providerCat?.map(item => String(item.serviceCategoryId));
        setPersonalDetails(prev=>{
            return {...prev,
                // providerId:userId,
                image:profileImage,
                firstName,middleName,lastName,email,dob,gender,waiveVisitCharge,
                isUserType: 'provider',
                serviceCategories: transformserviceCategories, 
                serviceAddress,
                selfAddress,
                mobileNumber:userDetailResponse.data.mobileNumber,
            }
        });
        // console.log('Self Address:', selfAddress);
        // console.log('Service Address:', serviceAddress);
        const {id,createdBy,updatedBy,createdAt,updatedAt,...restAll} = userDetailResponse.data?.UserBusinessDetail ?? {};
        setBusinessDetailsData(restAll);
        // setServiceDetailsData();
        const providerStorefrontData = userDetailResponse.data?.Storefront;
        console.log("storefront Data:",providerStorefrontData);
        if (providerStorefrontData) {
          const categories =  providerStorefrontData.StorefrontCategories?.map(item => String(item.categoryId));
          const {StorefrontCategories,...restAll} = providerStorefrontData;
            setStoreFrontDetailData(pre=>({...pre,...restAll,categories}))
        }
    }else{
        setIsLoading(false);
        Swal.fire('Failed to fetch Service Expert Details','','error')
    }
  };

  useEffect(() => {
    fetchUserDetails();
  }, [id]);
  const handleCoverImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => setCoverImage(e.target.result);
      reader.readAsDataURL(file);
    }
  };

  const handleProfileImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => setProfileImage(e.target.result);
      reader.readAsDataURL(file);
    }
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleEditToggle = () => {
    setEditMode(!editMode);
  };

  const handleSave = () => {
    // Here you would typically send the updated data to your backend
    setEditMode(false);
  };

  const renderForm = () => {
    switch (activeTab) {
      case 0:
        return (
          <Grid container spacing={0}>
            {/* <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="First Name"
                value={personalDetails.firstName}
                onChange={(e) => setPersonalDetails({...personalDetails, firstName: e.target.value})}
                disabled={!editMode}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Last Name"
                value={personalDetails.lastName}
                onChange={(e) => setPersonalDetails({...personalDetails, lastName: e.target.value})}
                disabled={!editMode}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Date of Birth"
                type="date"
                value={personalDetails.dateOfBirth}
                onChange={(e) => setPersonalDetails({...personalDetails, dateOfBirth: e.target.value})}
                disabled={!editMode}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Gender"
                value={personalDetails.gender}
                onChange={(e) => setPersonalDetails({...personalDetails, gender: e.target.value})}
                disabled={!editMode}
              />
            </Grid> */}
            <PersonalDetails personalDetails={personalDetails}/>
          </Grid>
        );
      case 1:
        return (
          <Grid container spacing={0}>
            <AddressDetails PersonalDetails={personalDetails}/>
          </Grid>
        );
      case 2:
        return (
          <Grid container spacing={0}>
            <BusinessDetails businessDetailsData={businessDetailsData}/>
          </Grid>
        );
      case 3:
        return (
          <Grid container spacing={0}>
            <ServiceTable/>
          </Grid>
        );
      case 4:
        return (
          <Grid container spacing={0}>
            <WorkingHours/>
          </Grid>
        );
      case 5:
        return (
          <Grid container spacing={0}>
            <StoreFrontDetails storeFrontDetailData={storeFrontDetailData} />
          </Grid>
        );
      default:
        return null;
    }
  };

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 2 }}>
      <Card sx={{ minWidth: "100%", marginBottom: "1%" }}>
        <Grid container spacing={1}>
            <Grid item xs={12} lg={6}>
                <Typography
                    gutterBottom
                    variant='h5'
                    component='div'
                    sx={{ padding: '2%' }}
                >
                    Profile
                </Typography>
            </Grid>
            <Grid item xs={12} lg={6} sx={{display:'flex',gap:'2%',alignItems:'center',justifyContent:'end',pr:'3%'}}>
              <PermissionGuard feature='service-expert' permission='canEdit'>
                <Button startIcon={<BorderColorOutlinedIcon/>} color='warning' variant='contained' size='small' onClick={()=>navigate(`/admin/userProfile/${id}`)}>
                  Edit
                </Button>
              </PermissionGuard>
                <Button endIcon={<ArrowBack/>} variant='contained' size='small' onClick={()=>navigate(-1)}>
                  Back
                </Button>
            </Grid>
        </Grid>
      </Card> 
      <Grid container spacing={2} sx={{ my: '0%' }}>
        <Grid item xs={12} lg={3}>
          <Paper>
            <Box sx={{ p: 2, textAlign: 'center', borderBottom:'1px dashed lightGray' }}>
              <Box sx={{display:'flex',justifyContent:'center'}}>
                <Avatar
                  src={personalDetails.image ? `${Helpers.ImagePathUrl()}/profile/sm/${personalDetails.image}`:profileImage}
                  // alt="Profile"
                  sx={{ width: 150, height: 150, border: '4px solid white'}}
                />
              </Box>
              <Typography variant="h4">{personalDetails.firstName} {personalDetails.middileName} {personalDetails.lastName}</Typography>
              <Typography variant="body2" color="text.secondary">
                 
              </Typography>
              <Chip
                label={'Service Expert'}
                color="primary"
                variant="outlined"
                size="small"
              />
            </Box>
            <Tabs
              
              orientation="vertical"
              variant="scrollable"
              value={activeTab}
              onChange={handleTabChange}
              sx={{ borderRight: 1, borderColor: 'divider', width: '100%' }}
              >
              <Tab label="Personal Details" />
              <Tab label="Address Details" />
              <Tab label="Business Details" />
              <Tab label="Service Details" />
              <Tab label="Availabilty Details" />
              <Tab label="Storefront Details" />
            </Tabs>
          </Paper>
        </Grid>
        <Grid item xs={12} lg={9}>
          <Box sx={{ flexGrow: 1, }}>
            <Paper elevation={3} >
              {renderForm()}
            </Paper>
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
            <PermissionGuard feature='service-expert' permission='canEdit'>
              <Button startIcon={<BorderColorOutlinedIcon/>} color='warning' variant='contained' size='small' onClick={()=>navigate(`/admin/userProfile/${id}`)}>
                Edit
              </Button>
            </PermissionGuard>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ViewProfile;