import React, { useContext, useEffect, useState } from 'react';
import {FormProvider, useForm } from 'react-hook-form';
import {Button,  Typography,  Grid,  Box,Card} from '@mui/material';
import { User } from '../UserService';
import Swal from 'sweetalert2';
import AddressDetails from './AddressDetails';
import BasicInfo from './BasicInfo';
import ImageSection from './ImageSection';
import { UserProfileContext } from '../UserProfile';
import { ProviderServices } from '../CrudServices';
import Loading from '../Loading';

export default function PersonalDetails({ userIdProp, personalDetails, onSubmitPersonal, tabNextChange }) {
  const {providerPersonalDetails, setProviderPersonalDetails,fetchUserDetails} = useContext(UserProfileContext);
  const [isSubmitted,setIsSubmitted] = useState(false);
  const personalDetailFormMethod = useForm(
    { 
      mode: "onChange",
      defaultValues:providerPersonalDetails 
    }
  );
  const{control, handleSubmit, setValue, formState: { errors ,isDirty},watch,reset} = personalDetailFormMethod;
  useEffect(()=>{
    reset(providerPersonalDetails);
  },[providerPersonalDetails,reset])

  const handleNext = () => {
    tabNextChange(1)
  }
  
  const [trigStatus, setTrigStatus] = useState(null);
  const [imgModalOpen, setImgModalOpen] = useState(false);
 

  const closeModal = () => {
    setImgModalOpen(false);
  };

  const handlePImgTrig = (data) => {
    setTrigStatus(data);
  }
  const imgData = {
    path: "profile",
    // image_name: `img-${GenerateUniqueVal(1000000)}-${personalDetails.id}.jpeg`,
    img_type: "single"
  };
  const updateUserProfileImage = async (data, imgType) => {
    // let pData;
    // if (imgType == "profile") {
    //   pData = { profile_image: data.Location };
    // } else {
    //   pData = { banner_image: data.Location };
    // }
    // const updateUser = await User.updateUser(userIdProp, pData);
    // if (updateUser.success) {
    //   if (imgType == "profile") {
    //     // setProfileImg(data.Location);
    //   } else {
    //     // setBannerImg(data.Location)
    //   }
    //   closeModal();
    //   Swal.fire("Image Uploaded", '', 'success')
    // }
  }

  const imgUploadResponse = (data,imageType) => {
    if (imageType == "profile") {
      updateUserProfileImage(data, "profile");
      setValue('image',data.imageName,{shouldDirty: true, })
    }
    if (imageType == "banner") {
      updateUserProfileImage(data, "banner");
    }
  }
  const onSubmitFormData = async(data)=>{
    console.log("submitted form data:",data);
    setIsSubmitted(true);
    const updatedData = {
      ...data,
      selfAddress: {
        ...data.selfAddress,               
        fullName: data.firstName+' '+data.middleName+' '+data.lastName,
    },
    serviceAddress: {
        ...data.serviceAddress,
        fullName: data.firstName+' '+data.middleName+' '+data.lastName,
    },
    }
    console.log("updated form data:",updatedData);
    try {
      const registerResponse = await ProviderServices.createUserProfile(updatedData);
      console.log("user profile creation response:",registerResponse);
      if (registerResponse.success=='1') {
        const {userId,firstName,middleName,lastName,email,dob,gender,profileImage,waiveVisitCharge} = registerResponse.data?.UserProfile ?? {};
            const transformAddresses = (currentState, userAllAddress) => {
                // Create a copy of the current state to avoid direct mutation
                const updatedState = { ...currentState };
              
                // Safely iterate through userAllAddress
                userAllAddress?.forEach(addr => {
                  if (addr.serviceArea === '1') {
                    updatedState.serviceAddress = {
                      serviceArea: '1',
                      fullName: addr.fullName || '',
                      mobileNumber: addr.mobileNumber || '',
                      pincode: addr.pincode || '',
                      latitude: addr.latitude || '',
                      longitude: addr.longitude || '',
                      city: addr.city || '',
                      state: addr.state || '',
                      landmark: addr.landmark || '',
                      address: addr.address || '',
                      addressType: addr.addressType || '1'
                    };
                  } else if (addr.serviceArea === '2') {
                    updatedState.selfAddress = {
                      serviceArea: '2',
                      fullName: addr.fullName || '',
                      mobileNumber: addr.mobileNumber || '',
                      pincode: addr.pincode || '',
                      latitude: addr.latitude || '',
                      longitude: addr.longitude || '',
                      city: addr.city || '',
                      state: addr.state || '',
                      landmark: addr.landmark || '',
                      address: addr.address || '',
                      addressType: addr.addressType || '1'
                    };
                  }
                });
              
                return updatedState;
              };
            const { selfAddress, serviceAddress } = transformAddresses(providerPersonalDetails,registerResponse.data.userAllAddress??[]);
            
            const providerCat = registerResponse.data?.providerCat??[];
            // Extract serviceCategoryId and convert to strings
            const transformserviceCategories = providerCat?.map(item => String(item.serviceCategoryId));
            setProviderPersonalDetails(prev=>{
                return {...prev,
                    // providerId:userId,
                    image:profileImage,
                    firstName,middleName,lastName,email,dob,gender,waiveVisitCharge,
                    isUserType: 'provider',
                    serviceCategories: transformserviceCategories, 
                    serviceAddress,
                    
                    selfAddress,
                }
            });
            console.log('Self Address:', selfAddress);
            console.log('Service Address:', serviceAddress);
        Swal.fire('Personal Detail Updated Successfully','','success');
        setIsSubmitted(false)
      }else{
          Swal.fire('Something Went Wrong','','error');
          setIsSubmitted(false)
      }
    } catch (error) {
      setIsSubmitted(false)
    }
  }
  return (
    <FormProvider {...personalDetailFormMethod}>
      {isSubmitted && <Loading/>}
      <Grid container spacing={2} component={'form'} >
        {/* =======================Basic Info section================== */}
        <BasicInfo/>
        {/* =======================Image section================== */}
        <Grid item xs={12} lg={5}>
          <Card sx={{my:0.5}}>
            <Box sx={{p:'.5% 2%',border:'2px solid lightGray',bgcolor:'lightGray'}}>
              <Typography sx={{fontWeight:'bold'}}>Image Info</Typography>
            </Box>
            <ImageSection  imgModalOpen={imgModalOpen} setImgModalOpen={setImgModalOpen} closeModal={closeModal} 
                    imgData={imgData} 
                    imgUploadResponse={imgUploadResponse} 
                    handlePImgTrig={handlePImgTrig}/>
            </Card>
        </Grid>
        {/* =======================address section================== */}
        <AddressDetails/>
        <Box sx={{textAlign:'center',width:'100%'}}>
          <Button disabled={!isDirty || isSubmitted} variant='contained' onClick={handleSubmit(onSubmitFormData)} size='large'>Update Personal Details</Button>
        </Box>
      </Grid>
    </FormProvider>
  );
}
