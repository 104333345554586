
import React from 'react';
import { useDispatch } from 'react-redux';
import MemberList from './MemberList';
import { Box } from '@mui/material';

const Member = () => {
    const menuDispatch = useDispatch();
    menuDispatch({
        type: 'menuSelect',
        payload: 'members'
    });
    return (
        <>
            <Box component="main" sx={{ flexGrow: 1, p: 2 }}>
                <MemberList/>
            </Box>
        </>
    )
}

export default Member;
